import React, {useState} from 'react';
import {Statistic, Row, Col, Button} from 'antd';
import {LikeOutlined} from '@ant-design/icons';
import ProCard from '@ant-design/pro-card';
import {getFeedbackStat} from '@/api/mock'
import moment from "moment";
import ProTable from "@ant-design/pro-table";
import {TimeFilter} from "../../../components/TimeFilter";
import {TABLE_PROPS} from "../../commonLayout";


function FeedbackQuantity() {
    const [config, setConfig] = useState(TABLE_PROPS.COMMON_PROPS);
    const [tableExtDataSource, setTableExtDataSource] = useState(undefined);

    const columns = [
        {
            title: '时间范围',
            key: 'dateRange',
            hideInTable: true,
            valueType: 'dateTimeRange',
            search: {
                transform: (value) => ({startDate: value[0], endDate: value[1]}),
            },
            initialValue: [moment().subtract(2, 'd').startOf('day'), moment().endOf('day')],
            colSize: 2,
            renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
                return <TimeFilter form={form} format={'YYYY/MM/DD'} defaultShortcut={'recent3days'}/>
            }
        },,
        {
            title: '操作员',
            dataIndex: 'adminName',
            hideInSearch: true,
        },
        {
            title: '反馈问题个数',
            dataIndex: 'feedbackCount',
            hideInSearch: true,
            // renderText: (val) => {
            //     return moment(val).format('YYYY-MM-DD')
            // }
        },
        {
            title: '总对话个数',
            dataIndex: 'talkCount',
            hideInSearch: true,
        },
        {
            title: '首次响应时长',
            dataIndex: 'firstResponseDisplay',
            hideInSearch: true,
        },
        {
            title: '平均响应时长',
            dataIndex: 'avgResponseDisplay',
            hideInSearch: true,
        },
    ];

    return (
        <>
            <ProTable
                {...config}
                tableExtraRender={(_, data) => (
                    <ProCard gutter={8}>
                        <ProCard
                            size="middle"
                        >
                            <Row>
                                <Col span={6}>
                                    <Statistic title="反馈问题数" value={tableExtDataSource?.feedbackCount}
                                               suffix={<LikeOutlined/>}/>
                                </Col>
                                <Col span={6}>
                                    <Statistic title="对话消息数" value={tableExtDataSource?.talkCount}/>
                                </Col>
                                <Col span={6}>
                                    <Statistic title="首次响应时长" value={tableExtDataSource?.avgResponseDisplay}/>
                                </Col>
                                <Col span={6}>
                                    <Statistic title="平均响应时长" value={tableExtDataSource?.firstResponseDisplay}/>
                                </Col>
                            </Row>
                        </ProCard>
                    </ProCard>
                )}
                // footer={() => '客服反馈小计'}
                columns={columns}
                rowKey="key"
                cardBordered={true}
                request={async (params, sorter, filter) => {
                    let rsp = await getFeedbackStat({...params, sorter, filter});
                    setTableExtDataSource(rsp.data.total);
                    // tableExtDataSource = rsp.data.total;
                    return {
                        success: true,
                        data: rsp.data.adminStat,
                    };
                }
                }
                // pagination={false}
            />
        </>
    );
}

export default FeedbackQuantity;
