import React, {useEffect, useState} from 'react';
import ProCard from "@ant-design/pro-card";
import {Tabs} from 'antd';
import {getVersionExtTypes} from "@/api/mock";
import RadioExt from "./RadioExt"
import FreeUseExt from "./FreeUseExt"
import ContactWaitingExt from "./ContactWaitingExt"
import ContactEntranceSwitchExt from "./ContactEntranceSwitchExt"
import HidableTextExt from "./HidableTextExt"
import RiskAreaExt from "./RiskAreaExt";
import PayTypeKefuEntranceExt from "./PayTypeKefuEntranceExt";
import AdDelayExt from "./AdDelayExt";
import PayWindowTextExt from "./PayWindowTextExt";

const {TabPane} = Tabs;


function VersionExtConfig() {
    // 具体某个type的信息
    // const [extTypeDom, setExtTypeDom] = useState(undefined);
    // 全部type的kv数组
    const [extTypes, setExtTypes] = useState([]);
    const [pnameMap, setPnameMap] = useState(undefined);
    const [selectedPname, setSelectedPname] = useState(undefined);
    const [selectedType, setSelectedType] = useState(0);


    useEffect(async () => {
        // let proj = window.localStorage.getItem('selectedProj');
        // if (!proj) {
        //     message.error('请选择项目', 3)
        // }
        let pMap = JSON.parse(window.localStorage.getItem('pnameMap'));
        setPnameMap(pMap);
        pMap && setSelectedPname(Object.keys(pMap)[0]);
        const result = await getVersionExtTypes();
        if (result.data && Object.keys(result.data).length) {
            const types = result.data;
            setExtTypes(types);
        }
    }, []);

    // 根据type去渲染
    const renderContent = () => {
        if (selectedPname && selectedType) {
            switch (selectedType) {
                case '5':
                    return <FreeUseExt key={selectedType} pname={selectedPname} type={selectedType}/>;
                case '7':
                    return <AdDelayExt key={selectedType} pname={selectedPname} type={selectedType}/>;
                case '8':
                    return <PayWindowTextExt key={selectedType} pname={selectedPname} type={selectedType}/>;
                case '11':
                case '12':
                case '14':
                    return <HidableTextExt key={selectedType} pname={selectedPname} type={selectedType}/>;
                case '13':
                    return <HidableTextExt key={selectedType} pname={selectedPname} type={selectedType}
                                           fontParam={true}/>;
                case '38':
                    return <ContactWaitingExt key={selectedType} pname={selectedPname} type={selectedType}/>;
                case '40':
                    return <ContactEntranceSwitchExt key={selectedType} pname={selectedPname} type={selectedType}/>;
                case '43':
                    return <RiskAreaExt key={selectedType} pname={selectedPname} type={selectedType}/>;
                case '31':
                    return <PayTypeKefuEntranceExt key={selectedType} pname={selectedPname} type={selectedType}/>;
                default:
                    return <RadioExt key={selectedType} pname={selectedPname} type={selectedType}/>;
            }
        }
    };

    const renderTypeTabs = () => {
        return (<Tabs activeKey={selectedType}
                      onTabClick={(key) => {
                          setSelectedType(key);
                      }}
        >
            {
                extTypes && extTypes.map(i =>
                    (
                        // selectedType == i.value 这里左边是字符串类型,右边是数字类型
                        <TabPane tab={i.label} key={i.value + ''}>
                            {selectedType == i.value && renderContent()}
                        </TabPane>
                    )
                )
            }
        </Tabs>)
    };

    return (
        <>
            <ProCard gutter={8}>
                {/*pname标签Tab*/}
                <Tabs activeKey={selectedPname}
                      type="card"
                      size="small"
                      tabBarGutter={4}
                      onTabClick={(key) => {
                          setSelectedPname(key);
                      }}
                >
                    {
                        // 渲染包名标签
                        pnameMap && Object.keys(pnameMap).map(key => (
                            <ProCard.TabPane key={key} tab={pnameMap[key]}>
                                {/*减少渲染次数*/}
                                {selectedPname === key && renderTypeTabs()}
                            </ProCard.TabPane>
                        ))
                    }
                </Tabs>
            </ProCard>
        </>
    );
}

export default VersionExtConfig;
