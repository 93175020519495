import React from 'react';
import ReactDOM from 'react-dom';
import Home from "./pages/Home";

import 'antd/dist/antd.less';
import './index.less'

ReactDOM.render(
    <Home/>,
    document.getElementById('root')
);
