import React, {useState, useEffect} from 'react';
import ProTable from '@ant-design/pro-table';
import ProCard from '@ant-design/pro-card';
import {getRefundInfo} from '@/api/mock';
import moment from 'moment';
import './index.less';
import {TimeFilter} from "../../../components/TimeFilter";
import {CARD_STYLES, TABLE_PROPS} from "../../commonLayout";
import {message} from "antd";

const cell_span_style = {width: 40, display: 'inline-block', textAlign: 'right', marginRight: 8};

const RefundInfo = () => {
    const [config, setConfig] = useState(TABLE_PROPS.COMMON_PROPS);
    const [footerRemark, setFooterRemark] = useState('');

    const columns = [
        {
            title: '时间范围',
            key: 'dateRange',
            hideInTable: true,
            valueType: 'dateTimeRange',
            search: {
                transform: (value) => ({startDate: value[0], endDate: value[1]}),
            },
            initialValue: [moment().subtract(1, 'd').startOf('day'), moment().subtract(1, 'd').endOf('day')],
            colSize: 2,
            renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
                return <TimeFilter form={form} format={'YYYY/MM/DD'} defaultShortcut={'yesterday'}/>
            }
        },
        {
            title: '操作员',
            dataIndex: 'username',
            hideInSearch: true,
        },
        {
            title: '总笔数',
            dataIndex: 'totalRefundNum',
            hideInSearch: true,
        },
        {
            title: '总金额',
            dataIndex: 'totalRefundFee',
            hideInSearch: true,
        },
        {
            title: '手动笔数',
            hideInSearch: true,
            render: (_, row, index, action) => {
                return <>
                    {row.manualRefundNum + ' 笔'}
                </>
                // if (row.adminId < 10000) {
                //     return <>
                //         <div><span style={cell_span_style}>{'all:'}</span>{row.manualRefundNum + ' 笔'}</div>
                //         <div><span style={cell_span_style}>{'oppo:'}</span>{row.manualRefundOppoNum + ' 笔'}</div>
                //         <div><span style={cell_span_style}>{'头条:'}</span>{row.manualRefundToutiaoNum + ' 笔'}</div>
                //         <div><span style={cell_span_style}>{'剔除后:'}</span>{row.manualNumAfterSubtract + ' 笔'}</div>
                //     </>;
                // } else {
                //     return '-';
                // }
            },
            width: 150
        },
        {
            title: '手动金额',
            hideInSearch: true,
            render: (_, row, index, action) => {
                return <>
                    {row.manualRefundFee + ' ￥'}
                </>
                // if (row.adminId < 10000) {
                //     return <>
                //         <div><span style={cell_span_style}>{'all:'}</span>{row.manualRefundFee + ' ￥'}</div>
                //         <div><span style={cell_span_style}>{'oppo:'}</span>{row.manualRefundOppoFee + ' ￥'}</div>
                //         <div><span style={cell_span_style}>{'头条:'}</span>{row.manualRefundToutiaoFee + ' ￥'}</div>
                //         <div><span style={cell_span_style}>{'剔除后:'}</span>{row.manualFeeAfterSubtract + ' ￥'}</div>
                //     </>
                // } else {
                //     return '-';
                // }
            }
        },
        {
            title: '手动撤诉笔数',
            hideInSearch: true,
            dataIndex: 'manualWithdrawRefundNum',
        },
        {
            hideInSearch: true,
            title: '手动撤诉金额',
            dataIndex: 'manualWithdrawRefundFee',
        },
        {
            hideInSearch: true,
            title: '申请列表处理数',
            dataIndex: 'manualHandleNum',
        },
        {
            hideInSearch: true,
            title: '申请列表退款数',
            dataIndex: 'refundListRefundNum',
        },
        {
            hideInSearch: true,
            title: '申请列表金额',
            dataIndex: 'refundListRefundFee',
        },
    ];

    return (
        <ProCard
            split="vertical"
            bordered
            headerBordered
        >
            <ProCard
                style={CARD_STYLES.COMMON_MIN_HEIGHT_STYLE}
            >
                <ProTable
                    {...config}
                    columns={columns}
                    rowKey="key"
                    request={async (params, sorter, filter) => {
                        const result = await getRefundInfo({...params, sorter, filter});
                        const summary = result.data.summary;
                        setFooterRemark(`总退款笔数：${summary.totalRefundNum}，总退款金额：${summary.totalRefundFee}；投诉自动退款笔数：${summary.autoWithdrawRefundNum}，金额：${summary.autoWithdrawRefundFee}；电话自动退款笔数：${summary.callAutoRefundNum}，金额：${summary.callAutoRefundFee}；反馈自动退款笔数：${summary.autoFeedbackRefundNum}，金额：${summary.autoFeedbackRefundFee}；`);
                        return {
                            success: true,
                            data: result.data.adminStats,
                        };
                    }}
                    // footer={() => footerRemark}
                    manualRequest={false}
                    onLoad={(dataSource) => {
                        if (dataSource.length === 0) {
                            message.info('没有数据');
                        }
                        // else {
                        //     let item = dataSource.filter(item =>
                        //         item.adminId === 0
                        //     );
                        //     setFooterRemark(item && item[0]?.remark);
                        // }
                    }}
                    pagination={false}
                />
            </ProCard>
        </ProCard>
    );
};


export default RefundInfo;