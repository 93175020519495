import React, {useEffect, useState} from 'react';
import ProCard from "@ant-design/pro-card";
import {Tabs} from 'antd';
import {getTaskConfigTypes} from "@/api/mock";
import TextConfig from "./TextConfig";
import BaseConfig from "./BaseConfig";

const {TabPane} = Tabs;


function TaskConfig() {
    // 具体某个type的信息
    // const [extTypeDom, setExtTypeDom] = useState(undefined);
    // 全部type的kv数组
    // const [taskConfigs, setTaskConfigs] = useState(undefined);
    const [taskTypes, setTaskTypes] = useState([]);
    const [pnameMap, setPnameMap] = useState(undefined);
    const [selectedPname, setSelectedPname] = useState(undefined);
    const [selectedType, setSelectedType] = useState('');


    useEffect(async () => {
        let pMap = JSON.parse(window.localStorage.getItem('pnameMap'));
        setPnameMap(pMap);
        pMap && setSelectedPname(Object.keys(pMap)[0]);

        const result = await getTaskConfigTypes();
        if (result.data && Object.keys(result.data).length) {
            setTaskTypes(result.data);
        }
    }, []);

    const renderContent = () => {
        if (selectedPname && selectedType) {
            switch (selectedType) {
                case 'ADD_FRIEND_TEXT_SWITCH':
                case 'PAY_TEXT_SWITCH':
                    return <TextConfig key={selectedType} type={selectedType} pname={selectedPname}/>;
                case 'PAY_FIRST_SWITCH':
                case 'RISK_PERIOD_SWITCH':
                    return <BaseConfig key={selectedType} type={selectedType} pname={selectedPname}/>;
                default:
                    return <BaseConfig key={selectedType} type={selectedType} pname={selectedPname}/>;
            }
        }
    };


    const renderTypeTabs = () => {
        return (
            <Tabs activeKey={selectedType}
                  onTabClick={(key) => {
                      setSelectedType(key);
                  }}
            >
                {
                    taskTypes && taskTypes.map(i => {
                            return (
                                <TabPane tab={i.label} key={i.value}>
                                    {selectedType == i.value && renderContent()}
                                    {/*<TextConfig pname={selectedPname} taskData={taskConfigs[selectedPname][i]}/>*/}
                                </TabPane>
                            )
                        }
                    )
                }
            </Tabs>)
    };

    return (
        <>
            <ProCard gutter={8}>
                {/*pname标签Tab*/}
                <Tabs activeKey={selectedPname}
                      type="card"
                      size="small"
                      tabBarGutter={4}
                      onTabClick={(key) => {
                          setSelectedPname(key);
                      }}
                >
                    {
                        // 渲染包名标签
                        pnameMap && Object.keys(pnameMap).map(key => (
                            <ProCard.TabPane key={key} tab={pnameMap[key]}>
                                {/*减少渲染次数*/}
                                {selectedPname === key && renderTypeTabs()}
                            </ProCard.TabPane>
                        ))
                    }
                </Tabs>
            </ProCard>
        </>
    );
}

export default TaskConfig;
