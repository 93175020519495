import React, {useEffect, useState} from 'react';
import {Button, message, Select, Tree, TreeSelect} from 'antd';
import {EditableProTable} from "@ant-design/pro-table";
import ProForm, {ModalForm, ProFormText} from "@ant-design/pro-form";
import {getRoleList, updateRole, saveRole} from "@/api/mock";
import {ENUMS} from "../../../common/enums";
import TreeExt from "../../AntdComponentExt/treeExt";
import {RULES} from "../../../common/rules";

const {DirectoryTree} = Tree;

const layout = {
    layout: "horizon",
    labelCol: {span: 6},
    wrapperCol: {span: 16},
};


function RoleList() {
    // 重新加载table
    const [reload, setReload] = useState(0);
    // 主列表数据
    const [roles, setRoles] = useState([]);
    const [menuTree, setMenuTree] = useState(undefined);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedMenuIds, setSelectedMenuIds] = useState(undefined);
    const [newRoleMenuIds, setNewRoleMenuIds] = useState(undefined);

    useEffect(async () => {
        let rsp = await getRoleList();
        if (rsp.data) {
            rsp.data.roleList.map(item =>
                item.roleMenus && item.roleMenus.map(i => {
                    i.key = i.id;
                    i.title = i.name;
                    i.children && i.children.map(j => {
                        j.title = j.name;
                        j.key = j.id;
                    });
                    return i;
                }));
            setRoles(rsp.data.roleList);

            if (rsp.data.menuList) {
                rsp.data.menuList.map(i => {
                    i.key = i.id;
                    i.title = i.name;
                    i.children && i.children.map(j => {
                        j.title = j.name;
                        j.key = j.id;
                    });
                    return i;
                });
                setMenuTree(rsp.data.menuList);
            }
        }
    }, [reload]);

    const columns = [
        {
            title: '角色',
            dataIndex: 'name',
            hideInSearch: true,
            width: 160,
            formItemProps: (form, {rowIndex}) => {
                return {
                    // rules: rowIndex > 2 ? [{required: true, message: '此项为必填项'}] : [],
                    rules: [{required: true, message: '此项为必填项'}],
                };
            },
        },
        {
            title: '角色描述',
            dataIndex: 'description',
            hideInSearch: true,
            width: 240,
        },
        {
            title: '授权菜单树',
            dataIndex: 'menuIds',
            hideInSearch: true,
            width: 400,
            renderFormItem: (_, {isEditable, record}) => {
                let item = roles.filter((item) => item.name === record.name)?.[0];
                const props = {
                    defaultCheckedKeys: item.menuIds,
                    treeData: menuTree,
                    checkable: true,
                    treeCheckable: true,
                    checkStrictly: true,
                    onChange: (e) => {
                        record.menuIds = e;
                        setSelectedMenuIds(e);
                    },
                    value: selectedMenuIds ?? item.menuIds
                };
                return <TreeExt{...props}/>
            },
            render: (text, record, _, action) => {
                return <DirectoryTree
                    showLine={true}
                    showIcon={false}
                    selectable={false}
                    placeholder={'暂无'}
                    style={{width: '100%'}}
                    treeData={record.roleMenus}
                />
            }
        },
        {
            title: '是否启用',
            key: 'enabledStatus',
            dataIndex: 'enabledStatus',
            valueType: 'select',
            width: 100,
            valueEnum: ENUMS.YES_OR_NO_ENUM,
        },
        {
            title: '创建时间',
            dataIndex: 'created',
            hideInSearch: true,
            editable: false,
        },
        {
            title: '操作',
            key: 'option',
            valueType: 'option',
            render: (text, record, _, action) => [
                <a key="editable" onClick={() => {
                    action.startEditable?.(record.id);
                }}>编辑</a>
            ],
        },
    ];

    return (
        <>
            <EditableProTable
                search={false}
                columns={columns}
                rowKey="id"
                cardBordered={true}
                // 工具栏拓展
                toolBarRender={() => [
                    <ModalForm
                        {...layout}
                        title={"添加菜单"}
                        width={400}
                        visible={modalVisible}
                        // 触发点
                        trigger={
                            <Button type="primary" key="addRootMenu"
                                    onClick={() => {
                                        setModalVisible(true);
                                    }}>添加角色</Button>
                        }
                        onFinish={async (values) => {
                            const result = await saveRole(values);
                            if (result.data) {
                                message.success('保存成功');
                                setModalVisible(false);
                                setReload(Math.random());
                            } else {
                                return false;
                            }
                        }}
                        modalProps={{
                            onCancel: () => {
                                setModalVisible(false);
                                return false;
                            },
                            maskClosable: false,
                            closable: false,
                            destroyOnClose: true,
                            bodyStyle: {
                                paddingTop: 40
                            },
                            centered: true,
                            okText: '添加',
                        }}
                    >
                        <>
                            <ProFormText name="name" label="角色名称" placeholder="" rules={RULES.REQUIRED_RULE}/>
                            <ProFormText name="description" label="角色描述" placeholder=""/>
                            <ProForm.Item name="menuIds" label={'菜单权限'}>
                                <TreeExt
                                    name="menuIds"
                                    placeholder={'暂无'}
                                    style={{width: '100%'}}
                                    treeData={menuTree}
                                    treeCheckable={true}
                                    checkable={true}
                                    checkStrictly={true}
                                    onChange={(e) => {
                                        setNewRoleMenuIds(e);
                                    }}
                                    value={newRoleMenuIds}
                                />
                            </ProForm.Item>
                        </>
                    </ModalForm>
                ]}
                value={roles}
                onChange={setRoles}
                // 保存行编辑
                editable={{
                    type: 'single',
                    onSave: async (key, row) => {
                        const {id, name, description, enabledStatus, menuIds} = row;
                        const result = await updateRole({
                            id: id,
                            name: name,
                            enabledStatus: enabledStatus,
                            description: description,
                            menuIds: menuIds,
                        });
                        if (result.data) {
                            message.info("操作成功");
                            // 方法一 数据重新渲染失败
                            setReload(Math.random());

                            // 方法二 重新渲染成功
                            // let rsp = await getRoleList();
                            // if (rsp.data) {
                            //     setRoles(rsp.data.roleList);
                            // }
                        }
                    },
                    actionRender: (row, config, dom) => [dom.save, dom.cancel],
                }}
                recordCreatorProps={false}
            />
        </>
    );
}

export default RoleList;
