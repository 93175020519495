import React, {useState, useEffect} from 'react';
import ProForm, {
    ProFormCheckbox,
} from '@ant-design/pro-form';
import {Button, message} from "antd";
import request from "../../../../api/request";
import {API} from "../../../../api/mock";
import {getVersionExtInfo, replaceChar} from "../util"
import {FORM_LAYOUT} from "../../../commonLayout";


// 联系客服开关组
function ContactEntranceSwitchExt(prop) {
    const {pname, type} = prop;
    const [readonly, setReadonly] = useState(true);
    const [data, setData] = useState(undefined);
    const [originData, setOriginData] = useState(undefined);

    let prepareData = (info) => {
        setData(info);
        // 拷贝一份供取消编辑恢复用
        let objString = JSON.stringify(info);
        setOriginData(Object.assign([], JSON.parse(objString)));
    };

    const checkboxEnum = [{label: '微信', value: 'wx'}, {label: 'QQ', value: 'qq'},
        {label: '邮箱', value: 'email'}, {label: '智齿', value: 'sobot'},
        {label: '问题反馈', value: 'feedback'}];

    let splitActionSwitch = (action) => {
        let result = [];
        if (action) {
            for (let i = 0; i < action.length; i++) {
                if (action.charAt(i) == '1') {
                    result.push(checkboxEnum[i].value);
                }
            }
        }

        return result;
    };

    useEffect(async () => {
        console.log("useEffect");
        let info = await getVersionExtInfo(pname, type);
        prepareData(info);
    }, []);

    async function saveExtConfig(values) {
        if (Object.keys(values).length) {
            let originDataMap = {};
            originData.map(item => {
                originDataMap[item.cname] = item;
            });

            for (let i in values) {
                let contacts = values[i];
                let defaultAction = '00000';
                if (contacts) {
                    for (let j = 0; j < checkboxEnum.length; j++) {
                        if (contacts.indexOf(checkboxEnum[j].value) != -1) {
                            defaultAction = replaceChar(defaultAction, j, '1');
                        }
                    }
                }
                let cnameObj = originDataMap[i];
                cnameObj.action = defaultAction;
                cnameObj.checked = true;
            }

            const result = await request.post(API.update_version_ext_by_type, {
                pname: pname,
                type: type,
                typeInfos: originData
            }, 'json');
            if (result.data) {
                message.success("保存成功");
                setReadonly(true);

                let info = await getVersionExtInfo(pname, type);
                prepareData(info);
            } else {
                message.error("保存失败");
            }
        } else {
            setReadonly(true);
        }
    }

    return (
        <ProForm
            key={Math.random()}
            onFinish={saveExtConfig}
            {...FORM_LAYOUT.HORIZON_LEFT_SPAN_2_LAYOUT}
            submitter={{
                // 完全自定义整个区域
                render: (props, doms) => {
                    if (readonly) {
                        return [
                            <Button type="primary"
                                    onClick={() => {
                                        setReadonly(false);
                                    }}>编辑</Button>
                        ];
                    } else {
                        return [
                            <Button key="cancel" onClick={() => {
                                setReadonly(true);
                                setData(Object.assign([], JSON.parse(JSON.stringify(originData))));
                            }}>取消</Button>,
                            <Button key="submit" type="primary" onClick={() => {
                                props.form?.submit?.()
                            }
                            }>保存</Button>
                        ];
                    }
                }
            }}
        >

            {
                data && data.map(item => {
                    let cname = item.cname;
                    let action = item.action;
                    let defaultValue = splitActionSwitch(action);
                    return <ProFormCheckbox.Group
                        label={cname}
                        value={defaultValue}
                        disabled={readonly}
                        name={cname}
                        // key={cname}
                        options={checkboxEnum}
                        onChange={(checked) => data.filter(item => item.cname === cname)[0].checked = checked}
                    />
                })
            }
        </ProForm>
    )
}


export default ContactEntranceSwitchExt;
