import React, {useRef, useState} from 'react';
import ProTable, {TableDropdown} from '@ant-design/pro-table';
import ProCard from '@ant-design/pro-card';
import {Button, message} from 'antd';
import {getUserInfo, updateMemberInfo, migration, addBlacklist, removeBlacklist} from '@/api/mock';
import {ModalForm, ProFormText} from "@ant-design/pro-form";
import {CARD_STYLES, FORM_LAYOUT, TABLE_PROPS} from "../../commonLayout";
import {ENUMS} from "../../../common/enums";
import moment from "moment";
import {timeFormatter, isProj} from "../../../utils";

const UserInfo = () => {
    const [config, setConfig] = useState(TABLE_PROPS.COMMON_PROPS);
    // const [username, setUsername] = useState();
    const actionRef = useRef();
    const formRef = useRef();

    let isActionTrack = isProj("ACTION_TRACK");

    const columns = [
        {
            title: '包名渠道',
            hideInSearch: true,
            valueEnum: ENUMS.PNAME_ENUM_MAP,
            width: 70,
            renderText: (text, record) => {
                return (ENUMS.PNAME_ENUM_MAP.get(record.pname) ?? record.pname) + '\n' + record.cname;
            }
        },
        {
            title: '流水号',
            dataIndex: 'outerTid',
            hideInTable: true,
            fieldProps: {
                autoComplete: "off"
            },
        },
        {
            title: '用户名',
            dataIndex: 'username',
            formItemProps: {
                rules: [
                    {
                        validator: (rules, value, callback) => {
                            const username = formRef.current?.getFieldValue('username');
                            const uid = formRef.current?.getFieldValue('uid');
                            const outerTid = formRef.current?.getFieldValue('outerTid');
                            if (!outerTid && !username && !uid) {
                                callback('流水号、用户名、设备号必填其一');
                            } else {
                                callback();
                            }
                        },
                    }
                ]
            },
            render: (_, row, index, action) => {
                return row.isDel ? {
                    children:
                        <span>{_} </span>,
                    props: {
                        style: {
                            textDecoration: 'line-through'
                        }
                    }
                }
                    : <span>{_} </span>
            },
        },
        {
            sorter: true,
            title: '设备号',
            dataIndex: 'uid',
            ellipsis: true,
            copyable: true,
            fieldProps: {
                autoComplete: "off"
            },
        },
        {
            hideInSearch: true,
            title: '用户状态',
            dataIndex: 'isDel',
            render: (_, row, index, action) => {
                return _ ? {
                    children:
                        <span>已销户<br/>{timeFormatter(row.modified)}</span>,
                    props: {
                        style: {
                            background: 'var(--danger-color)',
                            color: 'white',
                        }
                    }
                }
                    : <span>正常 </span>
            },
        },
        {
            hideInSearch: true,
            title: '黑名单',
            dataIndex: 'isBlack',
            render: (_, row, index, action) => {
                return _ && _ === true ? {
                    children:
                        <span>是 </span>,
                    props: {
                        style: {
                            background: 'var(--danger-color)',
                            color: 'lightblue'
                        }
                    }
                }
                    : <span>否 </span>
            },
        },
        {
            hideInSearch: true,
            title: '到期时间',
            dataIndex: 'expiredTime',
            tip: '绿色表示当前在有效期内',
            render: (_, row, index, action) => {
                let result;
                if (row.isMember || moment(row.expiredTime) > moment()) {
                    result = {
                        children:
                            <span>{row.isMember ? '终身会员' : row.expiredTime}</span>,
                        props: {
                            style: {
                                background: 'var(--success-color)',
                            }
                        }
                    }
                } else {
                    result = {
                        children:<span>{row.expiredTime}</span>,
                        props: {style: {}}
                    };
                }
                if (row.isDel) {
                    result.props.style.textDecoration = 'line-through';
                }
                return result;
            },
        },
        {
            hideInSearch: true,
            title: '备注',
            dataIndex: 'remark',
        },
        {
            hideInSearch: true,
            title: '注册时间',
            dataIndex: 'created',
            renderText: timeFormatter
        },
        {
            hideInSearch: true,
            hideInTable: !isActionTrack,
            title: '最近更新',
            dataIndex: 'lastTime',
            renderText: timeFormatter,
            tip: '最近一次轨迹更新的时间',
        },
        {
            title: '操作',
            key: 'action',
            valueType: 'option',
            render: (text, record, _, action) => [
                <>
                    {record.isMember || new Date(record.expiredTime).getTime() > new Date().getTime() ?
                        <>
                            <a onClick={async () => {
                                const result = await updateMemberInfo({
                                    userId: record.id,
                                    expiredTime: '2000-01-01',
                                    lifeMember: false
                                });
                                if (result.data) {
                                    message.success('修改成功');
                                    actionRef.current.reload();
                                    return true;
                                }
                            }}>撤销会员</a>
                            <ModalForm
                                {...FORM_LAYOUT.MODAL_FORM_LAYOUT}
                                trigger={
                                    <a>会员迁移</a>
                                }
                                onFinish={async (values) => {
                                    const {toUsername} = values;
                                    const result = await migration({
                                        userId: record.id,
                                        username: toUsername
                                    });
                                    if (result.data) {
                                        message.success('修改成功');
                                        actionRef.current.reload();
                                        return true;
                                    }
                                    return false;
                                }}
                            >
                                <>
                                    <ProFormText label={'新账号'} placeholder={'输入将要迁入的账号'} name="toUsername"/>
                                </>
                            </ModalForm>
                        </>
                        :
                        <></>}
                    {
                        !record.isMember ?
                            <>
                                <ModalForm
                                    {...FORM_LAYOUT.MODAL_FORM_UN_CLOSABLE_LAYOUT}
                                    trigger={
                                        <a>设置会员时间</a>
                                    }
                                    onFinish={async (values) => {
                                        const {expiredTime} = values;
                                        const result = await updateMemberInfo({
                                            userId: record.id,
                                            expiredTime: expiredTime
                                        });
                                        if (result.data) {
                                            message.success('修改成功');
                                            actionRef.current.reload();
                                            return true;
                                        }
                                        return false;
                                    }}
                                >
                                    <>
                                        <ProFormText label={'到期时间'} placeholder={'格式例如:2021-03-04'} name="expiredTime"
                                        // initialValue={moment().format("YYYY-MM-DD")}
                                        />
                                    </>
                                </ModalForm>
                                <a onClick={async () => {
                                    const result = await updateMemberInfo({
                                        userId: record.id,
                                        lifeMember: 1
                                    });
                                    if (result.data) {
                                        message.info('修改成功');
                                        actionRef.current.reload();
                                        return true;
                                    }
                                }
                                }>设置终身会员</a>
                            </>
                            :
                            <></>

                    }

                </>
            ],
        },
    ];

    return (
        <ProCard
            split="vertical"
            bordered
            headerBordered
        >
            <ProCard
                style={CARD_STYLES.COMMON_MIN_HEIGHT_STYLE}
            >
                <ProTable
                    actionRef={actionRef}
                    formRef={formRef}
                    {...config}
                    search={{
                        ...config.search,
                        span: 6,
                        optionRender: ({searchText, resetText}, {form}, dom) => [
                            dom[1],
                            <Button type='danger' onClick={async () => {
                                const {username, uid, outerTid} = form.getFieldsValue();
                                if (username || uid || outerTid) {
                                    const result = await addBlacklist({
                                        username: username,
                                        uid: uid,
                                        outerTid: outerTid,
                                    });
                                    if (result.data) {
                                        message.info('添加成功');
                                        actionRef?.current.reload();
                                    }
                                }
                            }}>添加到黑名单</Button>,
                            <Button type='success' onClick={async () => {
                                const {username, uid, outerTid} = form.getFieldsValue();
                                if (username || uid || outerTid) {
                                    const result = await removeBlacklist({
                                        username: username,
                                        uid: uid,
                                        outerTid: outerTid,
                                    });
                                    if (result.data) {
                                        message.info('移出成功');
                                        actionRef?.current.reload();
                                    }
                                }
                            }}>移出黑名单</Button>,
                        ],
                    }}
                    columns={columns}
                    rowKey="id"
                    request={async (params, sorter, filter) => {
                        if (params.username) {
                            params.username = params.username.replace(/\s+/g, "");
                        }
                        return getUserInfo({...params, sorter, filter});
                    }}
                    manualRequest={true}
                    onLoad={(dataSource) => {
                        dataSource.length === 0 && message.info('没有数据');
                    }}
                />
            </ProCard>
        </ProCard>
    );
};

export default UserInfo;
