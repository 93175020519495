import React, {useEffect, useRef, useState} from 'react';
import {
    operationList,
    getAdminList
} from '@/api/mock'
import ProTable from "@ant-design/pro-table";
import ProCard from "@ant-design/pro-card";
import {CARD_STYLES, TABLE_PROPS} from "../../commonLayout";
import moment from "moment";
import {TimeFilter} from "../../../components/TimeFilter";
import {timeFormatter} from "../../../utils";
import {ENUMS} from "../../../common/enums";

function OperationLog() {
    const ref = useRef(undefined);
    const [config, setConfig] = useState(TABLE_PROPS.COMMON_PROPS);
    const [adminMap, setAdminMap] = useState([]);

    useEffect(async () => {
        const result = await getAdminList();
        if (result && result.data) {
            let map = new Map();
            result.data.forEach(d => {
                map.set(d.id, d.username);
            });
            setAdminMap(map);
        }
    }, []);


    const columns = [
        {
            title: '时间范围',
            key: 'dateRange',
            hideInTable: true,
            valueType: 'dateTimeRange',
            colSize: 4,
            search: {
                transform: (value) => ({startDate: value[0], endDate: value[1]}),
            },
            initialValue: [moment().subtract(2, 'd').startOf('day'), moment().endOf('day')],
            renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
                return <TimeFilter form={form} format={'YYYY/MM/DD HH:mm'} defaultShortcut={'recent3days'}/>
            }
        },
        {
            title: '管理员',
            dataIndex: 'adminId',
            valueEnum: adminMap,
            width: 80
        },
        {
            title: '操作类型',
            dataIndex: 'actionDesc',
            hideInSearch: true,
            width: 120
        },
        {
            title: '行为',
            dataIndex: 'detail',
            hideInSearch: true,
        },
        // {
        //     title: '快照',
        //     dataIndex: 'preSnapshot',
        //     hideInSearch: true,
        //     ellipsis: true,
        // },
        {
            title: '项目',
            dataIndex: 'proj',
            hideInSearch: true,
            width: 100,
            render: (_, row, index, action) => {
                return ENUMS.PROJ_ENUM_MAP.get(_) ?? _
            }

        },
        {
            title: 'URI',
            dataIndex: 'uri',
            hideInSearch: true,
            width: 180,
        },
        {
            title: '操作ip',
            dataIndex: 'ip',
            hideInSearch: true,
            width: 120
        },
        {
            title: '请求ID',
            dataIndex: 'requestId',
            hideInSearch: true,
            ellipsis: true,
            copyable: true,
            width: 100
        },
        {
            title: '操作时间',
            dataIndex: 'created',
            hideInSearch: true,
            renderText: timeFormatter,
            width: 120
        },
    ];

    return (
        <>
            <ProCard
                split="vertical"
            >
                <ProCard
                    style={CARD_STYLES.COMMON_MIN_HEIGHT_STYLE}
                >
                    <ProTable
                        {...config}
                        actionRef={ref}
                        columns={columns}
                        rowKey="id"
                        request={async (params, sorter, filter) => {
                            const result = await operationList({...params});
                            return result;
                        }}
                        manualRequest={true}
                        recordCreatorProps={false}
                    />
                </ProCard>
            </ProCard>
        </>
    );
}

export default OperationLog;
