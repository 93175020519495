import {
    UsergroupAddOutlined,
    GithubOutlined,
    FileZipOutlined,
    ReadOutlined,
    SmileOutlined,
    HeartOutlined,
    CrownOutlined,
    TabletOutlined,
    SettingOutlined,
    InfoOutlined,
    BarChartOutlined,
    DashboardOutlined,
    ControlOutlined,
    EnvironmentOutlined,
    GlobalOutlined
} from '@ant-design/icons';
import React from "react";

export const IconMap = {
    smile: <SmileOutlined/>,
    dashboard: <DashboardOutlined/>,
    heart: <HeartOutlined/>,
    data: <BarChartOutlined/>,
    crown: <CrownOutlined/>,
    table: <TabletOutlined/>,
    control: <ControlOutlined/>,
    info: <InfoOutlined/>,
    setting: <SettingOutlined/>,
};

export const ProjIconMap = {
    ACTION_TRACK: <EnvironmentOutlined/>,
    POETRY: <ReadOutlined/>,
    LOVE_TALK: <UsergroupAddOutlined/>,
    ANIMAL_TRANSLATION: <GithubOutlined/>,
    FILE_MANAGER: <FileZipOutlined/>,
    SCENE: <GlobalOutlined/>,
};