import React, {useEffect, useState} from 'react';
import ProForm, {
    ProFormRadio,
    ProFormCheckbox,
} from '@ant-design/pro-form';
import ProCard from "@ant-design/pro-card";
import {Button, Tabs, message} from 'antd';
import {getPayConfigByPname, savePayConfig} from '@/api/mock';

const {TabPane} = Tabs;


const layout = {
    layout: "horizon",
    labelCol: {span: 3},
    labelAlign: 'left',
};


function getWxpayMerchantChoice(payConfig, selectedWxAppId) {
    const availableMap = payConfig?.availableWxpayConfigMap;
    return availableMap ?
        availableMap[selectedWxAppId ?? payConfig?.wxAppId]?.map(item => {
            let obj = {};
            obj.label = item.company + '(' + item.payment + ')';
            obj.value = item.id;
            return obj;
        }) : undefined;
}

function PaySwitchConfig() {
    const [payConfig, setPayConfig] = useState(undefined);
    const [readonly, setReadonly] = useState(true);

    const [pnameMap, setPnameMap] = useState(undefined);
    const [selectedPname, setSelectedPname] = useState(undefined);
    const [selectedWxAppId, setSelectedWxAppId] = useState(undefined);

    function renderForm() {
        return <ProForm
            style={{paddingTop: 40}}
            {...layout}
            onFinish={saveConfig}
            submitter={{
                // 完全自定义整个区域
                render: (props, doms) => {
                    if (readonly) {
                        return [
                            <Button type="primary"
                                    onClick={() => setReadonly(false)}>编辑</Button>
                        ];
                    } else {
                        return [
                            <Button key="cancel" onClick={() => {
                                setReadonly(true);
                                props.form?.resetFields();
                                // payConfig.map(config => {
                                //     tabWxAvailableMap[config.productName] = config.availableWxpayConfigMap;
                                //     setTabWxAvailableMap(Object.assign({}, tabWxAvailableMap));
                                //
                                //     tabSelectedWxAppId[config.productName] = config.wxAppId;
                                //     setTabSelectedWxAppId(Object.assign({}, tabSelectedWxAppId));
                                //
                                // })
                            }}>取消</Button>,
                            <Button key="submit" type="primary" onClick={() => {
                                props.form?.submit?.();
                            }
                            }>保存</Button>
                        ];
                    }

                },
            }}

            initialValues={{
                // id: payConfig?.id,
                paySwitch: [payConfig?.aliPaySwitch === 1 ? 'alipay' : '', payConfig?.wxPaySwitch === 1 ? 'wx' : ''],
                alipayConfigId: payConfig?.alipayConfigId,
                wxAppId: payConfig?.wxAppId,
                wxpayConfigId: payConfig?.wxpayConfigId
            }}
        >
            {/*<ProFormText hidden name={payConfig?.packageName}/>*/}
            {/*<ProFormText hidden name={payConfig?.id}/>*/}
            <ProFormRadio.Group
                disabled={readonly}
                name="alipayConfigId"
                label="支付宝"
                options={payConfig?.availableAlipayConfig?.map(item => {
                    let obj = {};
                    obj.label = item.company + ' (' + item.payment + ')';
                    obj.value = item.id;
                    return obj;
                })}
            />

            <ProFormRadio.Group
                fieldProps={{
                    // value: tabSelectedWxAppId[i.productName],
                    onChange:
                        (e) => {
                            setSelectedWxAppId(Object.assign({}, e.target.value));
                        },
                }}
                disabled={readonly}
                name="wxAppId"
                label="微信appId"
                options={payConfig?.availableWxpayAppIds?.split(",").map(i => {
                    let obj = {};
                    obj.value = i;
                    obj.label = i;
                    return obj;
                })}
            />

            <ProFormRadio.Group
                disabled={readonly}
                // initialValue={i.wxpayConfigId}
                // value={i.wxpayConfigId}
                name="wxpayConfigId"
                label="微信商户"
                options={getWxpayMerchantChoice(payConfig, selectedWxAppId)}
            />

            <ProFormCheckbox.Group
                disabled={readonly}
                name="paySwitch"
                label="支付方式开关"
                options={[{label: '支付宝', value: 'alipay'}, {label: '微信', value: 'wx'}]}
            />
        </ProForm>
    }

    async function tabClick(pname) {
        const result = await getPayConfigByPname({pname: pname});
        if (result.data) {
            setPayConfig(result.data)
        }
        setSelectedPname(pname);
    }


    async function saveConfig(values) {
        if (!payConfig || !payConfig.id) {
            message.error('系统异常');
            return;
        }

        let payTypeConcat = values.paySwitch?.join(',');

        if (!payTypeConcat) {
            message.error("至少开启一种支付方式");
            return;
        } else if (payTypeConcat.indexOf('alipay') !== -1 && payTypeConcat.indexOf('wx') !== -1) {
            values.paySwitch = '11';
        } else if (payTypeConcat.indexOf('alipay') !== -1) {
            values.paySwitch = '10';
        } else if (payTypeConcat.indexOf('wx') !== -1) {
            values.paySwitch = '01';
        } else {
            message.error("配置异常");
            return;
        }
        values.id = payConfig.id;
        const result = await savePayConfig(values);
        if (result.data) {
            setReadonly(true);
            message.info('保存成功');
        }
    }

    useEffect(async () => {
        let pMap = JSON.parse(window.localStorage.getItem('pnameMap'));
        setPnameMap(pMap);
        tabClick(Object.keys(pMap)[0]);
    }, []);

    return (
        <>
            <ProCard gutter={8}>
                <Tabs type="card" size="large" tabBarGutter={8} onTabClick={tabClick}>
                    {
                        pnameMap && Object.keys(pnameMap).map(key => {
                            return <TabPane tab={pnameMap[key]} key={key}>
                                {selectedPname === key && renderForm()}
                            </TabPane>
                        })}
                </Tabs>
            </ProCard>
        </>
    );
}

export default PaySwitchConfig;
