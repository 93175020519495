import React, {useState, useEffect} from 'react';
import ProCard from "@ant-design/pro-card";
import {Modal, Input, message, Divider, Col, Button, Row} from 'antd';
import {getPriceInfo, savePriceInfo} from '@/api/mock';
import PriceList from "./priceList"
import {ENUMS} from "../../../common/enums";
import {PlusOutlined} from '@ant-design/icons';
import LandscapePriceList from "./landscapePriceList";
import {ModalForm} from "@ant-design/pro-form";
import PriceFormMeta from "./priceFormMeta";


function PriceConfig() {
    const [priceInfoMap, setPriceInfoMap] = useState([]);
    const [reload, setReload] = useState(0);
    const [cnameModalVisible, setCnameModalVisible] = useState(false);
    // 需要添加渠道的包名
    const [selectedPackageKey, setSelectedPackageKey] = useState("");
    const [inputCname, setInputCname] = useState("");

    useEffect(async () => {
        let proj = window.localStorage.getItem('selectedProj');
        if (!proj) {
            message.error('请选择项目', 3)
        }
        const result = await getPriceInfo();
        setPriceInfoMap(result.data);
    }, [reload]);

    const renderCnameTabs = (cnamePriceMap, pname) => {
        const subTabs = [];
        Object.keys(cnamePriceMap).forEach((key) => {
            // 垂直展示的价格,指横幅价格
            let verticalPrice = [];
            // 常规横向展示的价格
            let horizonPrice = [];
            for (let i in cnamePriceMap[key]) {
                let price = cnamePriceMap[key][i]
                price.landscape ? verticalPrice.push(price) : horizonPrice.push(price);
            }

            subTabs.push(
                <ProCard.TabPane closable={false} key={key} tab={key}>
                    <ModalForm
                        layout={"vertical"}
                        width={400}
                        // labelCol={{span: 9}}
                        // wrapperCol={{offset: 1, span: 12}}
                        labelAlign={'left'}
                        trigger={
                            <Row>
                                <Col span={24}>
                                    <ProCard layout="center" style={{marginBottom: 12}}>
                                        <Button type="dashed">
                                            <PlusOutlined/> 新增套餐
                                        </Button>
                                    </ProCard>
                                </Col>
                            </Row>
                        }
                        onFinish={async (values) => {
                            values.pname = pname;
                            values.cname = key;

                            const result = await savePriceInfo(values);
                            if (result.data) {
                                message.success('保存成功');
                                setReload(Math.random());
                                return true;
                            } else {
                                message.success('保存失败');
                            }
                        }}
                        modalProps={{
                            destroyOnClose: true,
                            bodyStyle: {
                                paddingTop: 30,
                            },
                            title: '添加套餐',
                            centered: true,
                            okText: '添加',
                        }}
                        initialValues={{
                            landscape: 0,
                            subscribe: 0,
                        }}
                    >
                        <PriceFormMeta isAdd={true}/>
                    </ModalForm>

                    <LandscapePriceList priceInfos={verticalPrice} setReload={setReload} pname={pname} cname={key}/>

                    <Divider/>

                    <PriceList priceInfos={horizonPrice} setReload={setReload} pname={pname} cname={key}/>
                </ProCard.TabPane>
            );
        });
        return subTabs;
    };

    const renderPackageTabs = () => {
        const tabs = [];
        priceInfoMap && Object.keys(priceInfoMap).forEach((key) => {
            let pname = key;
            let productName = ENUMS.PNAME_ENUM_MAP.get(key) ?? key;

            tabs.push(
                <ProCard.TabPane key={pname} tab={productName}>
                    <ProCard
                        tabs={{
                            tabPosition: 'top',
                            type: 'editable-card',
                            size: 'small',
                            onEdit: (targetKey, action) => {
                                if (action === 'add') {
                                    setCnameModalVisible(true);
                                    setSelectedPackageKey(key);
                                    return;
                                }
                            }
                        }}
                    >
                        {renderCnameTabs(priceInfoMap[key], key)}
                    </ProCard>

                </ProCard.TabPane>
            );
        });
        return tabs;
    };

    return (
        <>
            <ProCard gutter={8}>
                <ProCard
                    tabs={{
                        type: 'card',
                        size: 'large'
                    }}
                >
                    {renderPackageTabs()}
                </ProCard>

                <Modal closable={false} destroyOnClose={true} centered
                       visible={cnameModalVisible} width={300} cancelText={'取消'} okText={'添加'}
                       onOk={() => {
                           let cnameMap = priceInfoMap[selectedPackageKey];
                           cnameMap[inputCname] = [];
                           setPriceInfoMap(Object.assign({}, priceInfoMap));
                           setCnameModalVisible(false);
                       }}
                       onCancel={() => {
                           setCnameModalVisible(false);
                       }}>
                    <Input placeholder="请输入渠道" onChange={(e) => {
                        setInputCname(e.target.value);
                    }}/>
                </Modal>
            </ProCard>
        </>
    );
}

export default PriceConfig;