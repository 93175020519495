import {ExportOutlined} from '@ant-design/icons';
import React, {useState, useEffect, useRef} from 'react';
import ProTable from '@ant-design/pro-table';
import ProCard from '@ant-design/pro-card';
import {Button, Progress, message, Tag} from 'antd';
import {getIOSTradeInfo, exportHourStat} from '@/api/mock';
import moment from 'moment';
import {TimeFilter} from "../../../components/TimeFilter";
import {CARD_STYLES, TABLE_PROPS} from "../../commonLayout";
import {Pie, measureTextWidth} from '@ant-design/charts';
import {dateFormatter} from "../../../utils";

const {CheckableTag} = Tag;

function renderStatistic(containerWidth, text, style) {
    var _measureTextWidth = (0, measureTextWidth)(text, style),
        textWidth = _measureTextWidth.width,
        textHeight = _measureTextWidth.height;
    var R = containerWidth / 2;
    var scale = 1;
    if (containerWidth < textWidth) {
        scale = Math.min(
            Math.sqrt(
                Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))),
            ),
            1,
        );
    }
    var textStyleStr = 'width:'.concat(containerWidth, 'px;');
    return '<div style="'
        .concat(textStyleStr, ';font-size:')
        .concat(scale, 'em;line-height:')
        .concat(scale < 1 ? 1 : 'inherit', ';">')
        .concat(text, '</div>');
}


const IOSTradeInfo = () => {
    const [config, setConfig] = useState(TABLE_PROPS.COMMON_PROPS);
    const [selectedPname, setSelectedPname] = useState('');

    useEffect(() => {
        let proj = window.localStorage.getItem('selectedProj');
        if (!proj) {
            message.error('请选择项目', 3);
        }
    }, []);

    const pnameMap = JSON.parse(window.localStorage.getItem('pnameMap'));

    const columns = [
        {
            title: '包名',
            dataIndex: 'selectedPname',
            hideInTable: true,
            colSize: 3,
            renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
                let tabs = [];
                pnameMap && Object.keys(pnameMap).forEach(i => (
                    tabs.push(<CheckableTag
                        key={i}
                        checked={selectedPname === i}
                        onChange={() => {
                            setSelectedPname(i);
                            form.setFieldsValue({'selectedPname': i});
                            form?.submit();
                        }}
                    >
                        {pnameMap[i]}
                    </CheckableTag>)
                ));
                return (
                    <>
                        <CheckableTag
                            checked={selectedPname === ''}
                            onChange={() => {
                                setSelectedPname('');
                                form.setFieldsValue({'selectedPname': ''});
                                form?.submit();
                            }
                            }>全部</CheckableTag>
                        {tabs}
                    </>
                )
            }
        },
        {
            title: '时间范围',
            key: 'dateRange',
            hideInTable: true,
            valueType: 'dateTimeRange',
            colSize: 2,
            search: {
                transform: (value) => ({startDate: value[0], endDate: value[1]}),
            },
            initialValue: [moment().subtract(1, 'd').startOf('day'), moment().subtract(1, 'd').endOf('day')],
            renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
                return <TimeFilter form={form} format={'YYYY/MM/DD HH:mm'} defaultShortcut={'yesterday'}/>
            }
        },
        {
            title: '时间',
            dataIndex: 'date',
            hideInSearch: true,
            renderText: dateFormatter
        },
        {
            title: '激活人数',
            dataIndex: 'activateNum',
            hideInSearch: true,
        },
        {
            title: '注册人数',
            dataIndex: 'registerNum',
            hideInSearch: true,
        },
        {
            title: '激活注册率',
            dataIndex: 'activateRegisterRate',
            hideInSearch: true,
        },
        // {
        //     title: '付费金额',
        //     hideInSearch: true,
        //     dataIndex: 'payment',
        // },
        {
            hideInSearch: true,
            title: 'ARPU(激活)',
            dataIndex: 'activatePaymentPrice',
        },
        {
            hideInSearch: true,
            title: 'ARPU(注册)',
            dataIndex: 'registerPaymentPrice',
        },
        {
            hideInSearch: true,
            title: '激活付费率',
            dataIndex: 'activatePayRate',
        },
        {
            hideInSearch: true,
            title: '注册付费率',
            dataIndex: 'registerPayRate',
        },
        // {
        //     hideInSearch: true,
        //     title: '退款金额',
        //     dataIndex: 'refundFee',
        // },
        {
            hideInSearch: true,
            title: '退款率',
            dataIndex: 'payRefundRate',
        },
        {
            hideInSearch: true,
            title: '付款金额',
            render: (_, row, index, action) => {
                let items = row.iOSProductStatDTOs;
                if (!items) {
                    return row.payment;
                }
                items.every(item => item.priceName = item.days === 0 ? '终身' : item.days + '天');

                let config = {
                    data: items,
                    meta: {
                        days: {
                            alias: '套餐',
                            formatter: function formatter(v) {
                                return v === 0 ? '终身' : v + '天';
                            },
                        },
                    },
                    angleField: 'totalPayment',
                    colorField: 'priceName',
                    width: 160,
                    height: 300,
                    radius: 1,
                    innerRadius: 0.6,
                    legend: {
                        layout: 'vertical',
                        position: 'top',
                        itemName: {
                            formatter: function formatter(text, item, index) {
                                let a = items.filter(item => item?.priceName === text);
                                if (!a) return text;
                                return text + ' （￥' + a[0].totalPayment + ' ）';
                            },
                        },
                    },
                    label: {
                        type: 'inner',
                        offset: '-50%',
                        style: {textAlign: 'center', fontSize: 10},
                        autoRotate: false,
                        content: function content(_ref) {
                            return ''.concat((_ref.totalPayment / row.payment * 100).toFixed(1), '%');
                        },

                    },
                    statistic: {
                        title: {
                            customHtml: function customHtml(container, view, datum) {
                                let _container$getBoundin = container.getBoundingClientRect(),
                                    width = _container$getBoundin.width,
                                    height = _container$getBoundin.height;
                                let d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
                                let text = datum ? datum.priceName : '付款总计';
                                return renderStatistic(d, text, {fontSize: 18});
                            },
                        },
                        content: {
                            offsetY: 4,
                            style: {fontSize: '16px'},
                            customHtml: function customHtml(container, view, datum, data) {
                                let _container$getBoundin2 = container.getBoundingClientRect(),
                                    width = _container$getBoundin2.width;
                                let text = datum
                                    ? '\xA5 '.concat(datum.totalPayment)
                                    : '\xA5 '.concat(
                                        data.reduce(function (r, d) {
                                            return r + d.totalPayment;
                                        }, 0),
                                    );
                                return renderStatistic(width, text, {fontSize: 18});
                            },
                        },
                    },
                    interactions: [
                        {type: 'element-active'},
                        {type: 'pie-statistic-active'},
                    ],
                };
                return <Pie {...config} />;
            },
        },
        // {
        //     hideInSearch: true,
        //     title: '付费金额占比',
        //     dataIndex: 'iOSProductStatDTOs',
        //     render: (_, row, index, action) => {
        //         return row.iOSProductStatDTOs?.map(item =>
        //             <div>
        //                 <span>{item.days == 0 ? '终身' : item.days + '天'}</span>
        //                 <span style={{paddingLeft: 12}}>{`（￥${item.totalPayment} ）`}</span>
        //                 <Progress
        //                     strokeWidth={6}
        //                     percent={(item.totalPayment / row.payment * 100).toFixed(0)}/>
        //             </div>
        //         )
        //     },
        //     width: 200
        // },
        {
            hideInSearch: true,
            title: '退款金额',
            render: (_, row, index, action) => {
                let items = row.iOSProductStatDTOs;
                if (!items) {
                    return row.refundFee;
                }

                let config = {
                    data: items,
                    meta: {
                        days: {
                            alias: '套餐',
                            formatter: function formatter(v) {
                                return v === 0 ? '终身' : v + '天';
                            },
                        },
                    },
                    angleField: 'totalRefundFee',
                    colorField: 'priceName',
                    width: 160,
                    height: 300,
                    radius: 1,
                    innerRadius: 0.6,
                    legend: {
                        layout: 'vertical',
                        position: 'top',
                        itemName: {
                            formatter: function formatter(text, item, index) {
                                let a = items.filter(item => item?.priceName === text);
                                if (!a) return text;
                                return text + ' （￥' + a[0].totalRefundFee + ' ）';
                            },
                        },
                    },
                    label: {
                        type: 'inner',
                        offset: '-50%',
                        style: {textAlign: 'center', fontSize: 10},
                        autoRotate: false,
                        content: function content(_ref) {
                            return ''.concat((_ref.totalRefundFee / row.refundFee * 100).toFixed(1), '%');
                        },

                    },
                    statistic: {
                        title: {
                            customHtml: function customHtml(container, view, datum) {
                                let _container$getBoundin = container.getBoundingClientRect(),
                                    width = _container$getBoundin.width,
                                    height = _container$getBoundin.height;
                                let d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
                                let text = datum ? datum.priceName : '退款总计';
                                return renderStatistic(d, text, {fontSize: 18});
                            },
                        },
                        content: {
                            offsetY: 4,
                            style: {fontSize: '16px'},
                            customHtml: function customHtml(container, view, datum, data) {
                                let _container$getBoundin2 = container.getBoundingClientRect(),
                                    width = _container$getBoundin2.width;
                                let text = datum
                                    ? '\xA5 '.concat(datum.totalRefundFee)
                                    : '\xA5 '.concat(
                                        data.reduce(function (r, d) {
                                            return r + d.totalRefundFee;
                                        }, 0),
                                    );
                                return renderStatistic(width, text, {fontSize: 18});
                            },
                        },
                    },
                    interactions: [
                        {type: 'element-active'},
                        {type: 'pie-statistic-active'},
                    ],
                };
                return <Pie {...config} />;
            },
        }
        // {
        //     hideInSearch: true,
        //     title: '退款金额占比',
        //     dataIndex: 'iOSProductStatDTOs',
        //     render: (_, row, index, action) => {
        //         return row.iOSProductStatDTOs?.map(item =>
        //             <div>
        //                 <span>{item.days == 0 ? '终身' : item.days + '天'}</span>
        //                 <span style={{paddingLeft: 12}}>{`（￥${item.totalPayment} ）`}</span>
        //                 <Progress
        //                     strokeWidth={6}
        //                     percent={(item.totalRefundFee / row.refundFee * 100).toFixed(0)}/>
        //             </div>
        //         )
        //     },
        //     width: 200
        // },
    ];

    return (
        <>
            <ProCard
                split="vertical"
            >
                <ProCard
                    style={CARD_STYLES.COMMON_MIN_HEIGHT_STYLE}
                >
                    <ProTable
                        {...config}
                        search={
                            {
                                ...config.search,
                                optionRender: ({searchText, resetText}, {form}, dom) => [
                                    dom[1],
                                    <Button onClick={
                                        () => {
                                            exportHourStat({
                                                startDate: form.getFieldValue('startDate').format('yyyy-MM-DD HH:mm:ss'),
                                                endDate: form.getFieldValue('endDate').format('yyyy-MM-DD HH:mm:ss'),
                                                pname: form.getFieldValue('pname'),
                                                cname: form.getFieldValue('cname')
                                            });
                                        }
                                    } icon={<ExportOutlined/>}>导出</Button>,
                                ],
                            }
                        }
                        columns={columns}
                        rowKey="key"
                        request={async (params, sorter, filter) => {
                            const result = await getIOSTradeInfo({...params, sorter, filter});
                            return result;
                        }}
                    />
                </ProCard>
            </ProCard>
        </>
    );
};


export default IOSTradeInfo;