import React, {useRef, useState} from 'react';
import ProTable from '@ant-design/pro-table';
import ProCard from '@ant-design/pro-card';
import {getRefundApplyList, handleRefundApply, markRefundApplyFinish} from '@/api/mock';
import moment from "moment";
import {ENUMS as commonEnums} from "../../../common/enums";
import {TimeFilter} from "../../../components/TimeFilter";
import {CARD_STYLES, TABLE_PROPS} from "../../commonLayout";
import {message} from "antd";
import {timeFormatter} from "../../../utils";


const RefundApplyList = () => {
    const [config, setConfig] = useState(TABLE_PROPS.COMMON_PROPS);
    const formRef = useRef();
    const actionRef = useRef();

    const columns = [
        {
            title: '时间范围',
            key: 'dateRange',
            hideInTable: true,
            valueType: 'dateTimeRange',
            search: {
                transform: (value) => ({startDate: value[0], endDate: value[1]}),
            },
            initialValue: [moment().subtract(2, 'd').startOf('day'), moment().endOf('day')],
            colSize: 6,
            renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
                return <TimeFilter form={form} defaultShortcut={'recent3days'}/>
            }
        },
        {
            title: '客服分组',
            key: 'idDivisor',
            valueType: 'radioButton',
            hideInTable: true,
            initialValue: window.localStorage.getItem('idDivisor'),
            valueEnum: commonEnums.KEFU_ID_DIVISOR_ENUM,
            colSize: 2,
        },
        {
            title: '包名渠道',
            hideInSearch: true,
            valueEnum: commonEnums.PNAME_ENUM_MAP,
            width: 70,
            renderText: (text, record) => {
                return (commonEnums.PNAME_ENUM_MAP.get(record.pname) ?? record.pname) + '\n' + record.cname;
            }
        },
        {
            title: '用户名',
            dataIndex: 'username',
            hideInSearch: true,
        },
        {
            title: '申请时间',
            dataIndex: 'created',
            hideInSearch: true,
            renderText: timeFormatter,
        },
        {
            title: '审核状态',
            dataIndex: 'status',
            valueType: 'radioButton',
            // hideInTable: true,
            initialValue: 1,
            valueEnum: commonEnums.REFUND_APPLY_STATUS_ENUM,
            colSize: 2,
        },
        {
            title: '流水号',
            dataIndex: 'outerTid',
            hideInSearch: true,
            ellipsis: true,
            copyable: true,
        },
        {
            hideInSearch: true,
            title: '支付金额',
            dataIndex: 'payment',
            render: payment => <span>{payment > 0 ? '¥' + payment : '-'}</span>,
        },
        {
            hideInSearch: true,
            title: '申请原因',
            dataIndex: 'reason',
        },
        {
            title: '操作',
            key: 'action',
            valueType: 'option',
            render: (text, record, _, action) => [
                <>
                    {record.payStatus === 3 && record.status === 1 &&
                    <>
                        <a onClick={async () => {
                            const result = await handleRefundApply({
                                id: record.id,
                                status: 3,
                                outerTid: record.outerTid,
                                refundFee: record.payment,
                                removeMember: 1,
                                forbid: 1,
                            });
                            if (result.data) {
                                message.success('操作成功');
                                actionRef?.current.reload();
                            } else {
                                message.error('操作失败');
                            }
                        }}>拉黑退款</a>
                        < a onClick={async () => {
                            const result = await handleRefundApply({
                                id: record.id,
                                status: 4
                            });
                            if (result.data) {
                                message.success('操作成功');
                                actionRef?.current.reload();
                            } else {
                                message.error('操作失败');
                            }
                        }}>拒绝</a>
                    </>
                    }


                    {record.payStatus === 5 && record.status === 1 &&
                    <a onClick={async () => {
                        const result = await markRefundApplyFinish({id: record.id,});
                        if (result.data) {
                            message.success('标记成功');
                            actionRef?.current.reload();
                        } else {
                            message.error('操作失败');
                        }
                    }}>标记完成</a>
                    }
                </>
            ],
        },
    ];


    return (
        <ProCard
            split="vertical"
            bordered
            headerBordered
        >
            <ProCard
                style={CARD_STYLES.COMMON_MIN_HEIGHT_STYLE}
            >
                <ProTable
                    {...config}
                    formRef={formRef}
                    pagination={{
                        pageSize: 200,
                    }}
                    search={{
                        ...config.search,
                        span: 4
                    }}
                    actionRef={actionRef}
                    columns={columns}
                    rowKey="id"
                    request={(params, sorter, filter) => getRefundApplyList({...params, sorter, filter})}
                    onLoad={(dataSource) => {
                        dataSource.length === 0 && message.info('没有数据');
                    }}
                />
            </ProCard>
        </ProCard>
    )
        ;
};

export default RefundApplyList;
