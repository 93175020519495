import React, {useState, useEffect} from 'react';
import ProForm, {
    ProFormRadio,
} from '@ant-design/pro-form';
import {Button, message} from "antd";
import request from "../../../../api/request";
import {API} from "../../../../api/mock";
import {getVersionExtInfo} from "../util"
import {FORM_LAYOUT} from "../../../commonLayout";


function PayTypeKefuEntranceExt(prop) {
    const {pname, type} = prop;
    const [readonly, setReadonly] = useState(true);
    const [data, setData] = useState(undefined);
    const [originData, setOriginData] = useState(undefined);

    let prepareData = (info) => {
        setData(info);
        // 拷贝一份供取消编辑恢复用
        let objString = JSON.stringify(info);
        setOriginData(Object.assign([], JSON.parse(objString)));
    };

    useEffect(async () => {
        const result = await getVersionExtInfo(pname, type);
        prepareData(result);

    }, []);

    async function saveExtConfig(values) {
        if (Object.keys(values).length) {
            let originDataMap = {};
            originData.map(item => {
                originDataMap[item.cname] = item;
            });

            for (let i in values) {
                let cnameObj = originDataMap[i];
                cnameObj.checked = 1;
                cnameObj.action = values[i];
            }

            const result = await request.post(API.update_version_ext_by_type, {
                pname: pname,
                type: type,
                typeInfos: originData
            }, 'json');
            if (result.data) {
                message.success("保存成功");
                setReadonly(true);
                let info = await getVersionExtInfo(pname, type);
                prepareData(info);
            }
        } else {
            setReadonly(true);
        }
    }

    return (
        <ProForm
            key={Math.random()}
            {...FORM_LAYOUT.HORIZON_LEFT_SPAN_2_LAYOUT}
            onFinish={saveExtConfig}
            submitter={{
                // 完全自定义整个区域
                render: (props, doms) => {
                    if (readonly) {
                        return [
                            <Button type="primary"
                                    onClick={() => {
                                        setReadonly(false);
                                    }}>编辑</Button>
                        ];
                    } else {
                        return [
                            <Button key="cancel" onClick={() => {
                                // console.log('准备setReadonly');
                                setReadonly(true);
                                setData(Object.assign([], JSON.parse(JSON.stringify(originData))));
                                // props.form?.resetFields();
                            }}>取消</Button>,
                            <Button key="submit" type="primary" onClick={() => {
                                props.form?.submit?.()
                            }
                            }>保存</Button>
                        ];
                    }
                }
            }}
        >
            {
                data && data.map(item => {
                    let checked = item.checked;
                    let cname = item.cname;
                    let action = item.action;

                    return <ProFormRadio.Group
                        fieldProps={{
                            // 没配置过才会有checked不是true的情况,默认都是全开,所以!checked的时候是3
                            defaultValue: !checked ? '3': action,
                        }}
                        label={cname}
                        disabled={readonly}
                        name={cname}
                        key={cname}
                        options={[
                            {
                                label: '关闭',
                                value: '0',
                            },
                            {
                                label: '支付宝开',
                                value: '1',
                            },
                            {
                                label: '支付宝关',
                                value: '2',
                            },
                            {
                                label: '全开',
                                value: '3',
                            },
                        ]}
                        onChange={(checked) => data.filter(item => item.cname === cname)[0].checked = checked}
                    />
                })
            }
        </ProForm>
    )
}

export default PayTypeKefuEntranceExt;
