import React, {useState, useEffect} from 'react';
import ProForm, {
    ProFormRadio,
    ProFormText,
    ProFormDependency,
    ProFormList
} from '@ant-design/pro-form';
import {Button, message} from "antd";
import request from "../../../../api/request";
import {API} from "../../../../api/mock";
import {getVersionExtInfo} from "../util"
import {FORM_LAYOUT} from "../../../commonLayout";


function HidableTextExt(prop) {
    const {pname, type, fontParam, desc} = prop;
    const [readonly, setReadonly] = useState(true);
    const [data, setData] = useState(undefined);
    const [originData, setOriginData] = useState(undefined);

    let prepareData = (info) => {
        setData(info);
        // 拷贝一份供取消编辑恢复用
        let objString = JSON.stringify(info);
        setOriginData(Object.assign([], JSON.parse(objString)));
    };

    useEffect(async () => {
        const result = await getVersionExtInfo(pname, type);
        prepareData(result);

    }, []);

    async function saveExtConfig(values) {
        if (Object.keys(values).length) {
            let originDataMap = {};
            originData.map(item => {
                originDataMap[item.cname] = item;
            });

            for (let i in values) {
                let cnameObj = originDataMap[i];
                let cnameValue = values[i][0];
                if (!cnameValue.cnameRadio) {
                    message.error('渠道' + i + '选项错误');
                    return;
                }

                if (cnameValue.cnameRadio === 'b') {
                    cnameObj.title = 'none';
                } else if (cnameValue.cnameRadio === 'c') {
                    cnameObj.title = cnameValue.title;
                } else if (cnameValue.cnameRadio === 'a') {
                    cnameObj.title = '';
                }
                cnameObj.checked = !!cnameObj.title;
                cnameObj.text = cnameValue.text;
                cnameObj.text2 = cnameValue.text2;
            }

            const result = await request.post(API.update_version_ext_by_type, {
                pname: pname,
                type: type,
                typeInfos: originData
            }, 'json');
            if (result.data) {
                message.success("保存成功");
                setReadonly(true);
                let info = await getVersionExtInfo(pname, type);
                prepareData(info);
            }
        } else {
            setReadonly(true);
        }
    }

    return (
        <ProForm
            // className={'hidableExtLabelWidth'}
            key={Math.random()}
            {...FORM_LAYOUT.HORIZON_LEFT_SPAN_2_LAYOUT}
            onFinish={saveExtConfig}
            submitter={{
                // 完全自定义整个区域
                render: (props, doms) => {
                    if (readonly) {
                        return [
                            <Button type="primary"
                                    onClick={() => {
                                        setReadonly(false);
                                    }}>编辑</Button>
                        ];
                    } else {
                        return [
                            <Button key="cancel" onClick={() => {
                                setReadonly(true);
                                setData(Object.assign([], JSON.parse(JSON.stringify(originData))));

                                // props.form?.resetFields();
                            }}>取消</Button>,
                            <Button key="submit" type="primary" onClick={() => {
                                props.form?.submit?.()
                            }
                            }>保存</Button>
                        ];
                    }
                }
            }}
        >
            {/*<Title level={5}>{desc}</Title>*/}
            {
                data && data.map(item => {
                    // 这个是前端选择的
                    // let selected = item.selected;
                    // 这个是后端带来的值
                    let checked = item.checked;
                    let title = item.title;
                    let text = item.text;
                    let text2 = item.text2;
                    let defaultValue = '';
                    // if (selected) {
                    //     defaultValue = selected
                    // } else {
                    if (checked && title === 'none') {
                        defaultValue = 'b'
                    } else if (checked && title && title !== 'none') {
                        defaultValue = 'c'
                    } else {
                        defaultValue = 'a'
                    }
                    // }
                    let cname = item.cname;

                    return <ProFormList
                        name={cname}
                        label={cname}
                        labelCol={{span: 3}}
                        creatorButtonProps={false}
                        copyIconProps={false}
                        deleteIconProps={false}
                        initialValue={[
                            {
                                cname: cname,
                                cnameRadio: defaultValue,
                                title: title,
                                text: text,
                                text2: text2,
                            },
                        ]}
                    >
                        <ProForm.Group>
                            <ProFormRadio.Group
                                // fieldProps={{
                                //     onChange: e => {
                                //         data.filter(item => item.cname === cname)[0].selected = e.target.value
                                //     }
                                // }}
                                disabled={readonly}
                                name='cnameRadio'
                                key={cname}
                                options={[
                                    {
                                        label: '客户端默认值',
                                        value: 'a',
                                    },
                                    {
                                        label: '不展示',
                                        value: 'b',
                                    },
                                    {
                                        label: '展示',
                                        value: 'c',
                                    },
                                ]}
                            />

                            {fontParam && <ProForm.Group>
                                    <ProFormText width={80} disabled={readonly} name="text" placeholder={'字号'}
                                                 rules={[
                                                     {
                                                         pattern: /^\d+$/,
                                                         message: '字体必须为整数!',
                                                     },
                                                 ]}/>
                                    <ProFormText disabled={readonly} name="text2"
                                                 placeholder={'颜色 例:#ffffff'}/>
                                </ProForm.Group>}

                            <ProFormDependency layout={"horizon"} name={['cnameRadio']}>
                                {({cnameRadio}) => {
                                    if (cnameRadio !== 'c') {
                                        return null;
                                    }

                                    return <ProFormText width={'md'}
                                        disabled={readonly} name="title" placeholder={'请输入文案'}
                                        rules={[
                                            {
                                                required: true,
                                                message: '文案必填!',
                                            },
                                        ]}/>;
                                }}
                            </ProFormDependency>
                        </ProForm.Group>
                    </ProFormList>
                })
            }
        </ProForm>
    )
}

export default HidableTextExt;
