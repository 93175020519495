import React, {useEffect, useRef, useState} from 'react';
import ProTable from '@ant-design/pro-table';
import {Button, message, notification} from 'antd';
import ProForm, {ModalForm, DrawerForm, ProFormText} from "@ant-design/pro-form";
import {getEditorTemplateList, saveEditorTemplate, exportEditorTemplate} from "@/api/mock";
import {timeFormatter} from "../../../utils";
import BraftEditor from "braft-editor";
import 'braft-editor/dist/index.css'
import {FORM_LAYOUT} from "../../commonLayout";
import {RULES as RULE} from "../../../common/rules";

const layout = {
    layout: "horizon",
    // labelCol: {span: 4},
    // wrapperCol: {span: 16},
};

const EditorList = () => {
        const ref = useRef(undefined);
        const [modalVisible, setModalVisible] = useState(false);
        const [curRecord, setCurRecord] = useState(false);
        const [editorChanged, setEditorChanged] = useState(false);

        const columns = [
            {
                title: '模板名称',
                dataIndex: 'name',
                editable: false,
            },
            {
                title: '模板变量',
                dataIndex: 'params',
                editable: false,
                tooltip: '根据模板内容自动产生。模板内容中可定义变量,在导出时根据输入值自动填充,变量定义方式为${param} 用例：邮箱地址为:${email}  这里email会视为变量,导出时填入email的值则会替换所有的${email}',
            },
            {
                title: '模板摘要',
                dataIndex: 'preview',
                editable: false,
            },
            {
                title: '修改时间',
                dataIndex: 'modified',
                hideInSearch: true,
                editable: false,
                renderText: timeFormatter,
            },
            {
                title: '操作',
                key: 'option',
                valueType: 'option',
                width: 120,
                render: (text, record, _, action) => [
                    <a key="editable" onClick={() => {
                        setCurRecord(record);
                        setEditorChanged(false);
                        setModalVisible(true);
                    }}>编辑</a>,
                    <ModalForm
                        {...FORM_LAYOUT.MODAL_FORM_LAYOUT}
                        title={`输入信息`}
                        trigger={
                            <a>导出</a>
                        }
                        onFinish={async (values) => {
                            const {fileName} = values;
                            delete values['fileName'];
                            const result = await exportEditorTemplate({
                                fileName: fileName,
                                paramsJson: values ? JSON.stringify(values) : undefined,
                                templateId: record.id,
                            });
                            return true;
                        }}
                    >
                        <>
                            {record.params?.split(",").map((item) => (
                                <ProFormText name={item} label={item} placeholder="必填" rules={RULE.REQUIRED_RULE}/>
                            ))}
                            <ProFormText name="fileName" label="文件名" placeholder="选填"/>
                        </>
                    </ModalForm>,
                ],
            },
        ];

        return (
            <>
                <ProTable
                    actionRef={ref}
                    search={false}
                    columns={columns}
                    rowKey="id"
                    cardBordered={true}
                    request={async (params, sorter, filter) => {
                        const result = await getEditorTemplateList();
                        return result;
                    }}
                    // 工具栏拓展
                    toolBarRender={() => [
                        <DrawerForm
                            {...layout}
                            layout={"horizon"}
                            // title={"添加模板"}
                            visible={modalVisible}
                            onVisibleChange={setModalVisible}
                            // 触发点
                            trigger={
                                <Button type="primary" key="addRootMenu"
                                        onClick={() => {
                                            setModalVisible(true);
                                        }}>添加模板</Button>
                            }
                            onFinish={async (values) => {
                                const {id, name, editor} = values;
                                const result = await saveEditorTemplate({
                                    id: id,
                                    name: name,
                                    htmlContent: editor.toHTML(),
                                    rawContent: editor.toRAW()
                                });
                                if (result.data) {
                                    message.success('保存成功');
                                    setModalVisible(false);
                                    ref.current.reload();
                                } else {
                                    return false;
                                }
                            }}
                            onCal
                            width={'70%'}
                            drawerProps={{
                                maskClosable: false,
                                closable: false,
                                destroyOnClose: true,
                                bodyStyle: {
                                    paddingTop: 40
                                },
                                onClose: () => {
                                    if (editorChanged) {
                                        const key = 'editorChanged';
                                        notification.open({
                                            message: '是否放弃保存',
                                            description:
                                                '模板已变更但未变更,若想继续编辑,请右上角关闭弹窗',
                                            key: key,
                                            btn: <Button type="primary" size="small"
                                                         onClick={() => {
                                                             notification.close(key);
                                                             setModalVisible(false);
                                                         }}>
                                                放弃保存
                                            </Button>,
                                            duration: null,
                                            onClose: () => console.log('123')
                                        });
                                        setModalVisible(true);
                                    }
                                },
                                okText: '添加',
                            }}
                            initialValues={{
                                id: curRecord.id,
                                name: curRecord.name,
                                editor: BraftEditor.createEditorState(curRecord.rawContent),
                            }}
                        >
                            <>
                                <ProFormText name="id" hidden/>
                                <ProFormText name="name" placeholder="输入模板名称"
                                             rules={[{required: true, message: '模板名称不能为空'}]}
                                />
                                <ProForm.Item name="editor">
                                    <BraftEditor placeholder="请输入模板内容" onChange={() => setEditorChanged(true)}/>
                                </ProForm.Item>
                            </>
                        </DrawerForm>
                    ]}

                    recordCreatorProps={false}
                />
            </>
        );
    }
;

export default EditorList;
