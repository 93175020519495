import React, {useState, useEffect, useRef} from 'react';
import ProForm, {
    ProFormText,
    ProFormSwitch, ProFormRadio, ProFormDependency, ProFormList, ProFormGroup,
} from '@ant-design/pro-form';
import {Button, message} from "antd";
import request from "../../../../api/request";
import {API} from "../../../../api/mock";
import {FORM_LAYOUT} from "../../../commonLayout";
import {RULES} from "../../../../common/rules";
import Divider from "antd/es/divider";
import {getTaskConfigByType} from "@/api/mock";

function TextConfig(prop) {
    const {pname, type} = prop;
    const [readonly, setReadonly] = useState(true);
    const [data, setData] = useState(undefined);
    const [originData, setOriginData] = useState(undefined);

    let prepareData = (info) => {
        setData(info);
        // 拷贝一份供取消编辑恢复用
        let objString = JSON.stringify(info);
        setOriginData(Object.assign({}, JSON.parse(objString)));
    };


    useEffect(async () => {
        const result = await getTaskConfigByType({pname: pname, type: type});
        prepareData(result.data);
    }, []);

    async function saveTaskConfig(values) {
        let cnamesInfos = [];
        let cnames = Object.keys(data.jsonMap);
        for (let i = 0; i < cnames.length; i++) {
            let cname = cnames[i];
            let item = values[cname]?.[0];
            if (item) {
                let cnameInfo = {};
                cnameInfo['enable'] = item.enable;

                if (item.openTextRadio === 'b') {
                    cnameInfo['text'] = 'none';
                } else if (item.openTextRadio === 'c') {
                    cnameInfo['text'] = item.openTextValue;
                } else if (item.openTextRadio === 'a') {
                    cnameInfo['text'] = '';
                }


                if (item.offTextRadio === 'b') {
                    cnameInfo['offText'] = 'none';
                } else if (item.offTextRadio === 'c') {
                    cnameInfo['offText'] = item.offTextValue;
                } else if (item.offTextRadio === 'a') {
                    cnameInfo['offText'] = '';
                }
                cnameInfo['cname'] = item.cname;
                cnamesInfos.push(cnameInfo);
            }
        }
        const result = await request.post(API.save_task_config, {
            pname: pname,
            taskType: data.type,
            cnameInfos: cnamesInfos,
            startTime: values.startTime,
            endTime: values.endTime,
            enable: values.checked ?? data.enable,
        }, 'json');

        if (result.data) {
            message.info('保存成功');
            setReadonly(true);
            let info = await getTaskConfigByType({pname: pname, type: type});
            prepareData(info.data);
        }
    }

    return (
        <ProForm
            key={Math.random()}
            {...FORM_LAYOUT.HORIZON_LEFT_SPAN_2_LAYOUT}
            onFinish={saveTaskConfig}
            submitter={{
                // 完全自定义整个区域
                render: (props, doms) => {
                    if (readonly) {
                        return [
                            <Button type="primary"
                                    onClick={() => {
                                        setReadonly(false);
                                    }}>编辑</Button>
                        ];
                    } else {
                        return [
                            <Button key="cancel" onClick={() => {
                                setReadonly(true);
                                setData(Object.assign({}, JSON.parse(JSON.stringify(originData))));
                            }}>取消</Button>,
                            <Button key="submit" type="primary" onClick={() => {
                                props.form?.submit?.()
                            }
                            }>保存</Button>
                        ];
                    }
                }
            }}
        >
            {
                data && <>
                    <ProFormSwitch
                        label={'任务总开关'}
                        width={70}
                        checkedChildren="开启" unCheckedChildren="关闭"
                        disabled={readonly}
                        name="checked"
                        fieldProps={{
                            defaultChecked: data.enable,
                            onChange: (checked) => {
                                data.enable = checked;
                            }
                        }
                        }
                    />
                    <ProFormDependency layout={"horizon"} name={['checked']}>
                        {({checked}) => {
                            if (checked === true || (checked === undefined && data.enable === true)) {
                                return <>
                                    <ProFormText label={'开始时间'} width={260} disabled={readonly}
                                                 placeholder={"格式为 hh:mm 例:20:00"}
                                                 name="startTime" rules={RULES.MIN_SEC_RULE}
                                                 initialValue={data.startTime}
                                                 fieldProps={{
                                                     onChange: (e) => {
                                                         data.startTime = e.target.value;
                                                     }
                                                 }}
                                    />
                                    <ProFormText label={'结束时间'} width={260} disabled={readonly}
                                                 placeholder={"格式为 hh:mm 例:20:00"}
                                                 name="endTime" rules={RULES.MIN_SEC_RULE}
                                                 initialValue={data.endTime}
                                                 fieldProps={{
                                                     onChange: (e) => {
                                                         data.endTime = e.target.value;
                                                     }
                                                 }}/>

                                    {
                                        data && data.jsonMap && Object.keys(data.jsonMap).map(cname => {
                                            let item = data.jsonMap[cname];

                                            // 这个是后端带来的值
                                            let enable = item.enable;
                                            let text = item.text;
                                            let offText = item.offText;
                                            let defaultOpenValue = '';

                                            if (enable && text === 'none') {
                                                defaultOpenValue = 'b'
                                            } else if (enable && text && text !== 'none') {
                                                defaultOpenValue = 'c'
                                            } else {
                                                defaultOpenValue = 'a'
                                            }

                                            let defaultOffValue = '';
                                            if (enable && offText === 'none') {
                                                defaultOffValue = 'b'
                                            } else if (enable && offText && offText !== 'none') {
                                                defaultOffValue = 'c'
                                            } else {
                                                defaultOffValue = 'a'
                                            }

                                            return <ProFormList
                                                key={pname + cname + type}
                                                name={cname}
                                                label={cname}
                                                creatorButtonProps={false}
                                                copyIconProps={false}
                                                deleteIconProps={false}
                                                initialValue={[
                                                    {
                                                        cname: cname,
                                                        enable: enable,
                                                        openTextValue: item.text,
                                                        offTextValue: item.offText,
                                                        openTextRadio: defaultOpenValue,
                                                        offTextRadio: defaultOffValue
                                                    },
                                                ]}
                                            >
                                                <ProFormRadio.Group
                                                    // fieldProps={{
                                                    //     onChange: e => {
                                                    //         item.enable = e.target.value;
                                                    //     }
                                                    // }}
                                                    disabled={readonly}
                                                    name='enable'
                                                    key={cname}
                                                    options={[
                                                        {
                                                            label: '排除',
                                                            value: false,
                                                        },
                                                        {
                                                            label: '执行中',
                                                            value: true,
                                                        },
                                                    ]}
                                                />

                                                <ProFormDependency layout={"horizon"} name={['enable']}>
                                                    {({enable}) => {
                                                        if (enable !== true) {
                                                            return null;
                                                        }
                                                        return <>
                                                            <ProFormGroup>
                                                                <ProFormRadio.Group
                                                                    label={'开始后文案'}
                                                                    disabled={readonly}
                                                                    name='openTextRadio'
                                                                    key={cname}
                                                                    options={[
                                                                        {
                                                                            label: '客户端默认值',
                                                                            value: 'a',
                                                                        },
                                                                        {
                                                                            label: '不展示',
                                                                            value: 'b',
                                                                        },
                                                                        {
                                                                            label: '展示',
                                                                            value: 'c',
                                                                        },
                                                                    ]}
                                                                />
                                                                <ProFormDependency layout={"horizon"}
                                                                                   name={['openTextRadio']}>
                                                                    {({openTextRadio}) => {
                                                                        if (openTextRadio !== 'c') {
                                                                            return null;
                                                                        }
                                                                        return <ProFormText width="xl"
                                                                            disabled={readonly} name="openTextValue"
                                                                            placeholder={'请输入文案'}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: '文案必填!',
                                                                                },
                                                                            ]}/>;
                                                                    }}
                                                                </ProFormDependency>
                                                            </ProFormGroup>
                                                            <ProFormGroup>
                                                                <ProFormRadio.Group
                                                                    label={'结束后文案'}
                                                                    disabled={readonly}
                                                                    name='offTextRadio'
                                                                    key={cname}
                                                                    options={[
                                                                        {
                                                                            label: '客户端默认值',
                                                                            value: 'a',
                                                                        },
                                                                        {
                                                                            label: '不展示',
                                                                            value: 'b',
                                                                        },
                                                                        {
                                                                            label: '展示',
                                                                            value: 'c',
                                                                        },
                                                                    ]}
                                                                />
                                                                <ProFormDependency layout={"horizon"}
                                                                                   name={['offTextRadio']}>
                                                                    {({offTextRadio}) => {
                                                                        if (offTextRadio !== 'c') {
                                                                            return null;
                                                                        }
                                                                        return <ProFormText width="xl"
                                                                            disabled={readonly} name="offTextValue"
                                                                            placeholder={'请输入文案'}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: '文案必填!',
                                                                                },
                                                                            ]}/>;
                                                                    }}
                                                                </ProFormDependency>
                                                            </ProFormGroup>
                                                        </>
                                                    }}
                                                </ProFormDependency>
                                                <Divider/>
                                            </ProFormList>
                                        })
                                    }
                                </>
                            }
                            return null;
                        }}
                    </ProFormDependency>
                </>
            }
        </ProForm>
    )
}


export default TextConfig;
