export const FORM_LAYOUT = {
    HORIZON_LEFT_SPAN_2_LAYOUT: {
        layout: "horizon",
        labelCol: {span: 2},
        labelAlign: 'left'
    },

    MODAL_FORM_LAYOUT: {
        width: 400,
        layout: "horizon",
        labelCol: {span: 6},
        wrapperCol: {span: 16},
        modalProps: {
            onCancel: () => {
                return false;
            },
            maskClosable: false,
            destroyOnClose: true,
            bodyStyle: {
                paddingTop: 40
            },
            centered: true,
        }
    },

    MODAL_FORM_UN_CLOSABLE_LAYOUT: {
        width: 400,
        layout: "horizon",
        labelCol: {span: 6},
        wrapperCol: {span: 16},
        modalProps: {
            closable: false,
            onCancel: () => {
                return false;
            },
            maskClosable: false,
            destroyOnClose: true,
            bodyStyle: {
                paddingTop: 40
            },
        }
    }
};

export const CARD_STYLES = {
    COMMON_MIN_HEIGHT_STYLE: {
        minHeight: '80vh',
        overflow: 'scroll',
    }
};

export const TABLE_PROPS = {
    COMMON_PROPS: {
        bordered: true,
        size: 'large',
        expandable: false,
        headerTitle: '',
        showHeader: true,
        // rowSelection: {},
        // scroll: true,
        hasData: true,
        tableLayout: undefined,
        // 取消的话,设置为false
        footer: () => '',
        toolBarRender: () => [],
        // dateValueType: 'date',
        form: {
            ignoreRules: false,
        },
        // 影响页面是否会自动loading
        // loading: false,
        debounceTime: 500,
        search: {
            show: true,
            // 一共24,通过column中的colSize控制占用格子数,查询按钮也会占用
            span: 8,
            // labelWidth: 'auto',
            defaultCollapsed: false,
            collapseRender: false,
            labelWidth: 80,
            // filterType: 'query',
            layout: 'horizontal',
            searchText: '查询',
            optionRender: ({searchText, resetText}, {form}, dom) => [
                dom[1]
            ],
        },
        options: {
            reload: false,
            show: true,
            density: false,
            fullScreen: true,
            setting: true,
        },
        pagination: {
            show: true,
            pageSize: 10,
        }
    }
};