import React, {useState, useEffect} from 'react';
import ProForm, {
    ProFormText,
    ProFormRadio,
    ProFormFieldSet, ProFormSwitch, ProFormList
} from '@ant-design/pro-form';
import {Button, message, Switch} from "antd";
import request from "../../../../api/request";
import {API} from "../../../../api/mock";
import {getVersionExtInfo} from "../util";
import {FORM_LAYOUT} from "../../../commonLayout";


function RiskAreaExt(prop) {
    const {pname, type} = prop;
    const [readonly, setReadonly] = useState(true);
    const [data, setData] = useState(undefined);
    const [originData, setOriginData] = useState(undefined);

    let prepareData = (info) => {
        setData(info);
        // 拷贝一份供取消编辑恢复用
        let objString = JSON.stringify(info);
        setOriginData(Object.assign([], JSON.parse(objString)));
    };


    useEffect(async () => {
        console.log("useEffect");
        const result = await getVersionExtInfo(pname, type);
        prepareData(result);
    }, []);

    async function saveExtConfig(values) {
        if (Object.keys(values).length) {
            let originDataMap = {};
            originData.map(item => {
                originDataMap[item.cname] = item;
            });

            for (let i in values) {
                let cnameOrigin = originDataMap[i];
                // todo 这里不能换，后续再看看有没有更好方案
                let cnameValue = values[i][0];
                cnameOrigin.title = cnameValue.title;
                cnameOrigin.checked = cnameValue.checked;
            }

            const result = await request.post(API.update_version_ext_by_type, {
                pname: pname,
                type: type,
                typeInfos: originData
            }, 'json');
            if (result.data) {
                message.success("保存成功");
                setReadonly(true);
                const result = await getVersionExtInfo(pname, type);
                prepareData(result);
            }
        } else {
            setReadonly(true);
        }
    }

    return (
        <ProForm
            key={Math.random()}
            onFinish={saveExtConfig}
            {...FORM_LAYOUT.HORIZON_LEFT_SPAN_2_LAYOUT}
            submitter={{
                // 完全自定义整个区域
                render: (props, doms) => {
                    if (readonly) {
                        return [
                            <Button type="primary"
                                    onClick={() => {
                                        setReadonly(false);
                                    }}>编辑</Button>
                        ];
                    } else {
                        return [
                            <Button key="cancel" onClick={() => {
                                setReadonly(true);
                                setData(Object.assign([], JSON.parse(JSON.stringify(originData))));
                            }}>取消</Button>,
                            <Button key="submit" type="primary" onClick={() => {
                                props.form?.submit?.()
                            }
                            }>保存</Button>
                        ];
                    }
                }
            }}
        >

            {
                data && data.map(item => {
                    let checked = item.checked;
                    let cname = item.cname;
                    let title = item.title;

                    return <ProFormList
                        name={cname}
                        label={cname}
                        creatorButtonProps={false}
                        copyIconProps={false}
                        deleteIconProps={false}
                        initialValue={[
                            {
                                cname: cname,
                                title: title,
                                checked: checked,
                            },
                        ]}
                    >
                        <ProForm.Group>
                            <ProFormText
                                width={'md'} disabled={readonly} placeholder={"输入地区,多个用逗号隔开"} name="title"
                                onChange={(value) => data.filter(item => item.cname === cname)[0].title = value}
                            />
                            <ProFormSwitch
                                width={70}
                                checkedChildren="开启" unCheckedChildren="关闭"
                                disabled={readonly}
                                name="checked"
                                key={cname}
                                onChange={(checked) => data.filter(item => item.cname === cname)[0].checked = checked}
                            />
                        </ProForm.Group>
                    </ProFormList>

                })
            }
        </ProForm>
    )
}


export default RiskAreaExt;
