import ProCard from "@ant-design/pro-card";
import React, {useRef, useState} from 'react';
import {
    EditOutlined,
    QuestionCircleOutlined,
    LeftOutlined,
    DeleteOutlined,
    SaveOutlined
} from '@ant-design/icons';
import PriceFormMeta from "./priceFormMeta";
import {Popconfirm, message, Button, Badge} from 'antd';
import ProForm from "@ant-design/pro-form";
import {savePriceInfo, deletePriceInfo} from '@/api/mock';

const layout = {
    layout: "horizon",
    labelCol: {span: 9},
    wrapperCol: {offset: 3, span: 10},
    labelAlign: 'left',
    size: "small"
};

const landscapeLayout = {
    layout: "horizon",
    // labelCol: {span: 3},
    // wrapperCol: {offset: 3, span: 10},
    labelAlign: 'left',
    size: "small"
};


/**
 * 含编辑和保存的单个价格面板
 */
function PricePanel(props) {
    const [readonly, setReadonly] = useState(true);
    const {priceInfo, landscape, setReload} = props;

    const formRef = useRef();

    const savePrice = async (values) => {
        const result = await savePriceInfo(values);
        if (result.data) {
            setReadonly(true);
            message.success('保存成功');
            setReload(Math.random());
        }
    };

    const deletePrice = async () => {
        const result = await deletePriceInfo({id: priceInfo.id, pname: priceInfo.pname});
        if (result.data) {
            setReadonly(true);
            setReload(Math.random());
            message.success('删除成功');
        }
    };

    const options = readonly ?
        [
            <Button type="text" icon={<EditOutlined/>} onClick={() => {
                setReadonly(false)
            }}/>,

            <Popconfirm arrowPointAtCenter title="确认删除此套餐?" icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                        placement="topLeft" okText="确认" okType="danger" cancelText="再想想" onConfirm={deletePrice}>
                <DeleteOutlined key="ellipsis"/>
            </Popconfirm>,

        ]
        :
        [<LeftOutlined key="back"
                       onClick={() => {
                           formRef.current.resetFields();
                           setReadonly(true);
                       }
                       }/>,
            <Button type="text" icon={<SaveOutlined/>} htmlType="submit"/>];

    return (
        <ProForm
            formRef={formRef}
            name={priceInfo.id}
            {...landscapeLayout}
            onFinish={savePrice}
            submitter={{
                // 完全自定义整个区域
                render: (props, doms) => {
                },
            }}
            initialValues={{
                id: priceInfo?.id,
                pname: priceInfo?.pname,
                cname: priceInfo?.cname,
                days: priceInfo?.days,
                name: priceInfo?.name,
                price: priceInfo?.price,
                realPrice: priceInfo?.realPrice,
                firstPrice: priceInfo?.firstPrice,
                description: priceInfo?.description,
                landscape: priceInfo?.landscape ?? 0,
                subscribe: priceInfo?.subscribe ?? 0,
                // sort: priceInfo?.sort,
                hot: priceInfo?.hot,
            }}
        >
            {
                priceInfo.subscribe ?
                    <Badge.Ribbon text="订阅">
                        <ProCard  actions={options}>
                            <PriceFormMeta landscape={landscape} readonly={readonly}/>
                        </ProCard>
                    </Badge.Ribbon>
                    :
                    <ProCard  actions={options}>
                        <PriceFormMeta landscape={landscape} readonly={readonly}/>
                    </ProCard>
            }
        </ProForm>
    )
}

export default PricePanel