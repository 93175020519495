import Mock from 'mockjs';

Mock.mock('/backend/mock/failed', {
    "head": {
        "status": "N",
        "msg": "未登录",
        "code": "001",
        "time": "2019-05-20 00:30:00"
    },
    "body": null
});


Mock.mock('/mock/security/login', {
    "data": {
        "id": 1,
        "img": "",
        "loginTime": "2021-01-18 15:00:06",
        "token": "60e908a5e528a06d8197124c6ce468251bb9e361",
        "username": "admin"
    }, "requestId": "9e4ef7152ab44d088c11ef7007ff30a1", "result": 200
});

Mock.mock('/mockFail/security/login', {
    "error": "密码错误",
    "message": "密码错误",
    "requestId": "bd53ce83033249109e0a76627b1f63d7",
    "result": 100
});

Mock.mock('/mockFail/priceInfo', {
    "data": {
        "scp": {
            "default": [{
                "cname": "default",
                "days": 90,
                "description": "",
                "hot": false,
                "id": 1,
                "name": "3个月",
                "pname": "scp",
                "price": 28.00,
                "realPrice": 0.04
            }, {
                "cname": "default",
                "days": 1,
                "id": 86,
                "name": "2",
                "pname": "scp",
                "price": 3.00,
                "realPrice": 4.00
            }],
            "vivo": [{
                "cname": "vivo",
                "days": 2,
                "description": "",
                "hot": false,
                "id": 15,
                "name": "2dd2",
                "pname": "scp",
                "price": 2.00,
                "realPrice": 1.00
            }]
        },
        "qnp": {
            "default": [{
                "cname": "default",
                "days": 0,
                "description": "限时特惠",
                "hot": true,
                "id": 14,
                "name": "终身",
                "pname": "qnp",
                "price": 18.00,
                "realPrice": 8.00,
                "sort": 1
            }, {
                "cname": "default",
                "days": 90,
                "description": "限时特惠",
                "hot": false,
                "id": 13,
                "name": "3个月",
                "pname": "qnp",
                "price": 12.00,
                "realPrice": 6.00,
                "sort": 2
            }]
        },
        "dzp": {
            "default": [{
                "cname": "default",
                "days": 44,
                "description": "0.1",
                "hot": false,
                "id": 7,
                "name": "3",
                "pname": "dzp",
                "price": 1.00,
                "realPrice": 0.10
            }],
            "oppo": [{
                "cname": "oppo",
                "days": 12,
                "description": "",
                "hot": false,
                "id": 16,
                "name": "12",
                "pname": "dzp",
                "price": 1.00,
                "realPrice": 0.10
            }]
        }
    }, "requestId": "4f8b143588f048ef87904d0bf46c7779", "result": 200
});