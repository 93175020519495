import {Tree} from 'antd';
import React from "react";


const TreeExt = (props) => {
    const {onChange, value} = props;
    return <Tree {...props} selectedKeys={value}
                 onCheck={(e) => {
                     onChange && onChange(e.checked);
                 }}/>
};

export default TreeExt;