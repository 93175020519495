import {message, Menu, Dropdown} from 'antd';
import {useState, useEffect} from 'react'
import {DownOutlined, UserOutlined, LogoutOutlined, SyncOutlined} from '@ant-design/icons';
import React from 'react';
import history from '../../history';
import {getAvailablePnameCnames, freshAdminInfo} from '@/api/mock';
import {cleanLocalStorage} from "../../api/request";
import {loginOut} from "@/api/mock";
import {ProjIconMap} from "../../pages/Home/iconMap";


async function logout() {
    try {
        if (window.localStorage.getItem('token')) {
            await loginOut();
        }
    } catch (e) {
        console.log('会话过期');
    } finally {
        cleanLocalStorage();
        // 请求登出
        history.push('/login');
    }
}


function RightHeader() {
    const [selectedProjName, setSelectedProjName] = useState(undefined);
    const [projs, setProjs] = useState([]);

    useEffect(() => {
        let localProjs = undefined;
        try {
            localProjs = JSON.parse(window.localStorage.getItem('projs'));
            if (!localProjs) {
                logout();
                return;
            }
        } catch (e) {
            logout();
            return;
        }

        // 获取项目列表
        let selectedProj = window.localStorage.getItem('selectedProj');
        let selectedProjName = window.localStorage.getItem('selectedProjName');

        setProjs(
            <Menu onClick={handleMenuClick}>
                {
                    localProjs && localProjs.map(i => (
                        <Menu.Item key={i.value} icon={ProjIconMap[i.value]}>
                            {i.label}
                        </Menu.Item>
                    ))
                }
            </Menu>
        );

        if (!selectedProj) {
            const _ = _click(localProjs[0].value, localProjs[0].label);
        } else {
            setSelectedProjName(selectedProjName);
        }
    }, []);


    // 每次点击的时候重新获取
    async function _click(projCode, projName) {
        setSelectedProjName(projName);
        window.localStorage.setItem('selectedProj', projCode);
        window.localStorage.setItem('selectedProjName', projName);

        const result = await getAvailablePnameCnames({proj: projCode});
        if (!result.data || !result.data.pnames) {
            window.localStorage.setItem('selectedProj', '');
            window.localStorage.setItem('selectedProjName', '');
        } else {
            window.localStorage.setItem('cnames', JSON.stringify(result.data.cnames));
            window.localStorage.setItem('pnameMap', JSON.stringify(result.data.pnameMap));
            window.location.reload();
        }
    }

    function handleMenuClick(e) {
        _click(e.key, e.domEvent.currentTarget.innerText)
    }

    function handleUserMenuClick(e) {
        if (e.key === 2) {
            window.localStorage.setItem('token', '');
            window.localStorage.setItem('username', '');
            history.push('/login.html')
        }
    }

    return (
        <div>
            <span style={{marginRight: 40}}>你好， {window.localStorage.getItem('username')}。</span>

            {projs?.props?.children.length > 1
            && <Dropdown overlay={projs} placement="bottom">
                <a style={{color: "black"}} className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    当前项目: {selectedProjName} <DownOutlined/> <span>&nbsp;&nbsp;&nbsp;</span>
                </a>
            </Dropdown>}

            <SyncOutlined title={"刷新"} style={{marginRight: 30, marginLeft: 20, fontSize: 18}} onClick={async () => {
                const result = await freshAdminInfo();
                window.localStorage.setItem('menus', JSON.stringify(result.data.availableMenus ?? undefined));

                let availableProjs = result.data.availableProjs;
                if (!availableProjs) {
                    message.error("暂无权限");
                } else {
                    window.localStorage.setItem('projs', JSON.stringify(availableProjs));
                    history.push('/');
                }
            }}/>

            <LogoutOutlined title={"退出登录"} style={{marginRight: 20, fontSize: 18}} onClick={logout}/>

            {/*<Dropdown.Button overlay={menu} placement="bottomCenter" icon={<UserOutlined/>}>*/}
            {/*    你好， {window.localStorage.getItem('username')}*/}
            {/*</Dropdown.Button>*/}
        </div>
    );

}

export default RightHeader