import React, {useEffect, useState} from 'react';
import {Row, Col, Space} from 'antd';
import {RightOutlined, EllipsisOutlined, LikeOutlined} from '@ant-design/icons';
import ProCard, {StatisticCard} from '@ant-design/pro-card';
import {Funnel} from "@antv/g2plot";
import moment from "moment";
import MiniArea from "./MiniArea";
import {Progress} from '@ant-design/charts';
import RcResizeObserver from 'rc-resize-observer';


const {Statistic} = StatisticCard;

function MiniProgress({bgColor = '#5B8FF9', percent = 0.3}) {
    return (
        <Progress height={20} barWidthRatio={0.5} percent={percent} color={[bgColor, '#E8EDF3']}/>
    );
}

function DashBoard() {
    const [responsive, setResponsive] = useState(false);

    return (
        <div>

            <ProCard gutter={8} title="">
                <ProCard
                    tooltip="这是提示"
                    size="small"
                >
                    <Row>
                        <Col span={12}>
                            <h1>今日，{moment().format('YYYY年MM月DD日，dddd')}</h1>
                            上次登录时间：{window.localStorage.getItem('lastLoginTime') ?? '首次登陆'}
                        </Col>
                    </Row>
                </ProCard>
            </ProCard>

            <ProCard style={{marginTop: 24}}>
                <StatisticCard
                    title={
                        <Space>
                            <span>项目A</span>
                            <RightOutlined style={{color: 'rgba(0,0,0,0.65)'}}/>
                        </Space>
                    }
                    extra={<EllipsisOutlined/>}
                    statistic={{
                        value: 100,
                        prefix: '¥',
                        description: (
                            <Space>
                                <Statistic title="实际完成度" value="82.3%"/>
                                <Statistic title="当前目标" value="¥100"/>
                            </Space>
                        ),
                    }}
                    chart={
                        <>
                            <MiniProgress bgColor="#F4664A"/>
                            <MiniArea/>
                        </>
                    }
                    style={{width: 268}}
                />


                <StatisticCard
                    title="整体流量评分"
                    extra={<EllipsisOutlined/>}
                    statistic={{
                        value: 86.2,
                        suffix: '分',
                        description: <Statistic title="排名前" value="20%"/>,
                    }}
                    chart={<MiniProgress/>}
                    footer={
                        <>
                            <Statistic value={1000} title="累计注册数" suffix="万" layout="horizontal"/>
                            <Statistic value={1000} title="本月注册数" suffix="万" layout="horizontal"/>
                        </>
                    }
                    style={{width: 250, marginLeft: 100}}
                />
            </ProCard>

            <RcResizeObserver
                key="resize-observer"
                onResize={(offset) => {
                    setResponsive(offset.width < 640);
                }}
            >
                <ProCard style={{marginTop: 40}} split="vertical">
                    <StatisticCard
                        colSpan={responsive ? 12 : 6}
                        title="财年业绩目标"
                        statistic={{
                            value: 1000,
                            suffix: '亿',
                            description: <Statistic title="日同比" value="6.47%" trend="up"/>,
                        }}
                        chart={
                            <img
                                src="https://gw.alipayobjects.com/zos/alicdn/PmKfn4qvD/mubiaowancheng-lan.svg"
                                alt="进度条"
                                width="100%"
                            />
                        }
                        footer={
                            <>
                                <Statistic value="70.98%" title="财年业绩完成率" layout="horizontal"/>
                                <Statistic value="86.98%" title="去年同期业绩完成率" layout="horizontal"/>
                                <Statistic value="88.98%" title="前年同期业绩完成率" layout="horizontal"/>
                            </>
                        }
                    />
                    <StatisticCard.Group
                        gutter={12}
                        colSpan={responsive ? 12 : 18}
                        direction={responsive ? 'column' : undefined}
                    >
                        <StatisticCard
                            statistic={{
                                title: '财年总收入',
                                value: 123456789,
                                description: <Statistic title="日同比" value="6.15%" trend="up"/>,
                            }}
                            chart={
                                <img
                                    src="https://gw.alipayobjects.com/zos/alicdn/zevpN7Nv_/xiaozhexiantu.svg"
                                    alt="折线图"
                                    width="100%"
                                />
                            }
                        >
                            <Statistic
                                title="大盘总收入"
                                value={0}
                                layout="vertical"
                                description={<Statistic title="日同比" value="6.15%" trend="down"/>}
                            />
                        </StatisticCard>
                        <StatisticCard
                            statistic={{
                                title: '当日排名',
                                value: 6,
                                description: <Statistic title="日同比" value="3.85%" trend="down"/>,
                            }}
                            chart={
                                <img
                                    src="https://gw.alipayobjects.com/zos/alicdn/zevpN7Nv_/xiaozhexiantu.svg"
                                    alt="折线图"
                                    width="100%"
                                />
                            }
                        >
                            <Statistic
                                title="近7日收入"
                                value={0}
                                layout="vertical"
                                description={<Statistic title="日同比" value="6.47%" trend="up"/>}
                            />
                        </StatisticCard>
                        <StatisticCard
                            statistic={{
                                title: '财年业绩收入排名',
                                value: 2,
                                description: <Statistic title="日同比" value="6.47%" trend="up"/>,
                            }}
                            chart={
                                <img
                                    src="https://gw.alipayobjects.com/zos/alicdn/zevpN7Nv_/xiaozhexiantu.svg"
                                    alt="折线图"
                                    width="100%"
                                />
                            }
                        >
                            <Statistic
                                title="月付费个数"
                                value={0}
                                layout="vertical"
                                description={<Statistic title="日同比" value="6.47%" trend="down"/>}
                            />
                        </StatisticCard>
                    </StatisticCard.Group>
                </ProCard>
            </RcResizeObserver>
        </div>
    )
}

export default DashBoard;
