import React, {useState, useRef, useEffect} from 'react';
import ProTable from '@ant-design/pro-table';
import ProCard from '@ant-design/pro-card';
import {getPayInfo, updatePayLogRemark, getQuickPhrases, getPriceByPnameCname} from '@/api/mock';
import {Button, message, Tag, Input} from 'antd';
import {ENUMS} from "../../../common/enums";
import {
    PaperClipOutlined,
    FileSearchOutlined
} from '@ant-design/icons';
import ProForm, {ModalForm, ProFormText, ProFormSelect} from "@ant-design/pro-form";
import {CARD_STYLES, FORM_LAYOUT, TABLE_PROPS} from "../../commonLayout";
import {RULES as RULE} from "../../../common/rules";
import {updateWithdrawModal, _refund} from "./refund";
import './../../../index.less'
import moment from "moment";

const {TextArea} = Input;

const PayInfo = () => {
    const [config, setConfig] = useState(TABLE_PROPS.COMMON_PROPS);
    // 1.仅退款  2.退款取消会员  3.退款拉黑
    const [refundType, setRefundType] = useState(1);
    const [quickPhrases, setQuickPhrases] = useState();
    const [productPrice, setProductPrice] = useState();
    const [buttonLoading, setButtonLoading] = useState(false);
    const actionRef = useRef();
    const formRef = useRef();

    const [refundFee, setRefundFee] = useState();
    const [subFee, setSubFee] = useState();
    const [remark, setRemark] = useState();
    // const [username, setUsername] = useState();
    const pnameJson = window.localStorage.getItem('pnameMap');
    let pnameOption = [];
    if (pnameJson) {
        const pnameMap = pnameJson ? JSON.parse(pnameJson) : undefined;
        for (let key in pnameMap) {
            pnameOption.push({label: pnameMap[key], value: key});
        }
    }

    useEffect(async () => {
        const result = await getQuickPhrases({proj: window.localStorage.getItem('selectedProj'), type: 2, isDel: 0});
        if (result && result.data) {
            setQuickPhrases(result.data);
        }
    }, []);

    const refundModal = (row) => {
        let availableRefundFee = (row.payment - (row.refundFee ?? 0)).toFixed(2);
        // 前面已经判断过,只有已支付状态和已退款但未全部退款的情况才会进入到这个方法
        return <ModalForm
            {...FORM_LAYOUT.MODAL_FORM_LAYOUT}
            title={`可退：${availableRefundFee}￥，填写一个即可`}
            trigger={
                <a style={{fontSize: '12px'}} onClick={() => {
                    setRefundFee(undefined);
                    setSubFee(undefined);
                    setButtonLoading(false);
                }}> {row.status === 3 ? '退款' : '继续'}</a>

            }
            onFinish={async (values) => {
                if (refundFee > 0 && subFee >= 0) {
                    if (row.payment - (row.refundFee ?? 0) >= refundFee) {
                        await _refund(row.id, refundFee, refundType !== 1, refundType === 3, false, row.outerTid);
                        actionRef.current.reload();
                        return true;
                    } else {
                        message.error('退款金额超出支付金额');
                        return false;
                    }
                } else {
                    message.error('退款信息有误');
                    return false
                }
            }}
            submitter={{
                render: (props) => [
                    <Button loading={buttonLoading} onClick={() => {
                        setRefundType(1);
                        setButtonLoading(true);
                        props.form?.submit?.();
                    }}>仅退款</Button>,
                    <Button key='refundAndCancel' loading={buttonLoading} type="primary" onClick={() => {
                        setRefundType(2);
                        setButtonLoading(true);
                        props.form?.submit?.();
                    }}>退款关会员</Button>,
                    <Button key='refundAndAddBlacklist' loading={buttonLoading} type="primary" onClick={() => {
                        setRefundType(3);
                        setButtonLoading(true);
                        props.form?.submit?.();
                    }}>退款并拉黑</Button>,
                ]
            }}
        >
            {
                row.days != 0 && moment(row.payTime).add(row.days, 'days').isBefore(moment()) ?
                    <ProForm.Item><strong style={{
                        paddingLeft: 20,
                        color: 'red'
                    }}>VIP已到期</strong></ProForm.Item>
                    :
                    (moment(row.payTime).add(6, 'month').isBefore(moment()) ?
                        <ProForm.Item><strong style={{
                            paddingLeft: 20,
                            color: 'red'
                        }}>{moment(row.payTime).format('YY年MM月DD日')}支付，迄今{moment().diff(moment(row.payTime), 'month')}个月</strong></ProForm.Item>
                        : <></>)
            }
            <ProFormText name="refundFee" label="退款金额" placeholder="需要退回的金额" allowClear
                         rules={RULE.DIGIT_WITHIN_2_DECIMAL_RULE}
                         fieldProps={{
                             value: refundFee,
                             onChange: (e) => {
                                 // if (e.target.value) {
                                 setSubFee((row.payment - (row.refundFee ?? 0) - e.target.value).toFixed(2));
                                 setRefundFee(e.target.value);
                                 // }
                             }
                         }}/>
            <ProFormText name="subtractFee" label="扣除金额" placeholder="扣除的金额,剩余部分退回"
                         rules={RULE.DIGIT_WITHIN_2_DECIMAL_RULE}
                         fieldProps={{
                             value: subFee,
                             onChange: (e) => {
                                 // if (e.target.value) {
                                 setSubFee(e.target.value);
                                 setRefundFee((row.payment - (row.refundFee ?? 0) - e.target.value).toFixed(2));
                                 // }
                             }
                         }}/>
        </ModalForm>

    };


    const updateRemarkModal = (row) => {
        // 前面已经判断过,只有已支付状态和已退款但未全部退款的情况才会进入到这个方法
        return <ModalForm
            {...FORM_LAYOUT.MODAL_FORM_UN_CLOSABLE_LAYOUT}
            trigger={
                <PaperClipOutlined title={'备注'} style={{color: 'blue'}} onClick={() => setRemark(row.remark ?? '')}/>
            }
            onFinish={async (values) => {
                if (!remark) {
                    message.error('请修改备注');
                    return false;
                }
                const result = await updatePayLogRemark({id: row.id, remark: remark});
                if (result.data) {
                    message.info('修改成功');
                    actionRef.current.reload();
                    return true;
                }
                return false;
            }}
        >
            <>
                <TextArea name="remark" allowClear
                          autoSize={{minRows: 6, maxRows: 8}}
                          defaultValue={row.remark}
                          value={remark}
                          placeholder={"请输入备注"}
                          onChange={(e) => {
                              setRemark(e.target.value);
                          }}
                />
                {quickPhrases && quickPhrases.map(item =>
                    <Tag color="cyan" style={{whiteSpace: "normal", marginTop: 12}}
                         onClick={() => setRemark(item.content)}>{item.content}</Tag>
                )}
            </>
        </ModalForm>
    };

    const columns = [
        {
            title: '包名渠道',
            hideInSearch: true,
            valueEnum: ENUMS.PNAME_ENUM_MAP,
            width: 70,
            renderText: (text, record) => {
                return (ENUMS.PNAME_ENUM_MAP.get(record.pname) ?? record.pname) + '\n' + record.cname;
            }
        },
        {
            title: '流水号',
            dataIndex: 'outerTid',
        },
        {
            title: '用户名',
            tip: '登录的手机号',
            dataIndex: 'username',
            // fieldProps: {
            //     onChange: (e) => {
            //         setUsername(e.target.value.replace(/\s+/g, ""));
            //     },
            //     value: username
            // },
            formItemProps: {
                rules: [
                    {
                        validator: (rules, value, callback) => {
                            const username = formRef.current?.getFieldValue('username');
                            const uid = formRef.current?.getFieldValue('uid');
                            const outerTid = formRef.current?.getFieldValue('outerTid');
                            if (!outerTid && !username && !uid) {
                                callback('流水号、用户名、设备号必填其一');
                            } else {
                                callback();
                            }
                        },
                    }
                ]
            },
            width: 100,
        },
        {
            title: '设备号',
            dataIndex: 'uid',
            hideInTable: true,
        },
        {
            hideInSearch: true,
            title: '状态',
            dataIndex: 'status',
            width: 80,
            filters: [
                {
                    text: '支付中',
                    value: '支付中',
                },
                {
                    text: '已付款',
                    value: '已付款',
                },
                {
                    text: '已退款',
                    value: '已退款',
                },
            ],
            onFilter: (value, record) => record.statusDesc?.indexOf(value) === 0,
            render: (_, row, index, action) => [
                // 已付款或退款未完全时展示,支付方式为苹果支付时不展示退款入口
                (_ === 3 || (_ === 5 && row.payment > row.refundFee)) && row.payType !== 3 && moment(row.payTime).add(1, 'year').isAfter(moment()) ?
                    <>
                        <span>{ENUMS.PAY_STATUS_MAP.get(_)}</span>
                        {refundModal(row)}
                        {row.days != 0 && moment(row.payTime).add(row.days, 'year').isBefore(moment()) &&
                        <div style={{color: 'red'}}>已到期</div>}
                    </>
                    : <span>{ENUMS.PAY_STATUS_MAP.get(_)}</span>
            ]

        },
        {
            hideInSearch: true,
            title: '支付方式',
            dataIndex: 'payType',
            render: (_, row, index, action) => ENUMS.PAY_TYPE_ENUM.get(_) + (row.originTrxId ? '(续)' : ''),
            width: 90
        },
        {
            // sorter: (a, b) => a.payment - b.payment,
            hideInSearch: true,
            title: '支付',
            dataIndex: 'payment',
            render: payment => <span>{payment > 0 ? '¥' + payment : '-'}</span>,
        },
        {
            hideInSearch: true,
            title: '支付时间',
            dataIndex: 'payTime',
            valueType: 'dateTime',
            sorter: (a, b) => moment(a.payTime).isAfter(moment(b.payTime)),
        },
        {
            hideInSearch: true,
            title: '退款',
            dataIndex: 'refundFee',
            render: refundFee => <span>{refundFee > 0 ? '¥' + refundFee : '-'}</span>,
        },
        {
            hideInSearch: true,
            title: '退款时间',
            dataIndex: 'refundTime',
            valueType: 'dateTime',
        },
        {
            hideInSearch: true,
            title: '投诉',
            render: (_, row, index, action) => {
                return row.complainedTime ?
                    <>
                        <span>是 </span>
                        {row.payType === 1 && updateWithdrawModal(row)}
                    </>
                    :
                    <span>否</span>
            },
            width: 80
        },
        {
            hideInSearch: true,
            title: '备注',
            dataIndex: 'remark',
            render: (_, row, index, action) => [
                <>
                    <span>{_ + ' '}</span>
                    {updateRemarkModal(row)}
                </>
            ],
        },
        {
            hideInSearch: true,
            title: '套餐',
            dataIndex: 'days',
            render: days => <span>{days === 0 ? '终身' : days + '天'}</span>,
            width: 80
        },
    ];

    return (
        <ProCard
            split="vertical"
            bordered
            headerBordered
        >
            <ProCard
                style={CARD_STYLES.COMMON_MIN_HEIGHT_STYLE}>
                <ProTable
                    actionRef={actionRef}
                    formRef={formRef}
                    {...config}
                    rowClassName={(record) => {
                        if (record.complainedTime && !record.withdraw) {
                            return 'dangerRow'
                        } else if (record.status === 3) {
                            return 'successRow';
                        } else if (record.status === 5) {
                            return 'warningRow';
                        }
                    }}
                    search={{
                        ...config.search,
                        span: 6
                    }}
                    toolBarRender={
                        config?.toolBarRender ? () => [queryPriceModal()] : false
                    }
                    toolbar={{
                        // subTitle: '红色:已投诉(未撤诉); 浅红色:已退款; 绿色:已付款; 白色:未支付;',
                        subTitle:
                            <>
                                <span style={{
                                    background: "var(--danger-color)"
                                }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <span>&nbsp;&nbsp;已投诉</span>

                                <span style={{
                                    marginLeft: 12,
                                    background: "var(--warning-color)"
                                }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <span>&nbsp;&nbsp;已退款</span>

                                <span style={{
                                    marginLeft: 12,
                                    background: "var(--success-color)"
                                }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <span>&nbsp;&nbsp;已付款</span>
                            </>
                    }}
                    footer={() => '超1年无法退款；apple支付需用户主动联系苹果退款；续 表示连续订阅的账单'}
                    columns={columns}
                    rowKey="id"
                    request={async (params, sorter, filter) => {
                        if (params.username) {
                            params.username = params.username.replace(/\s+/g, "");
                        }
                        return getPayInfo({...params, sorter, filter});
                    }}
                    manualRequest={true}
                    onLoad={(dataSource) => {
                        dataSource.length === 0 && message.info('没有数据');
                    }}
                />
            </ProCard>
        </ProCard>
    );


    function queryPriceModal() {
        return <ModalForm
            {...FORM_LAYOUT.MODAL_FORM_LAYOUT}
            trigger={
                <Button type="primary" icon={<FileSearchOutlined/>}>价格查询器</Button>
            }
            onFinish={async (values) => {
                const {pname, cname} = values;
                const result = await getPriceByPnameCname({pname: pname, cname: cname});
                if (result && result.data) {
                    setProductPrice(result.data);
                }
            }}
        >
            <ProFormSelect name="pname" label="包名" rules={RULE.REQUIRED_RULE}
                           options={pnameOption}/>
            <ProFormText name="cname" label="渠道" placeholder="请输入正确的渠道" rules={RULE.REQUIRED_RULE}/>

            {productPrice && productPrice.map(item =>
                <Tag color="cyan">
                    {item.name + "：" + item.realPrice}
                </Tag>
            )}
        </ModalForm>

    }

};

export default PayInfo;
