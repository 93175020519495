import axios from "axios";
import {message} from "antd";
import querystring from 'querystring';
import qs from 'qs';
import history from "../history";

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.timeout = 15000;
const request = {
    handleData: function (res, resolve, reject) {
        const rsp = res.data;
        if (rsp.result === 600) {
            message.error(rsp.message, 2);
            reject && reject(res.data);
            cleanLocalStorage();
            history.push('/login');
        } else if (rsp.result === 700 || rsp.result === 100 || rsp.result === 400 || rsp.error) {
            message.error(rsp.message, 2);
            resolve(res.data);
        } else {
            resolve(res.data);
        }
    },
    post: function (url, params, type) {
        axios.defaults.headers.common['token'] = window.localStorage.getItem('token');
        axios.defaults.headers.common['proj'] = window.localStorage.getItem('selectedProj');

        let _this = this,
            config = {};
        if (type && type === "json") {
            config = {
                headers: {
                    post: {
                        'Content-Type': 'application/json'
                    }
                }
            }
        } else if (type && type === "file") {
            config = {
                // headers: {
                //     'Content-Type': 'multipart/form-data'
                // }
            }
        } else {
            params = querystring.stringify(params);
        }
        return new Promise((resolve, reject) => {
            axios.post(url, params, config)
                .then((res) => {
                    _this.handleData(res, resolve, reject);
                })
                .catch(reason => {
                    message.error('服务异常，可能正在发布，请30秒后重试');
                    reject('502');
                })
        });
    },
    get: function (url, params) {
        axios.defaults.headers.common['token'] = window.localStorage.getItem('token');
        axios.defaults.headers.common['proj'] = window.localStorage.getItem('selectedProj');
        let _this = this;
        return new Promise((resolve, reject) => {
            axios.get(url, {
                params,
                paramsSerializer: params => {
                    return qs.stringify(params)
                }
            }).then((res) => {
                _this.handleData(res, resolve, reject);
            }).catch(reason => {
                message.error('服务异常，可能正在发布，请30秒后重试');
                reject('502');
            })
        })
    },
    download: function (url, params) {
        axios.defaults.headers.common['token'] = window.localStorage.getItem('token');
        axios.defaults.headers.common['proj'] = window.localStorage.getItem('selectedProj');
        let _this = this;
        return new Promise((resolve, reject) => {
            axios.get(url, {
                params,
                responseType: 'blob',
                paramsSerializer: params => {
                    return qs.stringify(params)
                },
            }).then((res) => {
                if (res.data && res.data.error) {
                    message.error(res.data.error)
                } else {
                    convertRes2Blob(res);
                    resolve(res.data);
                }
            }).catch(reason => {
                message.error('下载失败');
                reject('502');
            })
        })
    }

};

export function convertRes2Blob(res) {
    let binaryData = [];
    binaryData.push(res.data)
    const url = window.URL.createObjectURL(new Blob(binaryData));
    const a = document.createElement('a');

    let contentDisposition = res.headers['content-disposition'];
    let patt = new RegExp("filename=([^;]+\\.[^\\.;]+);*");
    let result = patt.exec(contentDisposition);
    let filename = decodeURI(result[1]);

    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
}

export function cleanLocalStorage() {
    window.localStorage.setItem('projs', '');
    window.localStorage.setItem('username', '');
    window.localStorage.setItem('token', '');
    window.localStorage.setItem('cnames', '');
    window.localStorage.setItem('pnames', '');
    window.localStorage.setItem('pnameMap', '');
    window.localStorage.setItem('selectedProj', '');
    window.localStorage.setItem('selectedProjName', '');
    window.localStorage.setItem('menus', '');
    window.localStorage.setItem('idDivisor', '');
}


export default request;
