import React, {useEffect} from 'react';
import ProForm, {ProFormText} from '@ant-design/pro-form';
import {LockTwoTone, UserOutlined} from '@ant-design/icons';
import history from '../../history';
import {WaterMark} from '@ant-design/pro-layout';
import {message} from 'antd';
import "./index.less";
import {sleep} from "../../utils";
import {login} from "@/api/mock";

function Login() {

    useEffect(() => {
        if (window.localStorage.getItem('token') && window.localStorage.getItem('projs')) {
            history.push('/');
        }
    }, []);

    const onFinish = async (values) => {
        const {username, password} = values;

        await sleep(500);

        const result = await login({username: username, password: password});
        window.localStorage.setItem('username', username);
        window.localStorage.setItem('token', result.data.token);
        window.localStorage.setItem('idDivisor', result.data.idDivisor ?? -1);
        window.localStorage.setItem('lastLoginTime', result.data.lastLoginTime ?? "");
        window.localStorage.setItem('menus', (result.data.availableMenus && JSON.stringify(result.data.availableMenus)) ?? []);

        let availableProjs = result.data.availableProjs;
        if (!availableProjs) {
            message.error("暂无权限");
        } else {
            window.localStorage.setItem('projs', JSON.stringify(availableProjs));
            history.push('/');
        }
    };

    return (
        <WaterMark content="RICH" gapX={500}>
            <div className={'login'}>
                <ProForm className={'loginForm'}
                         onFinish={onFinish}
                         submitter={{
                             searchConfig: {
                                 resetText: '',
                                 submitText: '提交',
                             },
                             render: (_, dom) => dom.pop(),
                             submitButtonProps: {
                                 size: 'large',
                                 style: {
                                     width: '100%',
                                 },
                             },
                         }}
                >
                    <h1
                        style={{
                            textAlign: 'center',
                            marginBottom: 40,
                        }}>
                        <img
                            style={{
                                height: '44px',
                                marginRight: 16,
                            }}
                            alt="logo"
                            src="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
                        />
                        管理中心
                    </h1>
                    <ProFormText
                        fieldProps={{
                            size: 'large',
                            prefix: <UserOutlined style={{color: '#1890ff'}}/>,
                        }}
                        name="username"
                        placeholder="请输入用户名"
                        rules={[
                            {
                                required: true,
                                message: '请输入用户名!',
                            },
                        ]}
                    />
                    <ProFormText.Password
                        fieldProps={{
                            size: 'large',
                            prefix: <LockTwoTone/>,
                        }}
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: '请输入密码',
                            },
                        ]}
                        placeholder="请输入密码"
                    />
                </ProForm>
            </div>
        </WaterMark>
    );
}

export default Login;
