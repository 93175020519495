import {ModalForm} from "@ant-design/pro-form";
import {FORM_LAYOUT} from "../../commonLayout";
import {refund, withdrawRefund} from '@/api/mock'
import {Button, message} from "antd";
import React from "react";
import {
    EditOutlined,
} from '@ant-design/icons';
import {debounce} from "../../../utils";

export const updateWithdrawModal = (row) => {
    // 前面已经判断过,只有已支付状态和已退款但未全部退款的情况才会进入到这个方法
    return <ModalForm
        {...FORM_LAYOUT.MODAL_FORM_LAYOUT}
        trigger={
            <EditOutlined title={'更新投诉'} style={{color: 'blue'}}/>
        }
        onFinish={() => {
            return true;
        }}
        submitter={{
            render: (props) => {
                return [
                    <Button onClick={async () => {
                        let result = await _refund(row.id, row.payment - (row.refundFee ?? 0), true, false, true, row.outerTid);
                        if (result) {
                            props.form?.submit?.();
                        }
                    }}>
                        标记撤诉
                    </Button>,
                    <Button type="primary" onClick={async () => {
                        let result  = await _refund(row.id, row.payment - (row.refundFee ?? 0), true, true, true, row.outerTid);
                        if (result) {
                            props.form?.submit?.();
                        }
                    }}>
                        标记撤诉并拉黑
                    </Button>,
                ];
            },
        }}
    >
        <>
            标记撤诉会取消会员并退款
        </>
    </ModalForm>
};

export const _refund = async (id, refundFee, cancel, forbid, withdrawn, outerTid) => {
    let result;
    if (withdrawn) {
        result = await withdrawRefund({id, forbid, outerTid});
    } else {
        result = await refund({id, refundFee, cancel, forbid, outerTid});
    }
    if (result.data) {
        message.success('操作成功');
        return true;
    } else {
        message.error('操作失败');
        return false;
    }
};

export const callRefund = debounce(_refund, 100);