// 获取url参数
import moment from "moment";

export function formatUrlParams() {
    const urlParamsSuffix = getUrlParamsSuffix();
    const obj = {};
    if (urlParamsSuffix.length > 1) {
        let urlParamsSuffixSplit = urlParamsSuffix.split('&')
        for (let i = 0; i < urlParamsSuffixSplit.length; i++) {
            obj[urlParamsSuffixSplit[i].split('=')[0]] = urlParamsSuffixSplit[i].split('=')[1]
        }
        return obj;
    }
    return obj;
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function getUrlParamsSuffix() {
    let url = window.location.href;
    let str = '';
    if (url.indexOf('?') !== -1) {
        let startIndex = url.indexOf('?') + 1;
        if (url.indexOf('#') !== -1) {
            let endIndex = url.indexOf('#');
            str = url.substring(startIndex, endIndex)
        } else {
            str = url.substring(startIndex)
        }
    }
    return str;
}

// 包含时分秒
export function timeFormatter(value) {
    if (value === undefined) return '';
    let m = moment(value);
    let valueY = m.get('y');
    let nowY = moment().get('y');
    return valueY === nowY ? m.format('M/D HH:mm') : m.format('YY/M/D HH:mm');
}

// 不包含时分
export function dateFormatter(value) {
    if (value === undefined) return '';
    let m = moment(value);
    let valueY = m.get('y');
    let nowY = moment().get('y');
    return valueY === nowY ? m.format('M/D') : m.format('YY/M/D');
}

/**
 * 防重复提交，注意：如果是在function组件中使用，一定要用useCallback包裹起来，否则每次重新渲染，这个方法也会重新初始化
 * @param method 具体执行的方法
 * @param wait 重复提交间隔
 * @param immediate 是否立即执行
 * @returns {function(...[*]=): Promise<any>}
 */
export function debounce(method, wait, immediate) {
    let timeout;
    let debounced = function (...args) {
        return new Promise(resolve => {
            let result;
            let context = this;
            if (timeout) {
                clearTimeout(timeout)
            }
            if (immediate) {
                let callNow = !timeout;
                timeout = setTimeout(() => {
                    timeout = null
                }, wait);
                if (callNow) {
                    result = method.apply(context, args);
                    resolve(result);
                }
            } else {
                timeout = setTimeout(() => {
                    result = method.apply(context, args);
                    resolve(result);
                }, wait)
            }
        })
    };
    debounced.cancel = function () {
        clearTimeout(timeout);
        timeout = null
    };
    return debounced
}

// 判断当前页面是否为传入
export function isProj(proj) {
    return proj === window.localStorage.getItem('selectedProj');
}