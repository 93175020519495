import React, {useState, useRef, useEffect} from 'react';
import ProTable from '@ant-design/pro-table';
import ProCard from '@ant-design/pro-card';
import {getFriendList} from '@/api/mock';
import {ENUMS} from "../../../common/enums";
import {RULES as RULE} from "../../../common/rules";
import {CARD_STYLES, TABLE_PROPS} from "../../commonLayout";
import {message} from "antd";

const Friend = () => {
    const [config, setConfig] = useState(TABLE_PROPS.COMMON_PROPS);

    // 1.仅退款  2.退款取消会员  3.退款拉黑
    const columns = [
        {
            title: '包名',
            dataIndex: 'pname',
            valueEnum: ENUMS.PNAME_ENUM_MAP,
            formItemProps: {
                rules: RULE.REQUIRED_RULE
            },
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            hideInTable: true,
            formItemProps: {
                rules: RULE.REQUIRED_RULE
            },
        },
        {
            hideInSearch: true,
            title: '好友账号',
            dataIndex: 'username',
        },
        {
            hideInSearch: true,
            title: '备注名称',
            dataIndex: 'remark',
        },
        {
            hideInSearch: true,
            title: '屏蔽好友',
            dataIndex: 'hidden',
            valueEnum: ENUMS.YES_OR_NO_ENUM,
        },
        {
            hideInSearch: true,
            title: '上次更新时间',
            dataIndex: 'lastTime',
        },
        {
            hideInSearch: true,
            title: '好友到期时间',
            dataIndex: 'expiredTime',
        },
        {
            hideInSearch: true,
            title: '好友添加时间',
            dataIndex: 'created',
        },
    ];

    return (
        <ProCard
            split="vertical"
            bordered
            headerBordered
        >
            <ProCard
                style={CARD_STYLES.COMMON_MIN_HEIGHT_STYLE}>
                <ProTable
                    {...config}
                    columns={columns}
                    rowKey="id"
                    request={(params, sorter, filter) => {
                        return getFriendList({...params, sorter, filter});
                    }}
                    manualRequest={true}
                    onLoad={(dataSource) => {
                        dataSource.length === 0 && message.info('没有数据');
                    }}
                />
            </ProCard>
        </ProCard>
    );

};


export default Friend;
