import ProForm, {ProFormText, ProFormRadio} from "@ant-design/pro-form";
import React from 'react';
import {RULES as RULE} from "../../../common/rules";


const PriceFormMeta = (props) => {
    const {landscape, isAdd} = props;

    function part1() {
        return <>
            <ProFormText
                tooltip="非负整数，数字代表天数，0代表终身会员"
                readonly={props.readonly} name="days" label="套餐天数" placeholder="会员有效天数"
                rules={RULE.NON_NEGATIVE_INTEGER_RULE}/>
            <ProFormText
                readonly={props.readonly} name="name" label="套餐名称" placeholder="包月、终身会员等"
                rules={RULE.REQUIRED_RULE}
            />
            <ProFormText
                readonly={props.readonly} name="price" label="划线价"
                rules={RULE.DIGIT_WITHIN_2_DECIMAL_REQUIRED_RULE}/>
            <ProFormText
                readonly={props.readonly} name="realPrice" label="实际价格" placeholder="实际购买价"
                rules={RULE.DIGIT_WITHIN_2_DECIMAL_REQUIRED_RULE}/>
            <ProFormText
                tooltip="仅ios有效，首次首月订购活动价"
                readonly={props.readonly} name="firstPrice" label="ios优惠价" placeholder="首月优惠价"
                rules={RULE.DIGIT_WITHIN_2_DECIMAL_RULE}/>
            <ProFormText
                tooltip="注意字数，否则客户端可能遮挡展示"
                readonly={props.readonly} name="description" label="套餐描述" placeholder="如:更划算"/>
            {isAdd && <ProFormRadio.Group
                tooltip="仅部分项目的部分包支持"
                disabled={props.readonly}
                name="landscape"
                width={120}
                label="横向展示"
                options={[
                    {
                        label: '否',
                        value: 0,
                    },
                    {
                        label: '是',
                        value: 1,
                    },
                ]}
            />}
            {isAdd && <ProFormRadio.Group
                tooltip="慎重选择，影响扣款模式"
                fieldProps={{
                    style: {textColor:'blue'}
                }}
                disabled={props.readonly}
                name="subscribe"
                width={120}
                label="是否订阅"
                options={[
                    {
                        label: '否',
                        value: 0,
                    },
                    {
                        label: '是',
                        value: 1,
                    },
                ]}
            />}

            <ProFormRadio.Group
                tooltip="客户端展示是否推荐、是否热门"
                disabled={props.readonly}
                name="hot"
                width={120}
                label="推荐"
                options={[
                    {
                        label: '否',
                        value: 0,
                    },
                    {
                        label: '是',
                        value: 1,
                    },
                ]}
            />
        </>;
    }

    return (<>
        <ProFormText hidden name="id"/>
        <ProFormText hidden name="pname"/>
        <ProFormText hidden name="cname"/>
        {landscape ?
            <ProForm.Group>
                {part1()}
            </ProForm.Group>
            : part1()}


    </>)
};

export default PriceFormMeta