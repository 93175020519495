import React, {useEffect, useRef, useState} from 'react';
import {BackTop, message, Tag, Input} from 'antd';
import {getCallInfo, updateCallInfoRemark, getQuickPhrases} from '@/api/mock'
import moment from "moment";
import ProTable from "@ant-design/pro-table";
import {TimeFilter} from "../../components/TimeFilter";
import {ENUMS} from "../../common/enums";
import {FORM_LAYOUT, TABLE_PROPS} from "../commonLayout";
import {ModalForm} from "@ant-design/pro-form";
import {
    PaperClipOutlined,
} from '@ant-design/icons';
import './../../index.less'

const {TextArea} = Input;

function CallInfo() {
    const actionRef = useRef();
    const [quickPhrases, setQuickPhrases] = useState();
    const [remark, setRemark] = useState();

    useEffect(async () => {
        const result = await getQuickPhrases({proj: window.localStorage.getItem('selectedProj'), type: 3, isDel: 0});
        if (result && result.data) {
            setQuickPhrases(result.data);
        }
    }, []);

    const updateRemarkModal = (row) => {
        // 前面已经判断过,只有已支付状态和已退款但未全部退款的情况才会进入到这个方法
        return <ModalForm
            {...FORM_LAYOUT.MODAL_FORM_UN_CLOSABLE_LAYOUT}
            trigger={
                <PaperClipOutlined title={'备注'} style={{color: 'blue'}}/>
            }
            initialValues={{
                remark: row.remark ?? '未曾联系',
            }}
            onFinish={async (values) => {
                if (!remark) {
                    message.error('请修改备注');
                    return false;
                }
                const result = await updateCallInfoRemark({id: row.id, remark: remark});
                if (result.data) {
                    message.info('修改成功');
                    actionRef?.current.reload();
                    return true;
                }
                return false;
            }}
        >
            <>
                <TextArea name="remark" allowClear
                          autoSize={{minRows: 6, maxRows: 8}}
                          defaultValue={row.remark}
                          value={remark}
                          placeholder={"请输入备注"}
                          onChange={(e) => {
                              setRemark(e.target.value);
                          }}
                />

                {quickPhrases && quickPhrases.map(item =>
                    <Tag color="cyan" style={{whiteSpace: "normal", marginTop: 12}}
                         onClick={() => setRemark(item.content)}>{item.content}</Tag>
                )}
            </>
        </ModalForm>
    };


    const columns = [
        {
            hideInSearch: true,
            title: '备注',
            dataIndex: 'remark',
            render: (_, row, index, action) => [
                <>
                    <span>{_ + ' '}</span>
                    {updateRemarkModal(row)}
                </>
            ],
        },
        {
            title: '时间范围',
            key: 'dateRange',
            hideInTable: true,
            valueType: 'dateTimeRange',
            search: {
                transform: (value) => ({startDate: value[0], endDate: value[1]}),
            },
            initialValue: [moment().startOf('day'), moment().endOf('day')],
            colSize: 2,
            renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
                return <TimeFilter form={form} format={'YYYY/MM/DD'} defaultShortcut={'today'}/>
            }
        },
        {
            title: '来电号码',
            dataIndex: 'mobile',
            hideInSearch: true,
        },
        {
            title: '来源',
            dataIndex: 'type',
            hideInSearch: true,
            render: _ => <span>{_ === 2 ? '智齿' : 'app'}</span>,
        },
        {
            title: '来电次数',
            dataIndex: 'callTimes',
            hideInSearch: true,
            width: 80,
            render: (_, row, index, action) => {
                return _ && _ >= 3 ? {
                        children:
                            <span>{_} </span>,
                        props: {
                            style: {
                                background: 'var(--danger-color)',
                                color: 'white',
                            }
                        }
                    }
                    : <span>{_} </span>
            },
        },
        {
            title: '来电时间',
            dataIndex: 'timeTrace',
            hideInSearch: true,
        },
        {
            title: '是否用户',
            dataIndex: 'isUser',
            hideInSearch: true,
            render: _ => <span>{_ === 1 ? '是' : '否'}</span>,
        },
        {
            title: '是否风险用户',
            dataIndex: 'isRisk',
            hideInSearch: true,
            render: (_, row, index, action) => {
                return _ && _ === 1 ? {
                        children:
                            <span>是 </span>,
                        props: {
                            style: {
                                background: 'var(--danger-color)',
                                color: 'white',
                            }
                        }
                    }
                    : <span>否 </span>
            },
        },
        {
            title: 'IVR输入内容',
            dataIndex: 'content',
            hideInSearch: true,
        },
        {
            title: '已注册包',
            dataIndex: 'registeredPnames',
            hideInSearch: true,
            render: (_, row, index, action) => {
                let pnames = _?.split(',');
                return pnames && pnames.map(item => (ENUMS.PNAME_ENUM_MAP.get(item)) ?? item).join('，');
            }
        },
        {
            title: '已注册项目',
            dataIndex: 'registeredProjs',
            hideInSearch: true,
            render: (_, row, index, action) => {
                let projs = _?.split(',');
                return projs && projs.map(item => (ENUMS.PROJ_ENUM_MAP.get(item)) ?? item).join('，');
            }
        },
        {
            title: '是否退款成功',
            dataIndex: 'refunded',
            hideInSearch: true,
            render: _ => <span>{_ === 1 ? '是' : '否'}</span>,
        },
    ];

    return (
        <>
            <ProTable
                {...TABLE_PROPS.COMMON_PROPS}
                actionRef={actionRef}
                rowClassName={(record) => {
                    if (record.refunded) {
                        return 'successRow';
                    }
                }}
                pagination={{
                    pageSize: 200,
                }}
                columns={columns}
                rowKey="key"
                request={(params, sorter, filter) => {
                    return getCallInfo({...params, sorter, filter});
                }}
                onLoad={(dataSource) => {
                    dataSource.length === 0 && message.info('没有数据');
                }}
            />
            <BackTop/>
        </>
    );
}

export default CallInfo;
