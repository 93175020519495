import React, {useState, useRef} from 'react';
import ProCard from '@ant-design/pro-card';
import Campaign from "./Campaign";
import {CARD_STYLES} from "../../commonLayout";

const ViVoMarketingPanel = () => {


    return (
        <ProCard
            split="vertical"
            bordered
            headerBordered
            style={CARD_STYLES.COMMON_MIN_HEIGHT_STYLE}
        >
            <ProCard
                // style={{
                //     height: '100vh',
                //     overflow: 'auto',
                // }}
                tabs={{
                    type: 'card',
                }}
            >

                <ProCard.TabPane key="plan" tab="广告计划">
                    <Campaign/>
                </ProCard.TabPane>
                <ProCard.TabPane key="group" tab="广告组">
                    内容二
                </ProCard.TabPane>
                <ProCard.TabPane key="ad" tab="广告">
                    内容二
                </ProCard.TabPane>
                <ProCard.TabPane key="keyword" tab="关键词">
                    内容二
                </ProCard.TabPane>
            </ProCard>
        </ProCard>
    );

};


export default ViVoMarketingPanel;
