import React, {useState, useEffect} from 'react';
import ProForm, {
    ProFormRadio,
    ProFormText,
    ProFormDependency,
    ProFormList, ProFormSwitch
} from '@ant-design/pro-form';
import {Button, message} from "antd";
import request from "../../../../api/request";
import {API} from "../../../../api/mock";
import {getVersionExtInfo} from "../util"
import {FORM_LAYOUT} from "../../../commonLayout";

function PayWindowTextExt(prop) {
    const {pname, type, desc} = prop;
    const [readonly, setReadonly] = useState(true);
    const [data, setData] = useState(undefined);
    const [originData, setOriginData] = useState(undefined);

    let prepareData = (info) => {
        setData(info);
        // 拷贝一份供取消编辑恢复用
        let objString = JSON.stringify(info);
        setOriginData(Object.assign([], JSON.parse(objString)));
    };

    useEffect(async () => {
        const result = await getVersionExtInfo(pname, type);
        prepareData(result);

    }, []);

    async function saveExtConfig(values) {
        if (Object.keys(values).length) {
            let originDataMap = {};
            originData.map(item => {
                originDataMap[item.cname] = item;
            });

            for (let i in values) {
                let cnameObj = originDataMap[i];
                let cnameValue = values[i][0];

                cnameObj.checked = cnameValue.cnameRadio;
                cnameObj.title = cnameValue.title;
                cnameObj.text = cnameValue.text;
                cnameObj.text2 = cnameValue.text2;
            }

            const result = await request.post(API.update_version_ext_by_type, {
                pname: pname,
                type: type,
                typeInfos: originData
            }, 'json');
            if (result.data) {
                message.success("保存成功");
                setReadonly(true);
                let info = await getVersionExtInfo(pname, type);
                prepareData(info);
            }
        } else {
            setReadonly(true);
        }
    }

    return (
        <ProForm
            // className={'hidableExtLabelWidth'}
            key={Math.random()}
            {...FORM_LAYOUT.HORIZON_LEFT_SPAN_2_LAYOUT}
            onFinish={saveExtConfig}
            submitter={{
                // 完全自定义整个区域
                render: (props, doms) => {
                    if (readonly) {
                        return [
                            <Button type="primary"
                                    onClick={() => {
                                        setReadonly(false);
                                    }}>编辑</Button>
                        ];
                    } else {
                        return [
                            <Button key="cancel" onClick={() => {
                                // console.log('准备setReadonly');
                                setReadonly(true);
                                setData(Object.assign([], JSON.parse(JSON.stringify(originData))));
                                // props.form?.resetFields();
                            }}>取消</Button>,
                            <Button key="submit" type="primary" onClick={() => {
                                props.form?.submit?.()
                            }
                            }>保存</Button>
                        ];
                    }
                }
            }}
        >
            {/*<Title level={5}>{desc}</Title>*/}
            {
                data && data.map(item => {
                    let checked = item.checked;
                    let title = item.title;
                    let text = item.text;
                    let text2 = item.text2;

                    let cname = item.cname;

                    return <ProFormList
                        name={cname}
                        label={cname}
                        labelCol={{span: 3}}
                        creatorButtonProps={false}
                        copyIconProps={false}
                        deleteIconProps={false}
                        initialValue={[
                            {
                                cname: cname,
                                cnameRadio: checked,
                                title: title,
                                text: text,
                                text2: text2,
                            },
                        ]}
                    >
                        <ProForm.Group>
                            <ProFormSwitch
                                fieldProps={{
                                    defaultChecked: checked,
                                }}
                                disabled={readonly}
                                checked={checked}
                                name='cnameRadio'
                                key={cname}
                                onChange={(checked) => data.filter(item => item.cname === cname)[0].checked = checked}
                            />
                            <ProFormDependency layout={"horizon"} name={['cnameRadio']}>
                                {({cnameRadio}) => {
                                    if (!cnameRadio) {
                                        return null;
                                    }

                                    return <ProForm.Group>
                                        <ProFormText disabled={readonly} name="title" placeholder={'请输入文案'}
                                                     rules={[
                                                         {
                                                             required: true,
                                                             message: '文案必填!',
                                                         },
                                                     ]}/>
                                        <ProFormText disabled={readonly} name="text" placeholder={'字体大小'}
                                                     rules={[
                                                         {
                                                             pattern: /^\d+$/,
                                                             message: '字体必须为整数!',
                                                         },
                                                     ]}/>
                                        <ProFormText disabled={readonly} name="text2"
                                                     placeholder={'#号开头 例: #ffffff'}/>
                                    </ProForm.Group>
                                }}
                            </ProFormDependency>
                        </ProForm.Group>
                    </ProFormList>
                })
            }
        </ProForm>
    )
}

export default PayWindowTextExt;
