const MIN_SEC_RULE = [{
    required: true,
    // pattern: /^[0-2][0-9]\:[0-5][0-9]$/,
    pattern: /\d*/,
    message: '格式为 hh:mm 例:20:00',
}];
const REQUIRED_RULE = [{
    required: true,
}];
const MOBILE_RULE = [
    {
        pattern: /^1\d{10}$/,
        message: '不合法的手机号格式!',
    },
];

const NON_NEGATIVE_INTEGER_RULE = [
    {
        required: true,
        pattern: /^\d+$/,
        message: '必须为非负整数!',
    },
];

const DIGIT_WITHIN_2_DECIMAL_REQUIRED_RULE = [
    {
        required: true,
        pattern: /^[0-9]+(\.?[0-9]{1,2})?$/,
        message: '请输入正数且最多2位小数点!',
    },
];

const DIGIT_WITHIN_2_DECIMAL_RULE = [
    {
        pattern: /^[0-9]+(\.?[0-9]{1,2})?$/,
        message: '请输入正数且最多2位小数点!',
    },
];

export const RULES = {
    // 时分规则 hh:mm 例如20:00
    MIN_SEC_RULE: MIN_SEC_RULE,
    MOBILE_RULE: MOBILE_RULE,
    REQUIRED_RULE: REQUIRED_RULE,
    NON_NEGATIVE_INTEGER_RULE: NON_NEGATIVE_INTEGER_RULE,
    DIGIT_WITHIN_2_DECIMAL_RULE: DIGIT_WITHIN_2_DECIMAL_RULE,
    DIGIT_WITHIN_2_DECIMAL_REQUIRED_RULE: DIGIT_WITHIN_2_DECIMAL_REQUIRED_RULE,
};
