import moment from "moment";
import React, {useState} from "react";
import {DatePicker, Tag} from "antd";

const {RangePicker} = DatePicker;
const {CheckableTag} = Tag;

export function TimeFilter(props) {
    const {form, format, defaultShortcut} = props;
    const [shortcut, setShortcut] = useState(defaultShortcut);

    // 默认昨天
    let start = moment().subtract(1, 'd').startOf('day');
    let end = moment().subtract(1, 'd').endOf('day');
    if (defaultShortcut === 'today') {
        start = moment().startOf('day');
        end = moment().endOf('day');
    } else if (defaultShortcut === 'recent3days') {
        start = moment().subtract(2, 'd').startOf('day');
        end = moment().endOf('day');
    } else if (defaultShortcut === 'recent7days') {
        start = moment().subtract(6, 'd').startOf('day');
        end = moment().endOf('day');
    } else if (defaultShortcut === 'recent30days') {
        start = moment().subtract(29, 'd').startOf('day');
        end = moment().endOf('day');
    }
    const [startDate, setStartDate] = useState(start);
    const [endDate, setEndDate] = useState(end);

    const isMobile = window.localStorage.getItem('isMobile') === '1' ? true : false;
    let _format = format ?? 'YYYY/MM/DD';

    return (
        <>
            <RangePicker
                inputReadOnly={isMobile}
                allowClear={false}
                value={[startDate, endDate]}
                onChange={(value) => {
                    setShortcut('');
                    setStartDate(value[0]);
                    let endDate = moment(value[1]);
                    if (endDate.minutes() === 0 && endDate.hours() === 0) {
                        endDate = endDate.endOf('day');
                    }

                    setEndDate(endDate);
                    form.setFieldsValue({'dateRange': [value[0], endDate]});
                }}
                format={_format}
            />
            <CheckableTag style={{marginLeft: 20}} checked={shortcut === 'yesterday'}
                          onChange={() => {
                              setShortcut('yesterday');
                              setStartDate(moment().subtract(1, 'd').startOf('day'));
                              setEndDate(moment().subtract(1, 'd').endOf('day'));
                              form.setFieldsValue({'dateRange': [moment().subtract(1, 'd').startOf('day'), moment().subtract(1, 'd').endOf('day')]});
                              form?.submit();
                          }}>
                昨日
            </CheckableTag>
            <CheckableTag checked={shortcut === 'today'}
                          onChange={() => {
                              setShortcut('today');
                              setStartDate(moment().startOf('day'));
                              setEndDate(moment().endOf('day'));
                              form.setFieldsValue({'dateRange': [moment().startOf('day'), moment().endOf('day')]});
                              form?.submit();
                          }}>
                当日
            </CheckableTag>
            <CheckableTag checked={shortcut === 'recent3days'}
                          onChange={() => {
                              setShortcut('recent3days');
                              setStartDate(moment().subtract(2, 'd').startOf('day'));
                              setEndDate(moment().endOf('day'));
                              form.setFieldsValue({'dateRange': [moment().subtract(2, 'd').startOf('day'), moment().endOf('day')]});
                              form?.submit();
                          }}>
                最近3天
            </CheckableTag>
            <CheckableTag checked={shortcut === 'recent7days'}
                          onChange={() => {
                              setShortcut('recent7days');
                              setStartDate(moment().subtract(6, 'd').startOf('day'));
                              setEndDate(moment().endOf('day'));
                              form.setFieldsValue({'dateRange': [moment().subtract(6, 'd').startOf('day'), moment().endOf('day')]});
                              form?.submit();
                          }}>
                最近7天
            </CheckableTag>
            <CheckableTag checked={shortcut === 'recent30days'}
                          onChange={() => {
                              setShortcut('recent30days');
                              setStartDate(moment().subtract(29, 'd').startOf('day'));
                              setEndDate(moment().endOf('day'));
                              form.setFieldsValue({'dateRange': [moment().subtract(29, 'd').startOf('day'), moment().endOf('day')]});
                              form?.submit();
                          }}>
                最近30天
            </CheckableTag>
        </>
    )
}