import ProTable from "@ant-design/pro-table";
import React, {useState} from "react";
import {ENUMS} from "../../../../common/enums";
import {RULES as RULE} from "../../../../common/rules";
import {FORM_LAYOUT, TABLE_PROPS} from "../../../commonLayout";
import {getVivoCampaignList} from '@/api/mock';
import {ModalForm, ProFormTextArea} from "@ant-design/pro-form";

function Campaign() {
    const [config, setConfig] = useState(TABLE_PROPS.COMMON_PROPS);
    // 1.仅退款  2.退款取消会员  3.退款拉黑
    const columns = [
        {
            hideInSearch: true,
            hideInTable: true,
            dataIndex: 'id',
        },
        {
            hideInSearch: true,
            title: '计划名称',
            dataIndex: 'name',
        },
        {
            title: '开关',
            dataIndex: 'pauseState',
            hideInSearch: true,
            render: _ => _ === 1 ? '关闭' : '开启'

        },
        {
            hideInSearch: true,
            title: '计划类型',
            dataIndex: 'mediaTypeDesc',
        },
        {
            hideInSearch: true,
            title: '限额',
            dataIndex: 'dailyBudgetYuan',
            render: _ => -1 === _ ? '不限' : '￥' + _ + ' /天'
        },
        {
            title: '操作',
            key: 'option',
            valueType: 'option',
            render: (text, record, _, action) => [
                <ModalForm
                    {...FORM_LAYOUT.MODAL_FORM_UN_CLOSABLE_LAYOUT}
                    trigger={
                        <a>限额时段</a>
                    }
                    onFinish={async (values) => {
                        const {remark} = values;
                        // const result = await updatePayLogRemark({id: row.id, remark: remark});
                        // if (result.data) {
                        //     message.info('修改成功');
                            // ref.current.reload();
                            // return true;
                        // }
                        return false;
                    }}
                >
                    <>
                        {/*<ProFormTextArea label="备注信息" name="remark" allowClear rows={6}*/}
                        {/*                 fieldProps={{*/}
                        {/*                     defaultValue: row.remark,*/}
                        {/*                     value: remarkValue,*/}
                        {/*                     onChange: (e) => {*/}
                        {/*                         setRemarkValue(e.target.value);*/}
                        {/*                     }*/}
                        {/*                 }}/>*/}
                    </>
                </ModalForm>


            ],


        },
    ];


    return <ProTable
        {...config}
        search={false}
        toolBarRender={() => {
        }}
        columns={columns}
        rowKey="id"
        request={(params, sorter, filter) => {
            return getVivoCampaignList();
        }}
    />
}

export default Campaign;