import {getVersionExtInfoByType} from "@/api/mock";
import {message} from "antd";

export let getVersionExtInfo = async (pname, type) => {
    const result = await getVersionExtInfoByType({
        type: type,
        pname: pname
    });

    if (!result.data) {
        message.error('获取信息失败');
        return [];

    }
    return result.data;
};

export let replaceChar = (text, start, newChar) => {
    return text.substring(0, start) + newChar + text.substring(start + 1);
};


// export default getVersionExtInfo();
