import React, {useRef, useEffect, useState} from 'react';
import {Button, Input, message, Modal, Select, Tree} from 'antd';
import {getAdminList, updateAdmin, getRoleList, addAdmin, getAvailablePnameCnames} from '@/api/mock'
import {EditableProTable} from "@ant-design/pro-table";
import {ModalForm, ProFormText, ProFormSelect, ProFormDependency} from "@ant-design/pro-form";
import {ENUMS} from "../../../common/enums";
import {RULES as RULE} from "../../../common/rules";
import {timeFormatter} from "../../../utils";

const layout = {
    layout: "horizon",
    labelCol: {span: 6},
    wrapperCol: {span: 16},
};

function AdminList() {
    const ref = useRef(undefined);
    const modalFormRef = useRef(undefined);
    const [roles, setRoles] = useState([]);
    const [roleMap, setRoleMap] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [adminList, setAdminList] = useState([]);
    const [reload, setReload] = useState(0);
    const [editingAvailableProjs, setEditingAvailableProjs] = useState(undefined);
    const [editRowId, setEditRowId] = useState(undefined);
    const [allProjs, setAllProjs] = useState(undefined);
    const [selectedProjPnames, setSelectedProjPnames] = useState(undefined);


    useEffect(async () => {
        const adminResult = await getAdminList();
        if (adminResult.data) {
            // 服务端返回的是字符串逗号分隔,ProTable里目前还没发现能对column值进行预处理的方法,这一步先放这里做了,其实也可以直接让服务端返回数组
            adminResult.data.map(i => i.availableProjs = i.availableProjs?.split(','));
            setAdminList(adminResult.data);
        }
    }, [reload]);

    useEffect(async () => {
        const localProjs = JSON.parse(window.localStorage.getItem('projs'));
        setAllProjs(localProjs);

        const roleResult = await getRoleList();
        if (roleResult.data) {
            let roleList = [];
            let map = new Map();
            roleResult.data.roleList.forEach(d => {
                roleList.push({value: d.id, label: d.name});
                map.set(d.id, d.name);
            });
            setRoleMap(map);
            setRoles(roleList);
        }
    }, []);

    const columns = [
        {
            title: '账号',
            dataIndex: 'username',
            hideInSearch: true,
            editable: false,
            width: 120
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            width: 150
        },
        {
            title: '角色',
            dataIndex: 'roleId',
            valueType: 'select',
            valueEnum: roleMap,
            width: 150,
            render: (_, record) => <span>{record.isSystem === 1 ? '超级管理员' : _}</span>,
        },
        {
            title: '项目',
            dataIndex: 'availableProjs',
            // width: 550,

            renderFormItem: (_, {isEditable, record}, form) => {
                let item = adminList.filter((item) => item.id === editRowId)?.[0];
                return <Select
                    // defaultValue={item?.availableProjs}
                    mode="tags"
                    options={allProjs}
                    onChange={(e) => {
                        item.availableProjs = e;
                        setEditingAvailableProjs(e);
                    }}
                />
            },
            render: (text, record, _, action) =>
                record.isSystem !== 1 ?
                    <Select
                        disabled
                        mode="tags"
                        defaultValue={record.availableProjs}
                        style={{width: '100%'}}
                        placeholder="暂无内容"
                        options={allProjs}
                    />
                    : <span>全部</span>,
        },
        {
            title: '可用包',
            dataIndex: 'availablePnames',
            editable: false,
            renderText: (text, record) => {
                return text ?? '全部';
            }
        },
        {
            title: '可用渠道',
            dataIndex: 'availableCnames',
            editable: false,
            renderText: (text, record) => {
                return text ?? '全部';
            }
        },
        {
            title: '创建时间',
            dataIndex: 'created',
            hideInSearch: true,
            editable: false,
            renderText: timeFormatter,
            width: 200
        },
        {
            title: '是否启用',
            dataIndex: 'enabledStatus',
            valueType: 'select',
            width: 100,
            valueEnum: ENUMS.YES_OR_NO_ENUM,
        },
        {
            title: '操作',
            key: 'option',
            valueType: 'option',
            render: (text, record, _, action) => [
                record.isSystem ?
                    <></> :
                    <a key="editable" onClick={() => {
                        setEditRowId(record.id);
                        action.startEditable?.(record.id);
                    }}>编辑</a>
            ],
        },
    ];

    return (
        <>
            <EditableProTable
                actionRef={ref}
                search={false}
                columns={columns}
                rowKey="id"
                cardBordered={true}
                // 工具栏拓展
                toolBarRender={() => [
                    <ModalForm
                        {...layout}
                        formRef={modalFormRef}
                        title={"添加用户"}
                        width={400}
                        visible={modalVisible}
                        // 触发点
                        trigger={
                            <Button type="primary" key="addRootMenu"
                                    onClick={() => {
                                        setModalVisible(true);
                                    }}>添加用户</Button>
                        }
                        onFinish={async (values) => {
                            const result = await addAdmin(values);
                            if (result.data) {
                                message.success('保存成功');
                                setModalVisible(false);
                                setReload(reload + 1);
                            } else {
                                return false;
                            }
                        }}
                        modalProps={{
                            onCancel: () => {
                                setModalVisible(false);
                                return false;
                            },
                            maskClosable: false,
                            closable: false,
                            destroyOnClose: true,
                            bodyStyle: {
                                paddingTop: 40
                            },
                            centered: true,
                            okText: '添加',
                        }}
                    >
                        <>
                            <ProFormText name="username" label="用户名" placeholder=""
                                         rules={[{required: true, message: '用户名不能为空'}]}
                            />
                            <ProFormText.Password name="password" label="密码" placeholder="" rules={[
                                {required: true, message: '请输入密码!'},
                            ]}/>

                            <ProFormText name="mobile" label="手机号" placeholder="非必填" rules={RULE.MOBILE_RULE}/>
                            <ProFormSelect
                                name="roleId"
                                label="角色"
                                options={roles}
                                rules={RULE.REQUIRED_RULE}
                            />
                            <ProFormSelect
                                name="availableProjs"
                                label="项目"
                                mode="tags"
                                placeholder="选择项目"
                                options={allProjs}
                                rules={RULE.REQUIRED_RULE}
                            />

                            <ProFormDependency name={['availableProjs']}>
                                {({availableProjs}) => {
                                    if (availableProjs && availableProjs.length === 1) {
                                        return <>
                                            <ProFormSelect
                                                name="availableCnames"
                                                label="渠道"
                                                placeholder="可用渠道，留空则全部可用"
                                                request={async () => {
                                                    modalFormRef?.current?.setFieldsValue({'availableCnames': ''});
                                                    modalFormRef?.current?.setFieldsValue({'availablePnames': ''});

                                                    const result = await getAvailablePnameCnames({proj: availableProjs[0]});
                                                    let cnameArr = [];
                                                    result?.data?.cnames && result.data.cnames.every(item => cnameArr.push({
                                                        label: item, value: item
                                                    }));
                                                    setSelectedProjPnames(result.data.pnameMap);
                                                    return cnameArr;
                                                }}
                                            />
                                            <ProFormSelect
                                                name="availablePnames"
                                                label="包名"
                                                // mode="tags"
                                                placeholder="可用包名，留空则全部可用"
                                                valueEnum={selectedProjPnames}
                                            />
                                        </>
                                    }
                                }}
                            </ProFormDependency>

                        </>
                    </ModalForm>
                ]}
                value={adminList}
                // 保存行编辑
                editable={{
                    type: 'single',
                    onSave: async (key, row) => {
                        const {id, mobile, enabledStatus, roleId, availableProjs} = row;
                        const result = await updateAdmin({
                            id: id,
                            mobile: mobile,
                            enabledStatus: enabledStatus,
                            roleId: roleId,
                            availableProjs: availableProjs?.join(','),
                        });
                        if (result.data) {
                            message.info("操作成功");
                            setReload(reload + 1);
                        }
                    },
                    actionRender: (row, config, dom) => [dom.save, dom.cancel],
                }}
                recordCreatorProps={false}
            />
        </>
    );
}

export default AdminList;
