import React, {useState} from 'react';
import ProTable from '@ant-design/pro-table';
import ProCard from '@ant-design/pro-card';
import {getBalanceInfo} from '@/api/mock';
import {CARD_STYLES, TABLE_PROPS} from "../commonLayout";


const columns = [
    {
        title: '平台',
        key: 'dateRange',
        dataIndex: 'platform',
        hideInSearch: true,
        width: 200,
        render: (_, record) =>
            <a href={record.link} target="_blank" rel="noopener noreferrer">
                {_}
            </a>
    },

    {
        title: '账户',
        dataIndex: 'account',
        hideInSearch: true,
        width: 200,
    },
    {
        title: '渠道',
        dataIndex: 'cname',
        hideInSearch: true,
        width: 200,
    },
    {
        title: '余额情况',
        dataIndex: 'content',
        ellipsis: true,
        hideInSearch: true,
    },
];


const PlatformBalance = () => {
    const [config, setConfig] = useState(TABLE_PROPS.COMMON_PROPS);
    return (
        <ProCard
            split="vertical"
            bordered
            headerBordered
        >
            <ProCard
                style={CARD_STYLES.COMMON_MIN_HEIGHT_STYLE}
            >
                <ProTable
                    {...config}
                    bordered={true}
                    search={false}
                    footer={() => '第三方平台的余额查询'}
                    columns={columns}
                    rowKey="key"
                    request={
                        (params, sorter, filter) => {
                            return getBalanceInfo({...params, sorter, filter});
                        }
                    }
                    pagination={false}
                />
            </ProCard>
        </ProCard>
    );
};

export default PlatformBalance;
