import React, {useState, useEffect} from 'react';
import ProTable from '@ant-design/pro-table';
import ProCard from '@ant-design/pro-card';
import {message, Tag} from 'antd';
import {getTradeInfo, querySalesAmountStat} from '@/api/mock';
import {DataView} from '@antv/data-set'
import moment from 'moment';
import './index.less';
import {DualAxes, measureTextWidth} from '@antv/g2plot';
import {TimeFilter} from "../../../components/TimeFilter";
import {CARD_STYLES, TABLE_PROPS} from "../../commonLayout";
import {dateFormatter} from "../../../utils";
import {Pie} from '@ant-design/charts';

const {CheckableTag} = Tag;

// valueType:'',//值的显示类型
// | money | 转化值为金额 | ¥10,000.26 |
// | date | 日期 | 2019-11-16 |
// | dateRange | 日期区间 | 2019-11-16 2019-11-18 |
// | dateTime | 日期和时间 | 2019-11-16 12:50:00 |
// | dateTimeRange | 日期和时间区间 | 2019-11-16 12:50:00 2019-11-18 12:50:00 |
// | time | 时间 | 12:50:00 |
// | option | 操作项，会自动增加 marginRight，只支持一个数组,表单中会自动忽略 | `[<a>操作a</a>,<a>操作b</a>]` |
// | text | 默认值，不做任何处理 | - |
// | select | 选择 | - |
// | textarea | 与 text 相同， form 转化时会转为 textarea 组件 | - |
// | index | 序号列 | - |
// | indexBorder | 带 border 的序号列 | - |
// | progress | 进度条 | - |
// | digit | [格式化](https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat)数字展示，form 转化时会转为 inputNumber | - |
// | percent | 百分比 | +1.12 |
// | code | 代码块 | `const a = b` |
// | avatar | 头像 | 展示一个头像 |
// | password | 密码框 | 密码相关的展示 |


const TradeInfo = () => {
    const [chart, setChart] = useState(undefined);
    const [selectedPname, setSelectedPname] = useState('');
    const [selectedCname, setSelectedCname] = useState('');

    const [columnsStateMap, setColumnsStateMap] = useState({
        productPayment: {
            show: false,
            fixed: 'right',
        }
    });

    useEffect(() => {
        let proj = window.localStorage.getItem('selectedProj');
        if (!proj) {
            message.error('请选择项目', 3);
        }
    }, []);

    const pnameMap = JSON.parse(window.localStorage.getItem('pnameMap'));
    const cnames = JSON.parse(window.localStorage.getItem('cnames'));

    // 图表渲染
    let renderChart = async (d, params) => {
        if (d.length === 1) {
            chart?.chart.changeVisible(false);
            return;
        }

        // 防止影响源数据
        const tmp = Object.assign([], d);
        const dv = new DataView().source(tmp.reverse());

        dv.transform({
            type: 'map',
            callback(row) {
                row.date = row.date.split(' ')[0];
                return row;
            },
        });

        dv.transform({
            type: 'rename',
            map: {
                activateNum: '激活人数',
                registerNum: '注册人数',
                payNum: '付款笔数',
                refundNum: '退款笔数',
                activatePaymentPrice: 'ARPU(激活)',
                registerPaymentPrice: 'ARPU(注册)',
            },
        });

        dv.transform({
            type: 'fold',
            fields: ['激活人数', '注册人数', '付款笔数', '退款笔数', 'ARPU(激活)', 'ARPU(注册)'], // 展开字段集
            key: 'series',
            value: 'value',
        });

        const data = dv.rows;

        const {startDate, endDate} = params;

        let result = await querySalesAmountStat({...params});
        // 记录结果集的日期,用于补足空缺日期的数据
        let resultDate = [];
        result.data.forEach(item => {
            item.date = item.date.split(' ')[0];
            if (resultDate.indexOf(item.date) == -1) {
                resultDate.push(item.date);
            }
        });

        let start = moment(startDate);
        let end = moment(endDate);
        while (!start.isAfter(end)) {
            let dateStr = start.format('yyyy-MM-DD');
            // 不存在当天的,设置一个默认数据
            if (resultDate.indexOf(dateStr) == -1) {
                result.data.push({'date': dateStr, 'saleAmount': 0, 'cname': '全渠道'});
            }
            start = start.add(1, 'day');
        }

        result.data.sort(function (o1, o2) {
            let d1 = o1.date;
            let d2 = o2.date;
            if (d1 < d2) {
                return -1;
            } else if (d1 > d2) {
                return 1;
            } else {
                return 0;
            }
        });

        const config = {
            data: [data, result.data],
            xField: 'date',
            yField: ['value', 'saleAmount'],
            meta: {
                type: 'time',
            },
            geometryOptions: [
                {
                    geometry: 'line',
                    seriesField: 'series',
                    smooth: true,
                    xAxis: {
                        label: {
                            formatter: (v) => {
                                return v.split(' ')[0];
                            }
                        },
                    },
                    animation: {
                        appear: {
                            animation: 'path-in',
                            duration: 3000,
                        },
                    },
                },
                {
                    geometry: 'column',
                    seriesField: 'cname',
                    isStack: true,
                    columnWidthRatio: 0.4,
                },
            ],
        };

        if (!chart) {
            let c = new DualAxes('container', config);
            setChart(c);
            c.render();
        } else {
            chart.update(config);
        }
    };

    const columns = [
        {
            title: '包名',
            dataIndex: 'selectedPname',
            hideInTable: true,
            colSize: 3,
            renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
                let tabs = [];
                pnameMap && Object.keys(pnameMap).forEach(i => (
                    tabs.push(<CheckableTag
                        key={i}
                        checked={selectedPname === i}
                        onChange={() => {
                            setSelectedPname(i);
                            form.setFieldsValue({'selectedPname': i});
                            form?.submit();
                        }}
                    >
                        {pnameMap[i]}
                    </CheckableTag>)
                ));
                return (
                    <>
                        <CheckableTag
                            checked={selectedPname === ''}
                            onChange={() => {
                                setSelectedPname('');
                                form.setFieldsValue({'selectedPname': ''});
                                form?.submit();
                            }
                            }>全部</CheckableTag>
                        {tabs}
                    </>
                )
            }
        },
        {
            title: '渠道',
            key: 'selectedCname',
            hideInTable: true,
            colSize: 3,
            renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
                return (
                    <>
                        <CheckableTag style={{width: 70, textAlign: 'center'}} checked={selectedCname === ''}
                                      onChange={() => {
                                          setSelectedCname('');
                                          form.setFieldsValue({'selectedCname': ''});
                                          form?.submit();
                                      }}>
                            全部
                        </CheckableTag>
                        {cnames && cnames.map(i => (
                            <CheckableTag style={{width: 70, textAlign: 'center'}}
                                          key={`${i}`}
                                          checked={selectedCname === i}
                                          onChange={() => {
                                              setSelectedCname(i);
                                              form.setFieldsValue({'selectedCname': i});
                                              form?.submit();
                                          }}>
                                {i}
                            </CheckableTag>
                        ))}
                    </>);
            }
        },
        {
            title: '时间范围',
            key: 'dateRange',
            hideInTable: true,
            valueType: 'dateTimeRange',
            colSize: 2,
            search: {
                transform: (value) => ({startDate: value[0], endDate: value[1]}),
            },
            initialValue: [moment().startOf('day'), moment().endOf('day')],
            renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
                return <TimeFilter form={form} format={'YYYY/MM/DD HH:mm'} defaultShortcut={'today'}/>
            }
        },
        {
            title: '时间',
            dataIndex: 'date',
            hideInSearch: true,
            renderText: dateFormatter,
            fixed: 'left',
            width: 80,
        },
        {
            title: '激活人数',
            dataIndex: 'activateNum',
            hideInSearch: true,
        },
        {
            title: '注册人数',
            dataIndex: 'registerNum',
            hideInSearch: true,
        },
        {
            title: '激活注册率',
            dataIndex: 'activateRegisterRate',
            hideInSearch: true,
        },
        {
            title: '付费总金额',
            hideInSearch: true,
            dataIndex: 'payment',
        },
        {
            title: '付费笔数',
            dataIndex: 'payNum',
            hideInSearch: true,
        },
        {
            hideInSearch: true,
            title: 'ARPU(激活)',
            dataIndex: 'activatePaymentPrice',
        },
        {
            hideInSearch: true,
            title: 'ARPU(注册)',
            dataIndex: 'registerPaymentPrice',
        },
        {
            tooltip: '基本可以理解为当天注册试用率',
            hideInSearch: true,
            title: '试用率',
            dataIndex: 'activateTriedRate',
        },
        {
            hideInSearch: true,
            title: '激活付费率',
            dataIndex: 'activatePayRate',
        },
        {
            hideInSearch: true,
            title: '注册付费率',
            dataIndex: 'registerPayRate',
        },
        {
            hideInSearch: true,
            title: '套餐占比',
            dataIndex: 'productPayment',
            render: (_, row, index, action) => {
                let items = row.productStatDTOs;
                if (!items) {
                    return row.payment;
                }
                items.every(item => item.priceName = item.days === 0 ? '终身' : item.days + '天');

                let config = {
                    data: items,
                    meta: {
                        days: {
                            alias: '套餐',
                            formatter: function formatter(v) {
                                return v === 0 ? '终身' : v + '天';
                            },
                        },
                    },
                    angleField: 'totalPayment',
                    colorField: 'priceName',
                    width: 160,
                    height: 300,
                    radius: 1,
                    innerRadius: 0.6,
                    legend: {
                        layout: 'vertical',
                        position: 'top',
                        itemName: {
                            formatter: function formatter(text, item, index) {
                                let a = items.filter(item => item?.priceName === text);
                                if (!a) return text;
                                return text + ' （￥' + a[0].totalPayment + ' ）';
                            },
                        },
                    },
                    label: {
                        type: 'inner',
                        offset: '-50%',
                        style: {textAlign: 'center', fontSize: 10},
                        autoRotate: false,
                        content: function content(_ref) {
                            return ''.concat((_ref.totalPayment / row.payment * 100).toFixed(1), '%');
                        },

                    },
                    statistic: {
                        title: {
                            customHtml: function customHtml(container, view, datum) {
                                let _container$getBoundin = container.getBoundingClientRect(),
                                    width = _container$getBoundin.width,
                                    height = _container$getBoundin.height;
                                let d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
                                let text = datum ? datum.priceName : '总计';
                                return renderStatistic(d, text, {fontSize: 18});
                            },
                        },
                        content: {
                            offsetY: 4,
                            style: {fontSize: '16px'},
                            customHtml: function customHtml(container, view, datum, data) {
                                let _container$getBoundin2 = container.getBoundingClientRect(),
                                    width = _container$getBoundin2.width;
                                let text = datum
                                    ? '\xA5 '.concat(datum.totalPayment)
                                    : '\xA5 '.concat(
                                        data.reduce(function (r, d) {
                                            return r + d.totalPayment;
                                        }, 0),
                                    );
                                return renderStatistic(width, text, {fontSize: 16});
                            },
                        },
                    },
                    interactions: [
                        {type: 'element-active'},
                        {type: 'pie-statistic-active'},
                    ],
                };
                return <Pie {...config} />;
            },
        },
        {
            hideInSearch: true,
            title: '退款笔数',
            dataIndex: 'refundNum',
        },
        {
            hideInSearch: true,
            title: '退款金额',
            dataIndex: 'refundFee',
        },
        {
            hideInSearch: true,
            title: '退款率',
            dataIndex: 'payRefundRate',
        },
        {
            title: 'auto非首次金额',
            hideInSearch: true,
            dataIndex: 'aliSubPayment',
        },
        {
            title: 'auto首次笔数',
            hideInSearch: true,
            dataIndex: 'aliAgreementNum',
        },
    ];

    return (
        <>
            <ProCard
                split="vertical"
            >
                <ProCard
                    style={CARD_STYLES.COMMON_MIN_HEIGHT_STYLE}
                >
                    <ProTable
                        {...TABLE_PROPS.COMMON_PROPS}
                        columns={columns}
                        rowKey="key"
                        scroll={{x: 'max-content'}}
                        request={async (params, sorter, filter) => {
                            const result = await getTradeInfo({...params, sorter, filter});
                            await renderChart(result.data, params);
                            return result;
                        }}
                        columnsState={{
                            value: columnsStateMap,
                            onChange: setColumnsStateMap,
                        }}
                    />
                </ProCard>
            </ProCard>
            <ProCard style={{marginTop: 16}}>
                <div id="container"></div>
            </ProCard>
        </>
    );
};

function renderStatistic(containerWidth, text, style) {
    var _measureTextWidth = (0, measureTextWidth)(text, style),
        textWidth = _measureTextWidth.width,
        textHeight = _measureTextWidth.height;
    var R = containerWidth / 2;
    var scale = 1;
    if (containerWidth < textWidth) {
        scale = Math.min(
            Math.sqrt(
                Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))),
            ),
            1,
        );
    }
    var textStyleStr = 'width:'.concat(containerWidth, 'px;');
    return '<div style="'
        .concat(textStyleStr, ';font-size:')
        .concat(scale, 'em;line-height:')
        .concat(scale < 1 ? 1 : 'inherit', ';">')
        .concat(text, '</div>');
}


export default TradeInfo;