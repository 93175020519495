import React, {useEffect, useState} from 'react';
import {Button, message, BackTop, DatePicker, Tag} from 'antd';
import {getRiskUserList} from '@/api/mock'
import moment from "moment";
import ProTable from "@ant-design/pro-table";
import {TimeFilter} from "../../components/TimeFilter";
import {TABLE_PROPS} from "../commonLayout";


function RiskUser() {
    const [config, setConfig] = useState(TABLE_PROPS.COMMON_PROPS);

    useEffect(() => {
        let proj = window.localStorage.getItem('selectedProj');
        if (!proj) {
            message.error('请选择项目', 3)
        }

    }, []);

    const columns = [
        {
            title: '时间范围',
            key: 'dateRange',
            hideInTable: true,
            valueType: 'dateTimeRange',
            search: {
                transform: (value) => ({startDate: value[0], endDate: value[1]}),
            },
            initialValue: [moment().subtract(2, 'd').startOf('day'), moment().endOf('day')],
            colSize: 2,
            renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
                return <TimeFilter form={form} defaultShortcut={'recent3days'}/>
            }
        },
        {
            title: '用户名',
            dataIndex: 'username',
            hideInSearch: true,
        },
        {
            title: '设备号',
            dataIndex: 'uid',
            hideInSearch: true,
        },
        {
            title: '付款笔数',
            dataIndex: 'payNum',
            hideInSearch: true,
        },
        {
            title: '更新时间',
            dataIndex: 'modified',
            hideInSearch: true,
        },
    ];

    return (
        <>
            <ProTable
                {...config}
                columns={columns}
                rowKey="key"
                request={(params, sorter, filter) => {
                    return getRiskUserList({...params, sorter, filter});
                }}
                onLoad={(dataSource) => {
                    dataSource.length === 0 && message.info('没有数据');
                }}
            />
            <BackTop/>
        </>
    );
}

export default RiskUser;
