import React, {useState, useEffect} from 'react';
import {
    QuestionCircleOutlined,
} from '@ant-design/icons';
import {getMenuList, saveMenu, deleteMenu} from '@/api/mock'
import {EditableProTable} from "@ant-design/pro-table";
import {Button, message, Popconfirm} from 'antd';
import {ModalForm, ProFormText} from "@ant-design/pro-form";
import {FORM_LAYOUT} from "../../commonLayout";

function MenuList() {
    const [reload, setReload] = useState(0);
    const [menus, setMenus] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [curMenu, setCurMenu] = useState(undefined);

    useEffect(async () => {
        let rsp = await getMenuList();
        setMenus(rsp.data);
    }, [reload]);

    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            hideInSearch: true,
        },
        {
            title: '标识',
            dataIndex: 'code',
            hideInSearch: true,
        },
        {
            title: '访问路径',
            dataIndex: 'path',
            hideInSearch: true,
        },
        {
            title: '创建时间',
            dataIndex: 'created',
            hideInSearch: true,
            editable: (text, record, index) => {
                return index !== 0;
            },
        },
        {
            title: '操作',
            key: 'option',
            width: 240,
            valueType: 'option',
            render: (text, record, _, action) => [
                <a key="2" onClick={() => {
                    setModalVisible(true);
                    setCurMenu(record);
                }}>添加子菜单</a>,

                <a key="editable" onClick={() => {
                    action.startEditable?.(record.id);
                }}>编辑</a>,

                <Popconfirm arrowPointAtCenter title="确认删除此菜单?" icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                            placement="topLeft" okText="确认" okType="danger" cancelText="再想想"
                            onConfirm={async () => {
                                const result = await deleteMenu({id: record.id});
                                if (result.data) {
                                    message.info("操作成功");
                                    setReload(Math.random());
                                }
                            }}>
                    <a key="3">删除</a>
                </Popconfirm>
            ],
        },
    ];

    return (
        <>
            {menus?.length ? <EditableProTable
                search={false}
                columns={columns}
                expandable={{defaultExpandAllRows: true}}
                rowKey="id"
                cardBordered={true}
                toolBarRender={() => [
                    <ModalForm
                        {...FORM_LAYOUT.MODAL_FORM_UN_CLOSABLE_LAYOUT}
                        title={"添加菜单"}
                        width={400}
                        visible={modalVisible}
                        trigger={
                            <Button type="primary" key="addRootMenu"
                                    onClick={() => {
                                        setCurMenu(undefined);
                                        setModalVisible(true);
                                    }}>添加主菜单</Button>
                        }
                        onFinish={async (values) => {
                            values.parentId = curMenu?.id ?? 0;
                            const result = await saveMenu(values);
                            if (result.data) {
                                message.success('保存成功');
                                setModalVisible(false);
                                setReload(Math.random());
                            } else {
                                message.error('保存失败');
                                return false;
                            }
                        }}
                        modalProps={{
                            onCancel: () => {
                                setModalVisible(false);
                                return false;
                            },
                            maskClosable: false,
                            closable: false,
                            destroyOnClose: true,
                            bodyStyle: {
                                paddingTop: 40
                            },
                            centered: true,
                            okText: '添加',
                        }}
                    >
                        <>
                            <ProFormText name="name" label="菜单名称" placeholder=""/>
                            <ProFormText name="code" label="权限标识" placeholder=""/>
                            <ProFormText name="path" label="访问路径" placeholder=""/>
                        </>
                    </ModalForm>
                ]}
                value={menus}
                onChange={setMenus}
                editable={{
                    type: 'multiple',
                    curMenu,
                    onSave: async (key, row) => {
                        const {id, name, path, code} = row;
                        const result = await saveMenu({id: id, name: name, path: path, code: code});
                        if (result.data) {
                            message.info("操作成功");
                        }
                        setReload(Math.random());
                    },
                    onChange: setCurMenu,
                }}
                recordCreatorProps={false}
            /> : "菜单暂未配置"
            }
        </>
    );
}

export default MenuList;
