import React, {useEffect, useState} from 'react';
import ProForm, {
    ProFormText,
    ProFormCheckbox,
    ProFormRadio
} from '@ant-design/pro-form';
import ProCard from "@ant-design/pro-card";
import {Button, Tabs, message} from 'antd';
import {getVersionInfo, updateVersionInfo} from '@/api/mock';
import {FORM_LAYOUT} from '../../commonLayout'

const {TabPane} = Tabs;


function VersionConfig() {
    const [versionInfos, setVersionInfos] = useState([]);
    const [readonly, setReadonly] = useState(true);

    async function saveConfig(values) {
        const result = await updateVersionInfo(values);
        if (result.data) {
            message.success("保存成功");
            setReadonly(true);
        }
    }

    useEffect(async () => {
        const result = await getVersionInfo();
        setVersionInfos(result.data);
    }, []);

    return (
        <>
            <ProCard gutter={8} title={' '} subTitle={'以下部分属性配置在部分项目里会不生效'}>
                <Tabs type="card" size="large" tabBarGutter={8}>
                    {
                        versionInfos && versionInfos.map(i => (
                            <TabPane
                                tab={i.productName ?? i.packageName} key={i.packageName}>
                                <ProForm
                                    style={{paddingTop: 40}}
                                    name={i.packageName}
                                    {...FORM_LAYOUT.HORIZON_LEFT_SPAN_2_LAYOUT}
                                    onFinish={saveConfig}
                                    submitter={{
                                        // 完全自定义整个区域
                                        render: (props, doms) => {
                                            if (readonly) {
                                                return [
                                                    <Button type="primary"
                                                            onClick={() => setReadonly(false)}>编辑</Button>
                                                ];
                                            } else {
                                                return [
                                                    <Button key="cancel" onClick={() => {
                                                        setReadonly(true);
                                                        props.form?.resetFields();
                                                    }}>取消</Button>,
                                                    <Button key="submit" type="primary" onClick={() => {
                                                        props.form?.submit?.()
                                                    }
                                                    }>保存</Button>
                                                ];
                                            }

                                        },
                                    }}

                                    initialValues={{
                                        id: i.id,
                                        packageName: i.packageName,
                                        contactQQ: i.contactQQ,
                                        contactEmail: i.contactEmail,
                                        contactWx: i.contactWx,
                                        testMobiles: i.testMobiles,
                                        functionDesc: i.functionDesc,
                                        suspension: i.suspension,
                                        commentUrl: i.commentUrl,
                                        paySwitch: i.paySwitch && [i.paySwitch.charAt(0) === '1' ? 'alipay' : '', i.paySwitch.charAt(1) === '1' ? 'wx' : ''],
                                        commentSwitch: i.commentSwitch ?? '0',
                                    }}
                                >

                                    <ProFormText hidden name="id"/>
                                    <ProFormText hidden name="packageName"/>
                                    <ProFormText width="lg" readonly={readonly} name="contactQQ" label="联系方式"
                                                 placeholder="请输入联系方式"/>
                                    <ProFormText width="lg" readonly={readonly} name="contactEmail" label="联系邮箱"
                                                 placeholder="请输入联系邮箱"/>
                                    <ProFormText width="lg" readonly={readonly} name="contactWx" label="联系微信"
                                                 tooltip='部分项目有,轨迹安卓没有、iOS有，可以通知iOS客户端去除'
                                                 placeholder="请输入联系微信"/>
                                    <ProFormText readonly={readonly} name="testMobiles" label="测试手机号"
                                                 tooltip='基本都会免验证码登录，有的项目注册会自动变成会员'
                                                 placeholder="请输入测试手机号"/>
                                    <ProFormText width="lg" readonly={readonly} name="functionDesc" label="功能描述"
                                                 tooltip='历史原因，有些项目很重要，有些用不到'
                                                 placeholder="请输入功能描述"/>
                                    <ProFormText width="sm" readonly={readonly} name="suspension" label="客服悬浮窗"
                                                 tooltip='联系客服悬浮窗,1表示打开,0表示关闭，顺序从左到右对应app底部tab'
                                                 placeholder="请输入客服悬浮窗"/>
                                    <ProFormRadio.Group width="lg" disabled={readonly} name="commentSwitch"
                                                        label="iOS评论开关"
                                                        options={[
                                                            {
                                                                label: '关闭',
                                                                value: 0,
                                                            },
                                                            {
                                                                label: '开启',
                                                                value: 1,
                                                            },
                                                            {
                                                                label: '9点~18点 开启',
                                                                value: 2,
                                                            },
                                                        ]}/>
                                    <ProFormText width="lg" readonly={readonly} name="commentUrl" label="iOS评论链接"
                                                 placeholder="请输入评论链接"/>
                                    <ProFormCheckbox.Group
                                        disabled={true}
                                        name="paySwitch"
                                        label="支付方式开关"
                                        tooltip='这里不能改,到【支付配置】去改'
                                        options={[{label: '支付宝', value: 'alipay'}, {label: '微信', value: 'wx'}]}
                                    />
                                </ProForm>
                            </TabPane>
                        ))}
                </Tabs>
            </ProCard>
        </>
    );
}

export default VersionConfig;
