import request from "../request";

import './data';

export const API_PREFIX = "/api";
export const API = {
    'save_task_config': API_PREFIX + '/saveTaskConfig',
    'update_version_ext_by_type': API_PREFIX + '/updateVersionExtByType',
};

export const login = (params) => request.get(API_PREFIX + '/security/login', params);
export const loginOut = (params) => request.get(API_PREFIX + '/security/logout', params);
export const freshAdminInfo = (params) => request.get(API_PREFIX + '/security/userInfo', params);
export const getUserInfo = (params) => request.get(API_PREFIX + '/queryUser', params);
export const migration = (params) => request.post(API_PREFIX + '/migration', params);
export const getPayInfo = (params) => request.get(API_PREFIX + '/queryPayLog', params);
export const getTradeInfo = (params) => request.get(API_PREFIX + '/queryTradeInfo', params);
export const querySalesAmountStat = (params) => request.get(API_PREFIX + '/querySalesAmountStat', params);
export const getIOSTradeInfo = (params) => request.get(API_PREFIX + '/queryIOSTradeInfo', params);
export const getRefundInfo = (params) => request.get(API_PREFIX + '/queryRefundInfo', params);
export const refund = (params) => request.post(API_PREFIX + '/refund', params);
export const withdrawRefund = (params) => request.post(API_PREFIX + '/withdrawRefund', params);
export const updatePayLogRemark = (params) => request.post(API_PREFIX + '/updatePayLogRemark', params);
export const updateMemberInfo = (params) => request.post(API_PREFIX + '/updateMemberInfo', params);
export const getBalanceInfo = (params) => request.get(API_PREFIX + '/system/platformBalance', params);
export const getAvailablePnameCnames = (params) => request.get(API_PREFIX + '/availablePnameCnames', params);
export const exportHourStat = (params) => request.download(API_PREFIX + '/downloadHourStat', params);
export const getHourStat = (params) => request.get(API_PREFIX + '/hourStat', params);
export const getFeedbackStat = (params) => request.get(API_PREFIX + '/feedbackStat', params);
export const getFeedbackList = (params) => request.get(API_PREFIX + '/feedbackList', params);
export const getFeedbackDetail = (params) => request.get(API_PREFIX + '/feedbackDetail', params);
export const getFeedbackReply = (params) => request.get(API_PREFIX + '/feedbackReply', params);
export const deleteFeedbackReply = (params) => request.get(API_PREFIX + '/withdrawReply', params);
export const finishFeedback = (params) => request.get(API_PREFIX + '/finishFeedback', params);
export const getVersionInfo = (params) => request.get(API_PREFIX + '/versionInfo', params);
export const getVersionExtInfo = (params) => request.get(API_PREFIX + '/versionExtInfo', params);
export const getVersionExtInfoByType = (params) => request.get(API_PREFIX + '/getVersionExtByType', params);
export const getVersionExtTypes = (params) => request.get(API_PREFIX + '/versionExtTypes', params);
export const getTaskConfigTypes = (params) => request.get(API_PREFIX + '/getTaskConfigTypes', params);
export const saveTaskConfig = (params) => request.post(API_PREFIX + '/saveTaskConfig', params);
export const getTaskConfigByType = (params) => request.post(API_PREFIX + '/getTaskConfigByType', params);
export const getPriceInfo = (params) => request.get(API_PREFIX + '/priceInfo', params);
export const savePriceInfo = (params) => request.post(API_PREFIX + '/savePriceInfo', params);
export const deletePriceInfo = (params) => request.get(API_PREFIX + '/deletePriceInfo', params);
export const reorderPriceInfo = (params) => request.post(API_PREFIX + '/reorderPriceInfo', params, 'json');
export const getPriceByPnameCname = (params) => request.post(API_PREFIX + '/priceInfoByPnameCname', params);
export const updateVersionInfo = (params) => request.post(API_PREFIX + '/updateVersionInfo', params);
export const getPayConfig = (params) => request.get(API_PREFIX + '/payConfig', params);
export const getPayConfigByPname = (params) => request.get(API_PREFIX + '/getPayConfigByPname', params);
export const savePayConfig = (params) => request.get(API_PREFIX + '/savePayConfig', params);
export const getRefundApplyList = (params) => request.get(API_PREFIX + '/refundApplyList', params);
export const handleRefundApply = (params) => request.get(API_PREFIX + '/handleRefundApply', params);
export const markRefundApplyFinish = (params) => request.get(API_PREFIX + '/markRefundApplyFinish', params);
export const upload = (params) => request.post(API_PREFIX + '/upload', params, "file");
export const getAdminList = (params) => request.get(API_PREFIX + '/admin/list', params);
export const updateAdmin = (params) => request.post(API_PREFIX + '/admin/update', params);
export const getRoleList = (params) => request.get(API_PREFIX + '/role/list', params);
export const addAdmin = (params) => request.post(API_PREFIX + '/admin/add', params);
export const disableAdmin = (params) => request.post(API_PREFIX + '/admin/disable', params);
export const enableAdmin = (params) => request.post(API_PREFIX + '/admin/enable', params);
export const getMenuList = (params) => request.get(API_PREFIX + '/menu/list', params);
export const saveMenu = (params) => request.post(API_PREFIX + '/menu/save', params);
export const deleteMenu = (params) => request.post(API_PREFIX + '/menu/delete', params);
export const updateRole = (params) => request.post(API_PREFIX + '/role/update', params);
export const saveRole = (params) => request.post(API_PREFIX + '/role/add', params);
export const deleteRole = (params) => request.post(API_PREFIX + '/role/delete', params);
export const getQuickPhrases = (params) => request.post(API_PREFIX + '/system/quickPhrases/list', params);
export const saveQuickPhrases = (params) => request.post(API_PREFIX + '/system/quickPhrases/add', params);
export const updateQuickPhrases = (params) => request.post(API_PREFIX + '/system/quickPhrases/update', params);
export const deleteQuickPhrases = (params) => request.post(API_PREFIX + '/system/quickPhrases/delete', params);
export const reorderQuickPhrases = (params) => request.post(API_PREFIX + '/system/quickPhrases/reorder', params);
export const getQuickPhrasesTypes = (params) => request.post(API_PREFIX + '/system/quickPhrases/types', params);
export const getRiskUserList = (params) => request.post(API_PREFIX + '/riskUserList', params);
export const getComplaintList = (params) => request.post(API_PREFIX + '/complaintList', params);
export const getComplaintStat = (params) => request.post(API_PREFIX + '/complaintStat', params);
export const getWxComplaintNegotiationHistory = (params) => request.post(API_PREFIX + '/system/getWxComplaintNegotiationHistory', params);
export const negotiationReply = (params) => request.post(API_PREFIX + '/system/negotiationReply', params);
export const finishComplaint = (params) => request.post(API_PREFIX + '/system/finishComplaint', params);
export const finishComplaintWithRefund = (params) => request.post(API_PREFIX + '/system/finishComplaintWithRefund', params);
export const getFriendList = (params) => request.post(API_PREFIX + '/actionTrack/friendList', params);
export const getVivoCampaignList = (params) => request.post(API_PREFIX + '/vivo/campaignList', params);
export const getCallInfo = (params) => request.get(API_PREFIX + '/system/callInfo', params);
export const updateCallInfoRemark = (params) => request.post(API_PREFIX + '/system/updateCallInfoRemark', params);
export const addBlacklist = (params) => request.post(API_PREFIX + '/addBlacklist', params);
export const removeBlacklist = (params) => request.post(API_PREFIX + '/removeBlacklist', params);
export const getAgentList = (params) => request.post(API_PREFIX + '/agentInfo/list', params);
export const addAgent = (params) => request.post(API_PREFIX + '/agentInfo/add', params);
export const updateAgent = (params) => request.post(API_PREFIX + '/agentInfo/update', params);
// export const enableAgent = (params) => request.post(API_PREFIX + '/agent/enableAgent', params);
// export const disableAgent = (params) => request.post(API_PREFIX + '/agent/disableAgent', params);
export const modifyPwd = (params) => request.post(API_PREFIX + '/agentInfo/modifyPwd', params);
export const getUrl = (params) => request.get(API_PREFIX + '/system/getUrl', params);
export const postUrl = (params) => request.post(API_PREFIX + '/system/postUrl', params);
export const getEditorTemplateList = (params) => request.post(API_PREFIX + '/system/getEditorTemplateList', params);
export const saveEditorTemplate = (params) => request.post(API_PREFIX + '/system/saveEditorTemplate', params);
export const exportEditorTemplate = (params) => request.download(API_PREFIX + '/system/exportEditorTemplate', params);
export const operationList = (params) => request.get(API_PREFIX + '/admin/operationLog/list', params);
export const missingPosterList = (params) => request.get(API_PREFIX + '/actionTrack/missingPoster/list', params);
export const auditMissingPoster = (params) => request.get(API_PREFIX + '/actionTrack/missingPoster/audit', params);
export const addMissingPoster = (params) => request.post(API_PREFIX + '/actionTrack/missingPoster/add', params);
