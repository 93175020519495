import React, {useRef, useEffect, useState} from 'react';
import {Button, Input, message, Modal, Select, Tree} from 'antd';
import {
    getQuickPhrasesTypes,
    getQuickPhrases,
    saveQuickPhrases,
    updateQuickPhrases,
    deleteQuickPhrases
} from '@/api/mock'
import ProTable from "@ant-design/pro-table";
import {ModalForm, ProFormText, ProFormSelect, ProFormTextArea} from "@ant-design/pro-form";
import {RULES as RULE} from "../../../common/rules";
import ProCard from "@ant-design/pro-card";
import {CARD_STYLES, TABLE_PROPS} from "../../commonLayout";
import {ENUMS} from "../../../common/enums";

const layout = {
    layout: "horizon",
    labelCol: {span: 6},
    wrapperCol: {span: 16},
};

function QuickPhrases() {
    const ref = useRef(undefined);
    const [config, setConfig] = useState(TABLE_PROPS.COMMON_PROPS);
    const [modalVisible, setModalVisible] = useState(false);
    const [phrasesTypes, setPhrasesTypes] = useState([]);
    const [phrasesTypesMap, setPhrasesTypesMap] = useState();
    const [editRowId, setEditRowId] = useState(undefined);

    useEffect(async () => {
        const result = await getQuickPhrasesTypes();
        if (result && result.data) {
            setPhrasesTypes(result.data);
            const phrasesTypesMap = new Map();
            for (let i in result.data) {
                let item = result.data[i];
                phrasesTypesMap.set(item.value, item.label);
            }
            setPhrasesTypesMap(phrasesTypesMap);
        }

    }, []);

    const columns = [
        {
            title: '快捷入口',
            dataIndex: 'type',
            hideInTable: true,
            valueEnum: phrasesTypesMap

        },

        {
            title: '快捷入口',
            dataIndex: 'typeDesc',
            hideInSearch: true,
            editable: false,
            width: 120
        },

        {
            title: '快捷内容',
            dataIndex: 'content',
            hideInSearch: true,
        },
        {
            title: '是否启用',
            dataIndex: 'isDel',
            valueType: 'select',
            width: 100,
            valueEnum: ENUMS.ANTI_YES_OR_NO_ENUM,
        },
        {
            title: '操作',
            key: 'option',
            valueType: 'option',
            render: (text, record, _, action) => [
                record.isSystem ?
                    <></> :
                    <a key="editable" onClick={() => {
                        setEditRowId(record.id);
                        action.startEditable?.(record.id);
                    }}>编辑</a>
            ],
            width: 150
        },
    ];

    return (
        <>
            <ProCard
                split="vertical"
            >
                <ProCard
                    style={CARD_STYLES.COMMON_MIN_HEIGHT_STYLE}
                >
                    <ProTable
                        {...config}
                        actionRef={ref}
                        columns={columns}
                        rowKey="id"
                        // 工具栏拓展
                        toolBarRender={() => [
                            <ModalForm
                                {...layout}
                                title={"添加快捷短语"}
                                width={600}
                                visible={modalVisible}
                                // 触发点
                                trigger={
                                    <Button type="primary" key="addRootMenu"
                                            onClick={() => {
                                                setModalVisible(true);
                                            }}>添加快捷短语</Button>
                                }
                                onFinish={async (values) => {
                                    let proj = window.localStorage.getItem('selectedProj');
                                    values.proj = proj;
                                    const result = await saveQuickPhrases(values);
                                    if (result.data) {
                                        message.success('保存成功');
                                        setModalVisible(false);
                                        ref.current.reload();
                                    } else {
                                        return false;
                                    }
                                }}
                                modalProps={{
                                    onCancel: () => {
                                        setModalVisible(false);
                                        return false;
                                    },
                                    maskClosable: false,
                                    closable: false,
                                    destroyOnClose: true,
                                    bodyStyle: {
                                        paddingTop: 40
                                    },
                                    centered: true,
                                    okText: '添加',
                                }}
                            >
                                <>
                                    <ProFormTextArea name="content" label="快捷内容" placeholder=""
                                                     rules={[{required: true, message: '内容不能为空'}]}
                                                     fieldProps={{
                                                         autoSize: {minRows: 6, maxRows: 8}
                                                     }}
                                    />

                                    <ProFormSelect
                                        name="type"
                                        label="快捷入口"
                                        options={phrasesTypes}
                                        rules={RULE.REQUIRED_RULE}
                                    />
                                </>
                            </ModalForm>
                        ]}
                        // value={phrases}
                        request={async (params, sorter, filter) => {
                            let proj = window.localStorage.getItem('selectedProj');
                            const result = await getQuickPhrases({...params, proj: proj});
                            return result;
                        }}
                        // 保存行编辑
                        editable={{
                            type: 'single',
                            onSave: async (key, row) => {
                                const {id, content, isDel} = row;
                                const result = await updateQuickPhrases({
                                    id: id,
                                    content: content,
                                    isDel: isDel,
                                });
                                if (result.data) {
                                    message.info("操作成功");
                                    ref.current.reload();
                                }
                            },
                            actionRender: (row, config, dom) => [dom.save, dom.cancel],
                        }}
                        recordCreatorProps={false}
                    />
                </ProCard>
            </ProCard>
        </>
    );
}

export default QuickPhrases;
