import PricePanel from "./pricePanel";
import ProCard from "@ant-design/pro-card";
import React, {useEffect, useState} from "react";
import {Badge, Divider} from "antd";
import {WaterMark} from "@ant-design/pro-layout";

function LandscapePriceList(props) {
    const {priceInfos, setReload, pname, cname} = props;
    const [data, setData] = useState(priceInfos);

    useEffect(() => {
        setData(priceInfos);
    }, [priceInfos]);

    // 定义表单体
    return (
        <Badge.Ribbon text="横幅价格">
            <ProCard style={{background: '#f0f2f5'}}
                     title={' '}
                     subTitle={'以下为横幅价格'}>
                <Divider/>
                {data.map((item, index) => (
                    <div
                        style={{
                            userSelect: 'none',
                            paddingBottom: 12,
                            width: '100%',
                        }}
                    >
                        <WaterMark markStyle={{color: 'red'}} content="横幅价格" gapX={600}>
                            <PricePanel landscape={true} priceInfo={item} setReload={setReload}/>
                        </WaterMark>
                    </div>
                ))}

            </ProCard>
        </Badge.Ribbon>
    )
}

export default LandscapePriceList;