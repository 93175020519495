import React, {useState, useEffect} from 'react';
import ProTable from '@ant-design/pro-table';
import ProCard from '@ant-design/pro-card';
import {Button, Tabs, message, Tag} from 'antd';
import {getHourStat, exportHourStat} from '@/api/mock';
import {DataView} from '@antv/data-set'
import {Line} from '@antv/g2plot';
import {TimeFilter} from "../../../components/TimeFilter";
import moment from "moment";
import {CARD_STYLES, TABLE_PROPS} from "../../commonLayout";
import {ExportOutlined} from '@ant-design/icons';

const {CheckableTag} = Tag;

const HourStat = () => {
    const [line, setLine] = useState(undefined);
    const [selectedPname, setSelectedPname] = useState('');
    const [selectedCname, setSelectedCname] = useState('');

    const pnameMap = JSON.parse(window.localStorage.getItem('pnameMap'));
    const cnames = JSON.parse(window.localStorage.getItem('cnames'));

    // 图表渲染
    let renderChart = (d) => {
        const dv = new DataView().source(d);

        dv.transform({
            type: 'map',
            callback(row) {
                row.hour = row.hour + '时';
                return row;
            },
        });

        dv.transform({
            type: 'rename',
            map: {
                activateNum: '激活人数',
                arpu: 'ARPU(激活)',
            },
        });

        dv.transform({
            type: 'fold',
            fields: ['激活人数', 'ARPU(激活)'], // 展开字段集
            key: 'series',
            value: 'value',
        });

        const data = dv.rows;

        if (!line) {
            let lineChart = new Line('container', {
                data,
                autoFit: true,
                smooth: true,
                xField: 'hour',
                yField: 'value',
                seriesField: 'series',
                animation: {
                    appear: {
                        animation: 'path-in',
                        duration: 2000,
                    },
                },
                tooltip: {
                    // showTitle: true,
                    // title: '经营分析表',
                    // showMarkers: false,
                    // follow: false,
                    // position: 'top',
                    // customContent: () => null,
                },
            });
            setLine(lineChart);
            lineChart.render();
            lineChart.chart.changeVisible(d.length === 1 ? false : true);
        } else {
            line.changeData(data);
            line.chart.changeVisible(d.length === 1 ? false : true);
        }
    };

    const columns = [
        {
            title: '包名',
            dataIndex: 'selectedPname',
            hideInTable: true,
            colSize: 3,
            renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
                let tabs = [];
                pnameMap && Object.keys(pnameMap).forEach(i => (
                    tabs.push(<CheckableTag
                        key={i}
                        checked={selectedPname === i}
                        onChange={() => {
                            setSelectedPname(i);
                            form.setFieldsValue({'selectedPname': i});
                            form?.submit();
                        }}
                    >
                        {pnameMap[i]}
                    </CheckableTag>)
                ));
                return (
                    <>
                        <CheckableTag
                            checked={selectedPname === ''}
                            onChange={() => {
                                setSelectedPname('');
                                form.setFieldsValue({'selectedPname': ''});
                                form?.submit();
                            }
                            }>全部</CheckableTag>
                        {tabs}
                    </>
                )
            }
        },
        {
            title: '渠道',
            key: 'selectedCname',
            hideInTable: true,
            colSize: 3,
            renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
                return (
                    <>
                        <CheckableTag style={{width: 70, textAlign: 'center'}} checked={selectedCname === ''}
                                      onChange={() => {
                                          setSelectedCname('');
                                          form.setFieldsValue({'selectedCname': ''});
                                          form?.submit();
                                      }}>
                            全部
                        </CheckableTag>
                        {cnames && cnames.map(i => (
                            <CheckableTag style={{width: 70, textAlign: 'center'}}
                                          key={`${i}`}
                                          checked={selectedCname === i}
                                          onChange={() => {
                                              setSelectedCname(i);
                                              form.setFieldsValue({'selectedCname': i});
                                              form?.submit();
                                          }}>
                                {i}
                            </CheckableTag>
                        ))}
                    </>);
            }
        },
        {
            title: '时间范围',
            key: 'dateRange',
            hideInTable: true,
            valueType: 'dateTimeRange',
            search: {
                transform: (value) => ({startDate: value[0], endDate: value[1]}),
            },
            initialValue: [moment().startOf('day'), moment().endOf('day')],
            colSize: 2,
            renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
                return <TimeFilter form={form} format={'YYYY/MM/DD HH:mm'} defaultShortcut={'today'}/>
            }
        },
    ];

    return (
        <>
            <ProCard
                split="vertical"
                bordered
                headerBordered
            >
                <ProCard
                    style={CARD_STYLES.COMMON_MIN_HEIGHT_STYLE}
                >
                    <ProTable
                        {...TABLE_PROPS.COMMON_PROPS}
                        tableRender={(_, dom) => (
                            <ProCard style={{marginTop: 16}}>
                                <div id="container"></div>
                            </ProCard>
                        )}
                        search={
                            {
                                ...TABLE_PROPS.COMMON_PROPS.search,
                                optionRender: ({searchText, resetText}, {form}, dom) => [
                                    dom[1],
                                    <Button onClick={
                                        () => {
                                            const {selectedPname, selectedCname, dateRange} = form.getFieldsValue();
                                            if (selectedPname) {
                                                exportHourStat({
                                                    startDate: dateRange[0].format('yyyy-MM-DD HH:mm:ss'),
                                                    endDate: dateRange[1].format('yyyy-MM-DD HH:mm:ss'),
                                                    selectedPname: selectedPname,
                                                    selectedCname: selectedCname
                                                });
                                            } else {
                                                message.error('请选择包名');
                                            }
                                        }
                                    } icon={<ExportOutlined/>}>导出</Button>,
                                ],
                            }
                        }
                        columns={columns}
                        rowKey="key"
                        request={async (params, sorter, filter) => {
                            if (params.startDate && params.endDate) {
                                let periodDays = moment(params.endDate).diff(moment(params.startDate), 'day');
                                if (periodDays > 10) {
                                    message.error('查询时间段不能大于10天');
                                    return [];
                                }
                                const result = await getHourStat({...params, sorter, filter});
                                renderChart(result.data);
                                return result;
                            } else {
                                return [];
                            }
                        }}
                    />
                </ProCard>
            </ProCard>
        </>
    );
};


export default HourStat;