import PricePanel from "./pricePanel";
import ProCard from "@ant-design/pro-card";
import {Badge, Divider, message} from "antd";
import React, {useEffect, useState} from "react";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {reorderPriceInfo, getPriceByPnameCname} from "@/api/mock";

function PriceList(props) {
    const {priceInfos, setReload, pname, cname} = props;
    const [data, setData] = useState(priceInfos);

    useEffect(() => {
        setData(priceInfos);
    }, [priceInfos]);

    const onDragEnd = async (result) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(
            data,
            result.source.index,
            result.destination.index
        );
        setData(items);

        let params = items.map((item, index) => {
            return {id: item.id, sort: index}
        });
        const r = await reorderPriceInfo(params);
        if (r.data) {
            message.info('排序成功');
            const priceResult = await getPriceByPnameCname({pname: pname, cname: cname});
            priceResult.data && setData(priceResult.data.filter(item => !item.landscape))
        }
    };
    // 定义表单体
    return (
        <Badge.Ribbon text="多列价格">
            <DragDropContext onDragEnd={onDragEnd}>
                <ProCard style={{background: '#f0f2f5'}}
                         title={' '}
                         subTitle={'以下价签可拖动排序'}>
                    <Divider/>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                                {...provided.droppableProps}
                            >
                                {data.map((item, index) => (
                                    <Draggable key={item.id + ''} draggableId={item.id + ''} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <PricePanel priceInfo={item} setReload={setReload}/>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            </div>
                        )}
                    </Droppable>
                </ProCard>
            </DragDropContext>
        </Badge.Ribbon>
    )
}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: 8,
    width: '25%',
    // margin: `0 ${grid}px 0 0`,
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : '#f0f2f5',
    display: 'flex',
    flexWrap: 'wrap',
});

export default PriceList;