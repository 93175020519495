import React, {useEffect, useRef, useState} from 'react';
import {EditableProTable} from '@ant-design/pro-table';
import {Button, message} from 'antd';
import {ModalForm, ProFormSelect, ProFormText} from "@ant-design/pro-form";
import {ENUMS} from "../../../common/enums";
import {RULES as RULE} from "../../../common/rules";
import {getAgentList, addAgent, updateAgent} from "@/api/mock";
import {timeFormatter} from "../../../utils";

const layout = {
    layout: "horizon",
    labelCol: {span: 6},
    wrapperCol: {span: 16},
};

const Agent = () => {
        const ref = useRef(undefined);
        const [modalVisible, setModalVisible] = useState(false);
        const [adminList, setAdminList] = useState([]);
        const [reload, setReload] = useState(0);
        // const [editRowId, setEditRowId] = useState(undefined);
        const [allProjs, setAllProjs] = useState(undefined);
        const [projMap, setProjMap] = useState(undefined);


        useEffect(async () => {
            const localProjs = JSON.parse(window.localStorage.getItem('projs'));
            setAllProjs(localProjs);


            let map = new Map();
            localProjs.forEach(d => {
                map.set(d.value, d.label);
            });
            setProjMap(map);

            const adminResult = await getAgentList();
            if (adminResult.data) {
                // 服务端返回的是字符串逗号分隔,ProTable里目前还没发现能对column值进行预处理的方法,这一步先放这里做了,其实也可以直接让服务端返回数组
                adminResult.data.map(i => i.availableProjs = i.availableProjs?.split(','));
                setAdminList(adminResult.data);
            }
        }, []);


        const columns = [
            {
                title: '账号',
                dataIndex: 'username',
                editable: false,
            },
            {
                title: '手机号',
                dataIndex: 'mobile',
            },
            {
                title: '姓名',
                dataIndex: 'name',
            },
            {
                title: '微信号',
                dataIndex: 'wx',
            },
            {
                title: '邮箱',
                dataIndex: 'email',
            },
            {
                title: '项目',
                dataIndex: 'proj',
                valueType: 'select',
                valueEnum: projMap,
                editable: false,
            },
            {
                title: '创建时间',
                dataIndex: 'created',
                hideInSearch: true,
                editable: false,
                renderText: timeFormatter,
            },
            {
                title: '是否启用',
                dataIndex: 'enabledStatus',
                valueType: 'select',
                width: 100,
                valueEnum: ENUMS.YES_OR_NO_ENUM,
            },
            {
                title: '操作',
                key: 'option',
                valueType: 'option',
                render: (text, record, _, action) => [
                    record.isSystem ?
                        <></> :
                        <a key="editable" onClick={() => {
                            // setEditRowId(record.id);
                            action.startEditable?.(record.id);
                        }}>编辑</a>
                ],
            },
        ];

        return (
            <>
                <EditableProTable
                    actionRef={ref}
                    search={false}
                    columns={columns}
                    rowKey="id"
                    cardBordered={true}
                    // 工具栏拓展
                    toolBarRender={() => [
                        <ModalForm
                            {...layout}
                            // layout={"horizon"}
                            title={"添加代理"}
                            width={400}
                            visible={modalVisible}
                            // 触发点
                            trigger={
                                <Button type="primary" key="addRootMenu"
                                        onClick={() => {
                                            setModalVisible(true);
                                        }}>添加代理</Button>
                            }
                            onFinish={async (values) => {
                                const result = await addAgent(values);
                                if (result.data) {
                                    message.success('保存成功');
                                    setModalVisible(false);
                                    ref.current.reload();
                                } else {
                                    return false;
                                }
                            }}
                            modalProps={{
                                onCancel: () => {
                                    setModalVisible(false);
                                    return false;
                                },
                                maskClosable: false,
                                closable: false,
                                destroyOnClose: true,
                                bodyStyle: {
                                    paddingTop: 40
                                },
                                centered: true,
                                okText: '添加',
                            }}
                        >
                            <>
                                <ProFormText name="username" label="用户名" placeholder=""
                                             rules={[{required: true, message: '用户名不能为空'}]}
                                />
                                <ProFormText.Password name="password" label="密码" placeholder="" rules={[
                                    {required: true, message: '请输入密码!'},
                                ]}/>
                                <ProFormText name="mobile" label="手机号" rules={RULE.MOBILE_RULE}/>
                                <ProFormText name="wx" label="微信号"/>
                                <ProFormText name="email" label="邮箱"/>
                                <ProFormText name="name" label="姓名" placeholder="非必填"/>
                                <ProFormText name="address" label="地址" placeholder="非必填"/>
                                <ProFormSelect
                                    name="proj"
                                    label="代理项目"
                                    // mode="tags"
                                    placeholder="请选择代理项目"
                                    options={allProjs}
                                    rules={RULE.REQUIRED_RULE}
                                />
                            </>
                        </ModalForm>
                    ]}
                    value={adminList}
                    // 保存行编辑
                    editable={{
                        type: 'single',
                        onSave: async (key, row) => {
                            const {id, mobile, wx, email, address, enabledStatus} = row;
                            const result = await updateAgent({
                                id: id,
                                mobile: mobile,
                                wx: wx,
                                email: email,
                                address: address,
                                enabledStatus: enabledStatus,
                            });
                            if (result.data) {
                                message.info("操作成功");
                                setReload(reload + 1);
                            }
                        },
                        actionRender: (row, config, dom) => [dom.save, dom.cancel],
                    }}
                    recordCreatorProps={false}
                />
            </>
        );
    }
;

export default Agent;
