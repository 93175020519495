const payTypeMap = new Map();
payTypeMap.set(null, '全部');
payTypeMap.set(1, '支付宝');
payTypeMap.set(2, '微信');
payTypeMap.set(3, '苹果');

// 常用支付方式
const commonlyPayTypeMap = new Map();
commonlyPayTypeMap.set(null, '全部');
commonlyPayTypeMap.set(1, '支付宝');
commonlyPayTypeMap.set(2, '微信');

const YesOrNoMap = new Map();
YesOrNoMap.set(0, '否');
YesOrNoMap.set(1, '是');

const AntiYesOrNoMap = new Map();
AntiYesOrNoMap.set(0, '是');
AntiYesOrNoMap.set(1, '否');

const payStatusMap = new Map();
payStatusMap.set(2, '待付款');
payStatusMap.set(3, '已付款');
payStatusMap.set(5, '已退款');

const complainedStatusMap = new Map();
// complainedStatusMap.set(0, "未知");
complainedStatusMap.set(1, "待处理");
complainedStatusMap.set(2, "处理中");
complainedStatusMap.set(3, "处理完成");

const feedbackStatusMap = new Map();
feedbackStatusMap.set(null, '全部');
feedbackStatusMap.set(1, '待回复');
feedbackStatusMap.set(2, '已回复');
feedbackStatusMap.set(3, '已完结');

const kefuIdDivisorMap = new Map();
kefuIdDivisorMap.set('-1', '全部');
// kefuIdDivisorMap.set('0', '1号客服');
// kefuIdDivisorMap.set('1', '2号客服');
// kefuIdDivisorMap.set('2', '3号客服');

const refundApplyStatusMap = new Map();
refundApplyStatusMap.set(1, '待审核');
refundApplyStatusMap.set(2, '退款失败');
refundApplyStatusMap.set(3, '已退款');
refundApplyStatusMap.set(4, '已拒绝');

const missingPosterAuditStatusMap = new Map();
missingPosterAuditStatusMap.set(null, '全部');
missingPosterAuditStatusMap.set(1, '待审核');
missingPosterAuditStatusMap.set(2, '审核通过');
missingPosterAuditStatusMap.set(3, '未通过');

const pnameEnumMap = new Map();
pnameEnumMap.set('com.shucha.find', '位寻');
pnameEnumMap.set('com.qianniu.earth', '他迹');
pnameEnumMap.set('com.qianniu.dingwei', '查位');
pnameEnumMap.set('com.dz.position', '守护');
pnameEnumMap.set('com.lingjige.location', '定位精灵');
pnameEnumMap.set('com.bianfeng.place', '位查查查');
pnameEnumMap.set('com.shucha.jiejing', '数查街景');

const projEnumMap = new Map();
projEnumMap.set('ACTION_TRACK', '定位');
projEnumMap.set('SCENE', '街景');
projEnumMap.set('POETRY', '藏头诗');
projEnumMap.set('ANIMAL_TRANSLATION', '动物翻译');
projEnumMap.set('FILE_MANAGER', '文件压缩');
projEnumMap.set('NOTEPAD', '记事本');

const sexOptions = [{label: '男', value: 1}, {label: '女', value: 2}];

export const ENUMS = {
    PAY_TYPE_ENUM: payTypeMap,
    COMMONLY_PAY_TYPE_MAP: commonlyPayTypeMap,
    FEEDBACK_STATUS_ENUM: feedbackStatusMap,
    KEFU_ID_DIVISOR_ENUM: kefuIdDivisorMap,
    REFUND_APPLY_STATUS_ENUM: refundApplyStatusMap,
    MISSING_POSTER_AUDIT_STATUS_MAP: missingPosterAuditStatusMap,
    YES_OR_NO_ENUM: YesOrNoMap,
    ANTI_YES_OR_NO_ENUM: AntiYesOrNoMap,
    PAY_STATUS_MAP: payStatusMap,
    PNAME_ENUM_MAP: pnameEnumMap,
    PROJ_ENUM_MAP: projEnumMap,
    COMPLAINED_STATUS_MAP: complainedStatusMap,
    SEX_OPTIONS:sexOptions,
};



