import React, {Fragment, useEffect, useState} from 'react';
import {Route, Router, Switch} from "react-router-dom";

import ProLayout, {PageContainer, SettingDrawer, DefaultFooter, WaterMark} from '@ant-design/pro-layout';
import NoMatch from 'react-router-nomatch';

import {getUrlParamsSuffix} from '../../utils';
import history from "../../history";

import DashBoard from "../Dashboard";
import CallInfo from "../CallInfo";
import UserInfo from "../Info/User";
import PayInfo from "../Info/PayLog";
import RefundApply from "../Info/RefundApply";
import TradeInfo from "../Stat/Trade";
import RefundInfo from "../Stat/Refund";
import PlatformBalance from "../PlatformBalance";
import VersionConfig from "../Config/Version";
import VersionExtConfig from "../Config/VersionExt";
import PriceConfig from "../Config/Price";
import PaySwitchConfig from "../Config/PaySwitch";
import NotFound from "../NotFound";
import Login from "../../components/Login";
import RightContent from "../../components/RightContent";

import './index.less';
import FeedbackQuantity from "../Stat/FeedbackQuantity";
import Feedback from "../Feedback";
import Complain from "../Complain";
import AdminList from "../System/Admin";
import RoleList from "../System/Role";
import MenuList from "../System/Menu";
import RiskUser from "../RiskUser";
import TaskConfig from "../Config/Task";
import HourStat from "../Stat/HourStat";
import Friend from "../Info/Friend";
import ViVoMarketingPanel from "../Config/Vivo";
import {IconMap} from "./iconMap";
import IOSTradeInfo from "../Stat/IOSTrade";
import QuickPhrases from "../System/QuickPhrases";
import Agent from "../Config/Agent";
import EditorList from "../Config/EditorList";
import OperationLog from "../System/OperationLog";
import MissingPosterInfo from "../MissingPoster";

const defaultFooterDom = (
    <DefaultFooter
        copyright={`${new Date().getFullYear()}`}
        links={[
            {
                key: '百度',
                title: '百度',
                href: 'https://www.baidu.com',
                blankTarget: true,
            },
            // {
            //     key: '恋爱宝典',
            //     title: '恋爱宝典',
            //     href: 'http://91smhappy.com/',
            //     blankTarget: true,
            // },
        ]}
    />
);

const menuIconRender = (menuList, selectedProj) => {
    return menuList.map((item) => {
        const localItem = {
            ...item,
            icon: item.icon && IconMap[item.icon],
            children: item.children ? menuIconRender(item.children, selectedProj) : undefined,
            hideInMenu: item.availableProjs && item.availableProjs.indexOf(selectedProj) === -1
        };
        return localItem;
    });
};


function Home() {
    const [settings, setSetting] = useState(undefined);
    const [selectedPath, setSelectedPath] = useState('/welcome');
    const [openKeys, setOpenKeys] = React.useState(['/']);

    let proj = window.localStorage.getItem('selectedProj');
    let menuData = [];
    let rootSubmenuKeys = [];

    let menuJson = window.localStorage.getItem('menus');
    if (menuJson) {
        let menus = JSON.parse(menuJson);
        menus.every(item => rootSubmenuKeys.push(item.path));
        menuData = menuIconRender(menus, proj);
    }

    const onOpenChange = keys => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    useEffect(() => {
        // 监听url, 根据url自动跳转对应菜单项页面
        const urlPath = history.location.pathname;
        renderPath(urlPath);
    }, []);

    // 渲染path
    const renderPath = (path) => {
        // 设置左侧被选中菜单项
        setSelectedPath(path || "/");
        // 跳转至菜单项path对应页面, getUrlParamsSuffix保留url原来的参数
        history.push(path + "?" + getUrlParamsSuffix());
    };


    return (
        <>
            <Router history={history}>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Fragment>
                        <ProLayout
                            {...settings}
                            style={{
                                height: '100%',
                                border: '1px solid #ddd',
                            }}
                            // 菜单加载
                            menuDataRender={() => menuData}
                            // 选中的菜单项, pathname与menu中菜单项的path对应
                            location={{
                                pathname: selectedPath,
                            }}
                            // // 菜单项点击处理, 选中点击的菜单项
                            menuItemRender={(item, dom) => {
                                return <div onClick={() => {
                                    return renderPath(item.path);
                                }
                                }>{dom}</div>
                            }}
                            menuProps={{
                                onOpenChange: onOpenChange,
                                openKeys: openKeys
                            }}
                            footerRender={() => defaultFooterDom}
                            rightContentRender={() => <RightContent/>}
                            fixSiderbar
                        >
                            <PageContainer
                                header={{
                                    // 这个title控制右边页面的标题
                                    // title: '',
                                    // 面包屑控制
                                    // breadcrumb: {},
                                }}>

                                <WaterMark content="RICH" gapX={600}>
                                    <NoMatch component={NotFound}>
                                        <Route path="/config/versionConfig" component={VersionConfig}/>
                                        <Route path="/config/priceConfig" component={PriceConfig}/>
                                        <Route path="/config/payConfig" component={PaySwitchConfig}/>
                                        <Route path="/config/versionExtConfig" component={VersionExtConfig}/>
                                        <Route path="/config/taskConfig" component={TaskConfig}/>
                                        <Route path="/config/vivoMarketing" component={ViVoMarketingPanel}/>
                                        <Route path="/config/agent" component={Agent}/>
                                        <Route path="/config/editorList" component={EditorList}/>
                                        <Route path="/info/user" component={UserInfo}/>
                                        <Route path="/info/payLog" component={PayInfo}/>
                                        <Route path="/info/refundApplyList" component={RefundApply}/>
                                        <Route path="/info/friend" component={Friend}/>
                                        <Route path="/info/missingPosterList" component={MissingPosterInfo}/>
                                        <Route path="/data/tradeInfo" component={TradeInfo}/>
                                        <Route path="/data/iOSTradeInfo" component={IOSTradeInfo}/>
                                        <Route path="/data/refundInfo" component={RefundInfo}/>
                                        <Route path="/data/balanceInfo" component={PlatformBalance}/>
                                        <Route path="/data/callList" component={CallInfo}/>
                                        <Route path="/data/feedbackQuantity" component={FeedbackQuantity}/>
                                        <Route path="/data/feedback" component={Feedback}/>
                                        <Route path="/data/complain" component={Complain}/>
                                        <Route path="/data/riskUser" component={RiskUser}/>
                                        <Route path="/data/hourStat" component={HourStat}/>
                                        <Route path="/systemManager/admin" component={AdminList}/>
                                        <Route path="/systemManager/role" component={RoleList}/>
                                        <Route path="/systemManager/menu" component={MenuList}/>
                                        <Route path="/systemManager/menuInterface" component={MenuList}/>
                                        <Route path="/systemManager/quickPhrases" component={QuickPhrases}/>
                                        <Route path="/systemManager/operationLog" component={OperationLog}/>
                                        <Route exact path="/" component={DashBoard}/>
                                    </NoMatch>
                                </WaterMark>
                            </PageContainer>
                        </ProLayout>
                    </Fragment>
                </Switch>
            </Router>
            <SettingDrawer
                getContainer={() => document.getElementById('home-layout')}
                settings={settings}
                onSettingChange={(changeSetting) => setSetting(changeSetting)}
            />
        </>
    );

}

export default Home;
